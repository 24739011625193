import React, { useState } from "react";
import styles from "../scss/tivv.module.scss";
import parse from "html-react-parser";

import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import { getMainBoard } from "../../api/api";
import { useEffect } from "react";
import LinkPopup from "../popup/LinkPopup";

const TivvService = ({
  serviceTop,
  setServiceTop,
  serviceRef,
  languageSelect,
}) => {
  const [isShowServicePopup, setIsShowServicePopup] = useState(false);
  // 메인 Get
  const { data: serData } = useQuery(
    [QueryKeys.MAIN, "TIV_IX", languageSelect],
    () => getMainBoard("TIV", "TIV_IX", languageSelect),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  useEffect(() => {
    if (serData) {
      setServiceTop(1);
    }
  }, [serData]);
  if (!serData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <section className={styles["service"]} ref={serviceRef}>
        <h2
          className={[
            styles["title"],
            `${serviceTop === 2 ? styles["active"] : ""}`,
          ].join(" ")}
        >
          <span className={styles["color-primary"]}>
            {serData.ttl.substr(0, 4)}
          </span>
          <span>{serData.ttl.substr(4)}</span>
        </h2>
        <span className={styles["sub-title"]}>
          <span>{parse(serData.dtl)}</span>
        </span>
        <div className={styles["service-box"]}>
          <img
            src={`${process.env.REACT_APP_IMGURL}/${serData.fileInfo[0].file_dntf_code}/${serData.fileInfo[0].pld_file_name}`}
            alt=""
          />
        </div>
        <div className={[styles["btn-group"], "flex"].join(" ")}>
          {serData.tmp03 === "true" ? (
            <button onClick={() => setIsShowServicePopup(true)}>
              {serData.tmp05}
            </button>
          ) : null}
        </div>
      </section>
      {isShowServicePopup && (
        <LinkPopup
          setIsShowServicePopup={setIsShowServicePopup}
          url={serData.tmp02.split("/").slice(-1)[0]}
        />
      )}
    </>
  );
};

export default TivvService;
