import React from "react";
import styles from "../scss/popup.module.scss";
import closePopup from "../../assets/images/ico/ico_close_menu.png";
import parse from "html-react-parser";

const Popup = ({
  top,
  left,
  width,
  height,
  item,
  closePopupToday,
  handleClickClosePopup,
}) => {
  const rectStyle = {
    zIndex: 999,
    position: "fixed",
    top: `${top}%`,
    left: `${left}%`,
    width: `${width}px`,
    height: `${height}px`,
  };
  const onClickPopUp = (url) => {
    window.open(url, "_blank", "noopener");
  };

  return (
    <>
      <div
        style={rectStyle}
        className={[styles["popup-wrapper"], "flex flex-col"].join(" ")}
      >
        <div
          className="grow"
          style={{
            maxWidth: width,
            maxHeight: height,
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => onClickPopUp(item.tmp05)}
        >
          {item.fileInfo.length === 0 && <div>{parse(item.dtl)}</div>}
          {item.fileInfo.length > 0 && (
            <>
              <div
                className={styles["popup-image"]}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`})`,
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  overflow: "hidden",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {parse(item.dtl)}
              </div>
            </>
          )}
        </div>
        <div className={styles["bottom-box"]}>
          <div
            className="flex items-center gap-x-1"
            onClick={() => closePopupToday(item.brd_id)}
          >
            <input
              type="checkbox"
              id={`popup-hide-${item.brd_id}`}
              name={item.brd_id}
            />
            <label htmlFor={`popup-hide-${item.brd_id}`}>
              오늘 하루 보지 않기
            </label>
          </div>
          <button
            className={styles["btn-close"]}
            onClick={() => {
              handleClickClosePopup(item.brd_id);
            }}
          >
            <img src={closePopup} className="w-full h-full" alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Popup;
