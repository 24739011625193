import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Main from "./pages/Main";
import Pola from "./pages/Pola";
import Tivv from "./pages/Tivv";
import People from "./pages/People";
import Recruit from "./pages/Recruit";
import NewsRoom from "./pages/NewsRoom";
import Admin from "./Admin";
import SignIn from "./admin/page/auth/SignIn";
import Logo from "./admin/page/main/Logo";
import OnlyView from "./admin/page/view/OnlyView";
import SingleEnroll from "./admin/page/enroll/SingleEnroll";
import AdminMain from "./admin/page/main/AdminMain";
import NonDelView from "./admin/page/view/NonDelView";
import MainEdit from "./admin/page/edit/MainEdit";
import OurSolutions from "./admin/page/main/OurSolutions";
import OurPartners from "./admin/page/main/OurPartners";
import Enroll from "./admin/page/enroll/Enroll";
import View from "./admin/page/view/View";
import Edit from "./admin/page/edit/Edit";
import Awards from "./admin/page/main/Awards";
import MainEnroll from "./admin/page/enroll/MainEnroll";
import History from "./admin/page/main/History";
import CoreValues from "./admin/page/main/CoreValues";
import MediaKit from "./admin/page/main/MediaKit";
import MediaKitView from "./admin/page/view/MediaKitView";
import MediaKitEnroll from "./admin/page/enroll/MediaKitEnroll";
import Researches from "./pages/Researches";
import Media from "./pages/Media";
import ServiceIntro from "./admin/page/pola/ServiceIntro";
import TmpEnroll from "./admin/page/enroll/TmpEnroll";
import TmpView from "./admin/page/view/TmpView";
import TmpEdit from "./admin/page/edit/TmpEdit";
import PolaDefinition from "./admin/page/pola/PolaDefinition";
import PolaDefEdit from "./admin/page/edit/PolaDefEdit";
import PolaApplication from "./admin/page/pola/PolaApplication";
import FirstNoImgEdit from "./admin/page/edit/FirstNoImgEdit";
import PolaFeatures from "./admin/page/pola/PolaFeatures";
import PartnerEdit from "./admin/page/edit/PartnerEdit";
import ParterView from "./admin/page/view/ParterView";
import Process from "./admin/page/pola/Process";
import PolaFaq from "./admin/page/pola/PolaFaq";
import FaqEnroll from "./admin/page/enroll/FaqEnroll";
import FaqEdit from "./admin/page/edit/FaqEdit";
import PopUp from "./admin/page/Operration/PopUp";
import PopUpEnroll from "./admin/page/enroll/PopUpEnroll";
import PopUpView from "./admin/page/view/PopUpView";
import PopUpEdit from "./admin/page/edit/PopUpEdit";
import TivvServiceIntro from "./admin/page/tivv/TivvServiceIntro";
import Tmp01View from "./admin/page/view/Tmp01View";
import Tmp01Edit from "./admin/page/edit/Tmp01Edit";
import TivvService from "./admin/page/tivv/TivvService";
import TivvDefinition from "./admin/page/tivv/TivvDefinition";
import TivvApplication from "./admin/page/tivv/TivvApplication";
import PeopleIntro from "./admin/page/people/PeopleIntro";
import OurLeaders from "./admin/page/people/OurLeaders";
import OurLeadersEdit from "./admin/page/edit/OurLeadersEdit";
import PeoplePeo from "./admin/page/people/PeoplePeo";
import PeopleEnroll from "./admin/page/enroll/PeopleEnroll";
import PeopleEdit from "./admin/page/edit/PeopleEdit";
import PeopleView from "./admin/page/view/PeopleView";
import Photo from "./admin/page/people/Photo";
import Culture from "./admin/page/people/Culture";
import RecruitMain from "./admin/page/recruit/RecruitMain";
import Interview from "./admin/page/recruit/Interview";
import InterViewEnroll from "./admin/page/enroll/InterViewEnroll";
import RecruitInfo from "./admin/page/recruit/RecruitInfo";
import RecruitFaq from "./admin/page/recruit/RecruitFaq";
import RecruitInfoEnroll from "./admin/page/enroll/RecruitInfoEnroll";
import RecruitInfoView from "./admin/page/view/RecruitInfoView";
import RecruitInfoEdit from "./admin/page/edit/RecruitInfoEdit";
import AdminMedia from "./admin/page/media/AdminMedia";
import AdminMediaView from "./admin/page/view/AdminMediaView";
import AdminMediaEnroll from "./admin/page/enroll/AdminMediaEnroll";
import AdminMediaEdit from "./admin/page/edit/AdminMediaEdit";
import SocialMedia from "./admin/page/media/SocialMedia";
import SocialMediaEnroll from "./admin/page/enroll/SocialMediaEnroll";
import SocialMediaEdit from "./admin/page/edit/SocialMediaEdit";
import AdminResearches from "./admin/page/researches/AdminResearches";
import InterviewEdit from "./admin/page/edit/InterviewEdit";
import AdminFooter from "./admin/page/Operration/AdminFooter";
import FooterEdit from "./admin/page/edit/FooterEdit";
import Code from "./admin/page/Operration/Code";
import UserManagement from "./admin/page/Operration/UserManagement";
import UserView from "./admin/page/view/UserView";
import UserEdit from "./admin/page/edit/UserEdit";
import AwardsEdit from "./admin/page/edit/AwardsEdit";
import PartnersEnroll from "./admin/page/enroll/PartnersEnroll";
import TivvMainView from "./admin/page/view/TivvMainView";
import TivvMainEdit from "./admin/page/edit/TivvMainEdit";
import CultureEdit from "./admin/page/edit/CultureEdit";
import UserManagementEnroll from "./admin/page/enroll/UserManagementEnroll";
import AdminResearchesView from "./admin/page/view/AdminResearchesView";
import AdminResearchesEdit from "./admin/page/edit/AdminResearchesEdit";
import AdminResearchesEnroll from "./admin/page/enroll/AdminResearchesEnroll";
import EngMain from "./pages/EngMain";
import TivvServiceEdit from "./admin/page/edit/TivvServiceEdit";
import TivvServiceIntoView from "./admin/page/view/TivvServiceIntoView";
import TivvDefEidt from "./admin/page/edit/TivvDefEidt";
import PolaAdEdit from "./admin/page/edit/PolaAdEdit";
import TivvFirstNoImgEdit from "./admin/page/edit/TivvFirstNoImgEdit";
import PeopleMainEdit from "./admin/page/edit/PeopleMainEdit";
import RecruitFaqEdit from "./admin/page/edit/RecruitFaqEdit";
import PolaEnroll from "./admin/page/enroll/PolaEnroll";
import TivvEnroll from "./admin/page/enroll/TivvEnroll";
import Investment from "./pages/Investment";
import AdminInvestment from "./admin/page/media/AdminInvestment";
import InvestmentView from "./pages/InvestmentView";
import AdminInvestmentView from "./admin/page/view/AdminInvestmentView";
import AdminInvestEnroll from "./admin/page/enroll/AdminInvestEnroll";
import AdminInvestEdit from "./admin/page/edit/AdminInvestEdit";
import NexLook from "./pages/NexLook";
import NextLookIntro from "./admin/page/nextlook/NextLookIntro";
import NextLookService from "./admin/page/nextlook/NextLookService";
import NextLookAdvantage from "./admin/page/nextlook/NextLookAdvantage";
import NextLookIntroView from "./admin/page/view/NextLookIntroView";
import NextLookIntroEdit from "./admin/page/edit/NextLookIntroEdit";
import NextLookServiceView from "./admin/page/view/NextLookServiceView";
import NextLookServiceEdit from "./admin/page/edit/NextLookServiceEdit";
import NextLookAdvantageEnroll from "./admin/page/enroll/NextLookAdvantageEnroll";
import NextLookAdvantageEdit from "./admin/page/edit/NextLookAdvantageEdit";
import TwoButtonView from "./admin/page/view/TwoButtonView";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Main /> },
      { path: "/en", element: <EngMain /> },
      { path: "/pola", element: <Pola /> },
      { path: "/nexlook", element: <NexLook /> },
      { path: "/tivv", element: <Tivv /> },
      { path: "/people", element: <People /> },
      { path: "/recruit", element: <Recruit /> },
      { path: "/newsroom", element: <NewsRoom /> },
      { path: "/newsroom/media", element: <Media /> },
      { path: "/newsroom/research", element: <Researches /> },
      { path: "/newsroom/investment", element: <Investment /> },
      { path: "/newsroom/investment/:id", element: <InvestmentView /> },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "/admin/main/logo",
        element: <Logo />,
      },
      {
        path: "/admin/main/logo/board/:id",
        element: <OnlyView />,
      },
      {
        path: "/admin/main/logo/enroll",
        element: <SingleEnroll />,
      },
      {
        path: "/admin/main/main",
        element: <AdminMain />,
      },
      {
        path: "/admin/main/main/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/main/main/board/edit/:id",
        element: <MainEdit />,
      },
      {
        path: "/admin/main/solutions",
        element: <OurSolutions />,
      },
      {
        path: "/admin/main/solutions/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/main/solutions/board/edit/:id",
        element: <MainEdit />,
      },
      {
        path: "/admin/main/partners",
        element: <OurPartners />,
      },
      {
        path: "/admin/main/partners/enroll",
        element: <PartnersEnroll />,
      },
      {
        path: "/admin/main/partners/board/:id",
        element: <ParterView />,
      },
      {
        path: "/admin/main/partners/board/edit/:id",
        element: <PartnerEdit />,
      },
      {
        path: "/admin/main/awards",
        element: <Awards />,
      },
      {
        path: "/admin/main/awards/enroll",
        element: <MainEnroll />,
      },
      {
        path: "/admin/main/awards/board/:id",
        element: <View />,
      },
      {
        path: "/admin/main/awards/board/edit/:id",
        element: <AwardsEdit />,
      },
      {
        path: "/admin/main/history",
        element: <History />,
      },
      {
        path: "/admin/main/history/enroll",
        element: <MainEnroll />,
      },
      {
        path: "/admin/main/history/board/:id",
        element: <View />,
      },
      {
        path: "/admin/main/history/board/edit/:id",
        element: <AwardsEdit />,
      },
      {
        path: "/admin/main/core-values",
        element: <CoreValues />,
      },
      {
        path: "/admin/main/core-values/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/main/core-values/board/edit/:id",
        element: <AwardsEdit />,
      },
      {
        path: "/admin/main/media-kit",
        element: <MediaKit />,
      },
      {
        path: "/admin/main/media-kit/board/:id",
        element: <MediaKitView />,
      },
      {
        path: "/admin/main/media-kit/enroll",
        element: <MediaKitEnroll />,
      },
      {
        path: "/admin/pola/service-intro",
        element: <ServiceIntro />,
      },
      {
        path: "/admin/pola/service-intro/board/:id",
        element: <TwoButtonView />,
      },
      {
        path: "/admin/pola/service-intro/board/edit/:id",
        element: <TmpEdit />,
      },
      {
        path: "/admin/pola/service-info",
        element: <PolaDefinition />,
      },
      {
        path: "/admin/pola/service-info/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/pola/service-info/board/edit/:id",
        element: <PolaDefEdit />,
      },
      {
        path: "/admin/pola/utilize",
        element: <PolaApplication />,
      },
      {
        path: "/admin/pola/utilize/enroll",
        element: <Enroll />,
      },
      {
        path: "/admin/pola/utilize/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/pola/utilize/board/edit/:id",
        element: <FirstNoImgEdit />,
      },
      {
        path: "/admin/pola/features",
        element: <PolaFeatures />,
      },
      {
        path: "/admin/pola/features/board/:id",
        element: <View />,
      },
      {
        path: "/admin/pola/features/enroll",
        element: <PolaEnroll />,
      },
      {
        path: "/admin/pola/features/board/edit/:id",
        element: <PolaAdEdit />,
      },
      {
        path: "/admin/pola/process",
        element: <Process />,
      },
      {
        path: "/admin/pola/process/board/:id",
        element: <View />,
      },
      {
        path: "/admin/pola/process/enroll",
        element: <Enroll />,
      },
      {
        path: "/admin/pola/process/board/edit/:id",
        element: <FirstNoImgEdit />,
      },
      {
        path: "/admin/pola/faq",
        element: <PolaFaq />,
      },
      {
        path: "/admin/pola/faq/board/:id",
        element: <View />,
      },
      {
        path: "/admin/pola/faq/enroll",
        element: <FaqEnroll />,
      },
      {
        path: "/admin/pola/faq/board/edit/:id",
        element: <FaqEdit />,
      },
      {
        path: "/admin/nextlook/main",
        element: <NextLookIntro />,
      },
      {
        path: "/admin/nextlook/main/board/:id",
        element: <TwoButtonView />,
      },
      {
        path: "/admin/nextlook/main/board/edit/:id",
        element: <NextLookIntroEdit />,
      },
      {
        path: "/admin/nextlook/service",
        element: <NextLookService />,
      },
      {
        path: "/admin/nextlook/service/board/:id",
        element: <NextLookServiceView />,
      },
      {
        path: "/admin/nextlook/service/board/edit/:id",
        element: <NextLookServiceEdit />,
      },
      {
        path: "/admin/nextlook/advantage",
        element: <NextLookAdvantage />,
      },
      {
        path: "/admin/nextlook/advantage/board/:id",
        element: <View />,
      },
      {
        path: "/admin/nextlook/advantage/enroll",
        element: <NextLookAdvantageEnroll />,
      },
      {
        path: "/admin/nextlook/advantage/board/edit/:id",
        element: <NextLookAdvantageEdit />,
      },
      {
        path: "/admin/tivv/service-intro",
        element: <TivvServiceIntro />,
      },
      {
        path: "/admin/tivv/service-intro/board/:id",
        element: <TwoButtonView />,
      },
      {
        path: "/admin/tivv/service-intro/board/edit/:id",
        element: <TmpEdit />,
      },
      {
        path: "/admin/tivv/service",
        element: <TivvService />,
      },
      {
        path: "/admin/tivv/service/board/:id",
        element: <TivvServiceIntoView />,
      },
      {
        path: "/admin/tivv/service/board/edit/:id",
        element: <TivvServiceEdit />,
      },
      {
        path: "/admin/tivv/definition",
        element: <TivvDefinition />,
      },
      {
        path: "/admin/tivv/definition/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/tivv/definition/board/edit/:id",
        element: <TivvDefEidt />,
      },
      {
        path: "/admin/tivv/application",
        element: <TivvApplication />,
      },
      {
        path: "/admin/tivv/application/board/:id",
        element: <View />,
      },
      {
        path: "/admin/tivv/application/enroll",
        element: <TivvEnroll />,
      },
      {
        path: "/admin/tivv/application/board/edit/:id",
        element: <TivvFirstNoImgEdit />,
      },
      {
        path: "/admin/people/intro",
        element: <PeopleIntro />,
      },
      {
        path: "/admin/people/intro/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/people/intro/board/edit/:id",
        element: <PeopleMainEdit />,
      },
      // {
      //   path: "/admin/people/our-leaders",
      //   element: <OurLeaders />,
      // },
      // {
      //   path: "/admin/people/our-leaders/board/:id",
      //   element: <Tmp01View />,
      // },
      // {
      //   path: "/admin/people/our-leaders/enroll",
      //   element: <TmpEnroll />,
      // },
      // {
      //   path: "/admin/people/our-leaders/board/edit/:id",
      //   element: <OurLeadersEdit />,
      // },
      {
        path: "/admin/people/people",
        element: <PeoplePeo />,
      },
      {
        path: "/admin/people/people/board/:id",
        element: <PeopleView />,
      },
      {
        path: "/admin/people/people/enroll",
        element: <PeopleEnroll />,
      },
      {
        path: "/admin/people/people/board/edit/:id",
        element: <PeopleEdit />,
      },
      // {
      //   path: "/admin/people/photo",
      //   element: <Photo />,
      // },
      // {
      //   path: "/admin/people/photo/board/:id",
      //   element: <NonDelView />,
      // },
      // {
      //   path: "/admin/people/photo/board/edit/:id",
      //   element: <MainEdit />,
      // },
      {
        path: "/admin/people/culture",
        element: <Culture />,
      },
      {
        path: "/admin/people/culture/board/:id",
        element: <Tmp01View />,
      },
      {
        path: "/admin/people/culture/board/edit/:id",
        element: <CultureEdit />,
      },
      {
        path: "/admin/recruit/main",
        element: <RecruitMain />,
      },
      {
        path: "/admin/recruit/main/board/:id",
        element: <NonDelView />,
      },
      {
        path: "/admin/recruit/main/board/edit/:id",
        element: <MainEdit />,
      },
      {
        path: "/admin/recruit/interview",
        element: <Interview />,
      },
      {
        path: "/admin/recruit/interview/board/:id",
        element: <Tmp01View />,
      },
      {
        path: "/admin/recruit/interview/enroll",
        element: <InterViewEnroll />,
      },
      {
        path: "/admin/recruit/interview/board/edit/:id",
        element: <InterviewEdit />,
      },
      {
        path: "/admin/recruit/recruit",
        element: <RecruitInfo />,
      },
      {
        path: "/admin/recruit/recruit/enroll",
        element: <RecruitInfoEnroll />,
      },
      {
        path: "/admin/recruit/recruit/board/:id",
        element: <RecruitInfoView />,
      },
      {
        path: "/admin/recruit/recruit/board/edit/:id",
        element: <RecruitInfoEdit />,
      },
      {
        path: "/admin/recruit/faq",
        element: <RecruitFaq />,
      },
      {
        path: "/admin/recruit/faq/board/:id",
        element: <View />,
      },
      {
        path: "/admin/recruit/faq/enroll",
        element: <FaqEnroll />,
      },
      {
        path: "/admin/recruit/faq/board/edit/:id",
        element: <RecruitFaqEdit />,
      },
      {
        path: "/admin/media/media",
        element: <AdminMedia />,
      },
      {
        path: "/admin/media/media/enroll",
        element: <AdminMediaEnroll />,
      },
      {
        path: "/admin/media/media/board/:id",
        element: <AdminMediaView />,
      },
      {
        path: "/admin/media/media/board/edit/:id",
        element: <AdminMediaEdit />,
      },
      {
        path: "/admin/media/investment",
        element: <AdminInvestment />,
      },
      {
        path: "/admin/media/investment/enroll",
        element: <AdminInvestEnroll />,
      },
      {
        path: "/admin/media/investment/board/edit/:id",
        element: <AdminInvestEdit />,
      },
      {
        path: "/admin/media/investment/board/:id",
        element: <AdminInvestmentView />,
      },

      {
        path: "/admin/management/popup",
        element: <PopUp />,
      },
      {
        path: "/admin/media/social-media",
        element: <SocialMedia />,
      },
      {
        path: "/admin/media/social-media/board/:id",
        element: <AdminMediaView />,
      },
      {
        path: "/admin/media/social-media/enroll",
        element: <SocialMediaEnroll />,
      },
      {
        path: "/admin/media/social-media/board/edit/:id",
        element: <SocialMediaEdit />,
      },
      {
        path: "/admin/researches/contents",
        element: <AdminResearches />,
      },
      {
        path: "/admin/researches/contents/enroll",
        element: <AdminResearchesEnroll />,
      },
      {
        path: "/admin/researches/contents/board/:id",
        element: <AdminResearchesView />,
      },
      {
        path: "/admin/researches/contents/board/edit/:id",
        element: <AdminResearchesEdit />,
      },
      {
        path: "/admin/researches/contents/board/enroll",
        element: <AdminResearchesEnroll />,
      },
      {
        path: "/admin/workSpaceManages/contents/board/:id",
        element: <View />,
      },
      {
        path: "/admin/management/popup/enroll",
        element: <PopUpEnroll />,
      },
      {
        path: "/admin/management/popup/board/:id",
        element: <PopUpView />,
      },
      {
        path: "/admin/management/popup/board/edit/:id",
        element: <PopUpEdit />,
      },
      {
        path: "/admin/management/pref/footerinfo",
        element: <AdminFooter />,
      },
      {
        path: "/admin/management/pref/footerinfo/board/edit/:lang",
        element: <FooterEdit />,
      },
      {
        path: "/admin/management/code",
        element: <Code />,
      },
      {
        path: "/admin/management/member",
        element: <UserManagement />,
      },
      {
        path: "/admin/management/member/enroll",
        element: <UserManagementEnroll />,
      },
      {
        path: "/admin/management/member/board/:id",
        element: <UserView />,
      },
      {
        path: "/admin/management/member/board/edit/:id",
        element: <UserEdit />,
      },
    ],
  },
  {
    path: "/admin/sign-in",
    element: <SignIn />,
  },
]);

export default router;
