import React, { useState } from "react";
import styles from "../../components/scss/sign/signIn.module.scss";
import { useMutation } from "@tanstack/react-query";
import { postSignIn } from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoginState } from "../../util/recoil";

function SignIn() {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  const [isLogin, setIsLogin] = useRecoilState(isLoginState);

  let loginInfo;

  const { mutate } = useMutation((userData) => postSignIn(userData), {
    onSuccess: (data) => {
      if (data.code === "fail") {
        setLoginStatus(data.msg);
      } else {
        setIsLogin(true);
        if (window.opener) {
          navigate(-1);
        } else {
          navigate("/");
        }
      }
    },
  });

  const handleLogin = (e) => {
    e.preventDefault();

    loginInfo = {
      user_id: userId,
      pswr: password,
    };

    mutate(loginInfo);
  };

  return (
    <div className="flex flex-col h-full items-center p-6 pt-[5rem]">
      <h1 className="text-xl font-bold">Login</h1>
      <form
        onSubmit={handleLogin}
        className={[styles["login-form"], "w-[30rem] mt-6"].join(" ")}
      >
        <div className={styles["login-box"]}>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            required
            minLength={4}
            maxLength={20}
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="아이디를 입력하세요"
          />
        </div>
        <div className={styles["login-box"]}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            required
            autoComplete="off"
            minLength={4}
            maxLength={10}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호는 4자리 이상 10자리 이하입니다"
          />
        </div>

        <button type="submit" className={styles["btn-login"]}>
          Login
        </button>
      </form>

      <div className={styles["helper-text"]}>{loginStatus}</div>
    </div>
  );
}

export default SignIn;
