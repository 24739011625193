import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import styles from "../components/scss/main.module.scss";

import MainIntro from "../components/main/MainIntro";
import OurSolutions from "../components/main/OurSolutions";
import OurPartners from "../components/main/OurPartners";
import Awards from "../components/main/Awards";
import History from "../components/main/History";
import CoreValues from "../components/main/CoreValues";
import MediaKit from "../components/main/MediaKit";

import contactBgImgL from "../assets/images/bg/main_contact_1.png";
import { useRecoilValue } from "recoil";
import { isLoginState, languageSelector } from "../admin/util/recoil";
import Popup from "../components/popup/Popup";
import { useQuery } from "@tanstack/react-query";
import { getPopupList } from "../api/api";
import { QueryKeys } from "../util/queryClient";
import { useLocation } from "react-router-dom";

const Contact = lazy(() => import("../components/layout/Contact"));
const Main = () => {
  const pathName = window.location.href;
  useEffect(() => {
    // if (pathname === "#solution") {
    //   window.scrollTo({ top: 1000, left: 0, behavior: "auto" });
    // }
  }, [pathName]);
  const solutionRef = useRef();
  const partnerRef = useRef();
  const awardsRef = useRef();
  const awardsSwiperRef = useRef(null);
  const awardsSlideRef = useRef();
  const historyRef = useRef();
  const historySwiperRef = useRef();
  const valueRef = useRef();
  const mediaRef = useRef();
  const contactRef = useRef();

  const languageSelect = useRecoilValue(languageSelector);
  const isAdmin = useRecoilValue(isLoginState);
  const [isOpen, setIsOpen] = useState(true);
  const [clickedItem, setClickedItem] = useState();

  const [solution, setSolution] = useState(0);
  const [partner, setPartner] = useState(0);
  const [awards, setAwards] = useState(0);
  const [history, setHistory] = useState(0);
  const [value, setValue] = useState(0);
  const [media, setMedia] = useState(0);
  const [contact, setContact] = useState(0);
  const [awardsSlideHeight, setAwardsSlideHeight] = useState(0);
  const [popupList, setPopupList] = useState([]);
  let lastScroll = document.documentElement.scrollTop || 0;

  const onScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop > lastScroll) {
      // down
    } else {
      if (awardsSwiperRef.current) {
        awardsSwiperRef.current.swiper.mousewheel.enable();
      }
      if (historySwiperRef.current) {
        historySwiperRef.current.swiper.mousewheel.enable();
      }
    }
    lastScroll = scrollTop;
  };

  const { data: popupData } = useQuery(
    [QueryKeys.POPUP, "OPE_PP"],
    () => getPopupList("OPE", "OPE_PP"),
    {
      select: (data) => {
        const newData = data.popList.slice(1).filter((item) => {
          return (
            item.xpsr_ndt.split("-").join("") >=
            new Date().toISOString().slice(0, 10).split("-").join("")
          );
        });
        return newData;
      },
    }
  );
  const checkPopupClose = (id) => {
    const expireDay = localStorage.getItem(`${id}`);
    let today = new Date();

    if (today.getTime() > expireDay) {
      return true;
    } else {
      return false;
    }
  };
  const closePopupToday = (id) => {
    let expire = new Date();
    expire.setDate(expire.getDate() + 1); // 현재 날짜에 1을 더해 다음 날로 설정
    expire.setHours(0, 0, 0, 0);
    localStorage.setItem(`${id}`, expire.getTime());
    const newArray = popupList.filter((item) => item.brd_id !== id);
    setPopupList(newArray);
  };

  const handleClickClosePopup = (id) => {
    const newArray = popupList.filter((item) => item.brd_id !== id);
    setPopupList(newArray);
  };

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case solutionRef.current:
              setSolution(2);
              observer.unobserve(target);
              break;
            case partnerRef.current:
              setPartner(2);
              observer.unobserve(target);
              break;
            case awardsRef.current:
              setAwards(2);
              const awardsWidth = awardsSlideRef?.current.offsetWidth;
              const awardsHeight =
                awardsWidth * awardsSlideRef.current.childElementCount;
              setAwardsSlideHeight(awardsHeight * 1.5);
              window.addEventListener("scroll", onScroll);
              observer.unobserve(target);
              break;
            case historyRef.current:
              setHistory(2);
              observer.unobserve(target);
              break;
            case valueRef.current:
              setValue(2);
              observer.unobserve(target);
              break;
            case mediaRef.current:
              setMedia(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContact(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);

    // 감지할 요소들을 옵저버에 등록
    if (solutionRef.current) {
      observer.observe(solutionRef.current);
    }
    if (partnerRef.current) {
      observer.observe(partnerRef.current);
    }
    if (awardsRef.current) {
      observer.observe(awardsRef.current);
    }
    if (historyRef.current) {
      observer.observe(historyRef.current);
    }
    if (valueRef.current) {
      observer.observe(valueRef.current);
    }
    if (mediaRef.current) {
      observer.observe(mediaRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
      window.removeEventListener("scroll", onScroll);
    };
  }, [
    solutionRef.current,
    awardsRef.current,
    partnerRef.current,
    awardsRef.current,
    historyRef.current,
    valueRef.current,
    mediaRef.current,
  ]);
  useEffect(() => {
    if (popupData) {
      const newPopUpData = popupData.filter((item) => {
        return (
          !localStorage.getItem(`${item.brd_id}`) ||
          checkPopupClose(item.brd_id)
        );
      });
      setPopupList(newPopUpData);
    }
  }, [popupData]);
  return (
    <>
      <div
        className={[
          styles["wrapper"],
          styles["main-wrapper"],
          isAdmin ? styles["is-admin"] : "",
        ].join(" ")}
      >
        <MainIntro isAdmin={isAdmin} languageSelect={languageSelect} />
        <div id="main-solutions">
          <OurSolutions
            solutionRef={solutionRef}
            solution={solution}
            setSolution={setSolution}
            languageSelect={languageSelect}
          />
        </div>
        <div id="main-partners">
          <OurPartners
            partnerRef={partnerRef}
            partner={partner}
            setPartner={setPartner}
            languageSelect={languageSelect}
          />
        </div>

        <div className={styles["bg-area"]}>
          <div id="main-awards">
            <Awards
              awards={awards}
              setAwards={setAwards}
              awardsRef={awardsRef}
              awardsSwiperRef={awardsSwiperRef}
              awardsSlideRef={awardsSlideRef}
              languageSelect={languageSelect}
            />
          </div>
          <div id="main-history">
            <History
              history={history}
              setHistory={setHistory}
              historyRef={historyRef}
              historySwiperRef={historySwiperRef}
              languageSelect={languageSelect}
            />
          </div>
          <div id="main-values">
            <CoreValues
              value={value}
              valueRef={valueRef}
              setValue={setValue}
              languageSelect={languageSelect}
            />
          </div>
          <div id="main-mediakit">
            <MediaKit
              media={media}
              mediaRef={mediaRef}
              setMedia={setMedia}
              languageSelect={languageSelect}
            />
          </div>
          {/* contact */}
          <Suspense fallback="">
            <Contact
              contact={contact}
              contactRef={contactRef}
              text={
                languageSelect === "KOR"
                  ? "씨앤에이아이에 대해 궁금하신가요?"
                  : "Are you curious about C&AI?"
              }
              bgImgL={contactBgImgL}
              bgImgR={""}
              isPadding={true}
            />
          </Suspense>
        </div>
      </div>
      {popupList?.map((item) => (
        <Popup
          key={item.brd_id}
          top={item.tmp02}
          left={item.tmp01}
          width={item.tmp03}
          height={item.tmp04}
          item={item}
          closePopupToday={closePopupToday}
          handleClickClosePopup={handleClickClosePopup}
        />
      ))}
    </>
  );
};

export default Main;
