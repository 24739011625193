import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import styles from "../scss/table/table.module.scss";
import Button from "../button/Button";
import PageTitle from "../title/PageTitle";
import ytubeImg from "../../assets/images/items/ytubeImg.png";

const headerList = [
  { id: 1, title: "번호" },
  { id: 2, title: "제목" },
  { id: 3, title: "썸네일" },
  { id: 4, title: "URL" },
  { id: 5, title: "사용여부" },
  { id: 6, title: "등록자" },
  { id: 7, title: "등록일" },
];

const SocialTable = ({ layoutList }) => {
  const navigate = useNavigate();
  // State
  const [layoutHeaderList, setLayoutHeaderList] = useState(headerList);
  const [modalState, setModalState] = useState(false);

  // 목록으로 이동
  const handleClickMoveToPage = (id) => {
    navigate(`${window.location.pathname}/board/${id}`);
  };

  // 등록일자 시간
  const getTime = (value) => {
    let time = value.substring(0, 10);
    return time;
  };

  //등록 페이지 이동
  const handleClickMoveToAddPage = () => {
    navigate(`${window.location.pathname}/enroll`);
  };
  //모달 닫기
  const closeModal = () => {
    setModalState(0);
  };
  const Thumbnail = function extractVideoID(url) {
    const urlId = url.split("/").splice(-1)[0];
    return `https://img.youtube.com/vi/${urlId}/default.jpg`;
  };

  return (
    <div
      className={[styles["content-box"], styles["social-content-box"]].join(
        " "
      )}
    >
      <PageTitle title={"소셜미디어"} />
      <div className="flex flex-col align-middle">
        <div
          className={[styles["top-section"], "my-3 flex justify-between"].join(
            " "
          )}
        >
          <span className={[styles["list-title"], "mb-3"].join(" ")}></span>
        </div>
        <div className={styles["list-wrapper"]}>
          <div className={styles["list-header"]}>
            <ul className={[styles["list-box"], "flex"].join(" ")}>
              {layoutHeaderList.map((layoutList, index) => (
                <li
                  key={index + `${layoutList?.title}`}
                  className={[
                    styles["title-list-item"],
                    "flex items-center",
                  ].join(" ")}
                >
                  <div className={styles["header-text"]}>
                    {layoutList?.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles["list-container"]}>
            <ul className={styles["list-box"]}>
              {layoutList?.map((layoutList, index) => (
                <li
                  className={[
                    styles["content-list-item"],
                    "flex items-center",
                  ].join(" ")}
                  key={layoutList.brd_id}
                  onClick={() => handleClickMoveToPage(layoutList.brd_id)}
                >
                  {/* 번호 */}
                  <div
                    className={[styles["number"], styles["list-item"]].join(
                      " "
                    )}
                  >
                    {layoutList.rownum}
                  </div>
                  {/* 제목 */}
                  <div
                    className={[styles["name"], styles["list-item"]].join(" ")}
                  >
                    <span>{layoutList.ttl}</span>
                  </div>
                  {/* 썸네일 */}
                  <div
                    className={[
                      styles["thumbnailbox"],
                      styles["list-item"],
                    ].join(" ")}
                  >
                    <img
                      alt="유튜브 썸네일"
                      src={Thumbnail(layoutList.tmp01)}
                    />
                  </div>

                  {/* URL */}
                  <div
                    className={[styles["url"], styles["list-item"]].join(" ")}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(layoutList.tmp01, "_blank");
                    }}
                  >
                    <div>링크가기</div>
                  </div>
                  {/* 사용여부 */}
                  <div
                    className={[styles["use_yn"], styles["list-item"]].join(
                      " "
                    )}
                  >
                    {layoutList.use_yn === "Y" ? "사용" : "미사용"}
                  </div>
                  {/* 등록자 */}
                  <div
                    className={[
                      styles["date"],
                      styles["rgst_id"],
                      styles["list-item"],
                    ].join(" ")}
                  >
                    {layoutList.rgst_id}
                  </div>
                  {/* 등록일 */}
                  <div
                    className={[
                      styles["date"],
                      styles["rgst_date"],
                      styles["list-item"],
                    ].join(" ")}
                  >
                    {getTime(layoutList?.rgst_date)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <Button text="등록하기" buttonFunction={handleClickMoveToAddPage} />
        </div>
      </div>
    </div>
  );
};

export default SocialTable;
