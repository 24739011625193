import React from "react";
import styles from "../scss/tivv.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import { useEffect } from "react";

const Definition = ({
  definitionRef,
  definitionTop,
  setDefinitionTop,
  languageSelect,
}) => {
  // 메인 Get
  const { data: defData } = useQuery(
    [QueryKeys.MAIN, "TIV_ES", languageSelect],
    () => getMainBoard("TIV", "TIV_ES", languageSelect),
    {
      select: (data) => {
        return data.boardList;
      },
    }
  );
  useEffect(() => {
    if (defData) {
      setDefinitionTop(1);
    }
  }, [defData]);
  if (!defData) return <div style={{ width: "100vh", height: "100vh" }}></div>;

  return (
    <section className={styles["definition"]} ref={definitionRef}>
      <h2
        className={[
          styles["title"],
          `${definitionTop === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {/* {defData[0].ttl} */}
        <span className={styles["color-primary"]}>
          {defData[0].ttl.substr(0, 4)}
        </span>
        <span>{defData[0].ttl.substr(4)}</span>
      </h2>
      <span className={styles["desc-text"]}>{parse(defData[0].dtl)}</span>
      <div className={styles["definition-box"]}>
        <ul>
          {defData.slice(1).map((item) => (
            <li key={item.brd_id}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                alt=""
              />
              <strong className={styles["definition-text"]}>
                {parse(item.ttl)}
              </strong>
              <span className={styles["detail-text"]}>{parse(item.dtl)}</span>
            </li>
          ))}
        </ul>
        <div className={styles["img-box"]}>
          <img
            src={`${process.env.REACT_APP_IMGURL}/${defData[0].fileInfo[0].file_dntf_code}/${defData[0].fileInfo[0].pld_file_name}`}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Definition;
