import React from "react";
import styles from "../scss/button/button.module.scss";
const Button = ({ disable, text, buttonFunction }) => {
  return (
    <div className={[styles["btn-group"], "flex justify-end gap-3"].join(" ")}>
      <button disabled={disable} onClick={buttonFunction}>
        {text}
      </button>
    </div>
  );
};

export default Button;
