import React, { useEffect } from "react";
import styles from "../scss/main.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import { useNavigate } from "react-router-dom";
function OurSolutions({ solutionRef, solution, setSolution, languageSelect }) {
  const navigate = useNavigate();
  const { data: solData } = useQuery(
    [QueryKeys.MAIN, "MAI_SOL", languageSelect],
    () => getMainBoard("MAI", "MAI_SOL", languageSelect),
    {
      select: (data) => {
        return data.boardList;
      },
    }
  );

  const ViewMoreFn = (url) => {
    const absoluteUrl = new URL(url).pathname;
    navigate(absoluteUrl);
    window.scrollTo(0, 0);
  };
  const EnViewMoreFn = (id) => {
    const myComponent = document.getElementById(`${id}`);
    if (myComponent) {
      myComponent.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    if (solData) {
      setSolution(1);
    }
  }, [solData]);

  return (
    <>
      {solData ? (
        <section
          id="solution"
          className={[styles["solution"], "solution"].join(" ")}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_IMGURL}/${solData[0].fileInfo[0].file_dntf_code}/${solData[0].fileInfo[0].pld_file_name})`,
          }}
          ref={solutionRef}
        >
          <h2
            className={[
              styles["title"],
              `${solution === 2 ? styles["active"] : ""}`,
            ].join(" ")}
          >
            {solData[0].ttl}
          </h2>
          <div
            className={
              languageSelect === "ENG"
                ? [styles["solution-en-box"], styles["solution-box"]].join(" ")
                : styles["solution-box"]
            }
          >
            <img
              src={`${process.env.REACT_APP_IMGURL}/${solData[1].fileInfo[0].file_dntf_code}/${solData[1].fileInfo[0].pld_file_name}`}
              alt=""
              className={[styles["solution-bg"], styles["bg-pola"]].join(" ")}
            />
            <strong className={styles["sub-text"]}>{solData[1].ttl}</strong>
            <div>{parse(solData[1].dtl)}</div>
            {languageSelect === "KOR" ? (
              <button
                onClick={() => {
                  ViewMoreFn(solData[1].tmp02);
                }}
                className={styles["btn-view"]}
              >
                View More
              </button>
            ) : (
              <button
                onClick={() => {
                  EnViewMoreFn("pola-definition");
                }}
                className={styles["btn-view"]}
              >
                View More
              </button>
            )}
          </div>
          <div
            className={
              languageSelect === "ENG"
                ? [styles["solution-en-box"], styles["solution-box"]].join(" ")
                : styles["solution-box"]
            }
          >
            <img
              src={`${process.env.REACT_APP_IMGURL}/${solData[2].fileInfo[0].file_dntf_code}/${solData[2].fileInfo[0].pld_file_name}`}
              alt=""
              className={[styles["solution-bg"], styles["bg-tivv"]].join(" ")}
            />
            <strong className={styles["sub-text"]}>{solData[2].ttl}</strong>
            <div>{parse(solData[2].dtl)}</div>
            {languageSelect === "KOR" ? (
              <button
                onClick={() => {
                  ViewMoreFn(solData[2].tmp02);
                }}
                className={styles["btn-view"]}
              >
                View More
              </button>
            ) : (
              <button
                onClick={() => {
                  EnViewMoreFn("tivv-service");
                }}
                className={styles["btn-view"]}
              >
                View More
              </button>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default React.memo(OurSolutions);
