import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom/dist";
import Header from "./admin/components/layouts/Header";
import MenuBar from "./admin/components/sidebar/Menubar";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoginState } from "./admin/util/recoil";
import FullMenubar from "./admin/components/sidebar/FullMenubar";

//관리자 페이지
const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  useEffect(() => {
    const checkSession = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/users/getSessionInfo`,{ withCredentials: true });

      if (res.data.code !== "success") {
        setIsLogin(false);
        navigate("/admin/sign-in");
      } else {
        setIsLogin(true);
      }
    };
    checkSession();
  }, [location]);
  return (
    <>
      {/* <Header /> */}
      <Header />
      <div className="flex">
        {window.opener && <MenuBar />}
        {!window.opener && <FullMenubar />}
        <div className="flex flex-col w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Admin;
