import React, { useEffect, useRef, useState } from "react";
import styles from "../components/scss/recruit.module.scss";

import bottomBgImg from "../assets/images/bg/pola_bottom.png";
import { useRecoilValue } from "recoil";
import { isLoginState } from "../admin/util/recoil";
import RecruitIntro from "../components/recruit/RecruitIntro";
import InterView from "../components/recruit/InterView";
import RecruitInfo from "../components/recruit/RecruitInfo";
import RecruitFaq from "../components/recruit/RecruitFaq";

const Recruit = () => {
  const interviewRef = useRef();
  const recruitRef = useRef();
  const faqRef = useRef();
  const contactRef = useRef();
  const processRef = useRef();

  const [scrollY, setScrollY] = useState(0);
  const [interviewTop, setInterviewTop] = useState(0);
  const [recruitTop, setRecruitRefTop] = useState(0);
  const [contactTop, setContactTop] = useState(0);
  const [processTop, setProcessTop] = useState(0);
  const [faqTop, setFqaTop] = useState(0);

  const isAdmin = useRecoilValue(isLoginState);

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case interviewRef.current:
              setInterviewTop(2);
              observer.unobserve(target);
              break;
            case recruitRef.current:
              setRecruitRefTop(2);
              observer.unobserve(target);
              break;
            case faqRef.current:
              setFqaTop(2);
              observer.unobserve(target);
              break;
            case processRef.current:
              setProcessTop(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContactTop(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);

    // 감지할 요소들을 옵저버에 등록
    if (interviewRef.current) {
      observer.observe(interviewRef.current);
    }
    if (recruitRef.current) {
      observer.observe(recruitRef.current);
    }
    if (faqRef.current) {
      observer.observe(faqRef.current);
    }
    if (processRef.current) {
      observer.observe(processRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
    };
  }, [
    interviewRef.current,
    recruitRef.current,
    faqRef.current,
    processRef.current,
    contactRef.current,
  ]);

  return (
    <div
      className={[
        styles["wrapper"],
        styles["recruit-wrapper"],
        isAdmin ? styles["is-admin"] : "",
      ].join(" ")}
    >
      {/* intro */}
      <RecruitIntro isAdmin={isAdmin} />
      {/* interview */}

      <div id="recruit-interview">
        <InterView
          interviewRef={interviewRef}
          interviewTop={interviewTop}
          setInterviewTop={setInterviewTop}
        />
      </div>
      <div
        className={styles["bg-area"]}
        style={{
          backgroundImage: `url(${bottomBgImg})`,
          backgroundColor: "#1d212e",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* recruit */}
        <div id="recruit-recruit">
          <RecruitInfo
            recruitRef={recruitRef}
            recruitTop={recruitTop}
            setRecruitRefTop={setRecruitRefTop}
          />
        </div>
        {/* process */}
        {/* <section className={styles["process"]} ref={processRef}>
          <span className={styles["sub-title"]}>RECRUIT PROCESS</span>
          <h2
            className={[
              styles["title"],
              `${scrollY > processTop - 500 ? styles["active"] : ""}`,
            ].join(" ")}
          >
            영입 절차
          </h2>
          <ul>
            {recruitProcess.map((item) => (
              <li key={item.id}>{item.text}</li>
            ))}
          </ul>
          <span className={styles["helper-text"]}>
            채용에 대해 궁금한 점이 생기셨다면, 주저하지 말고 언제든 문의
            주세요!
          </span>
          <span className={styles["email"]}>Hr_team@cnai.ai</span>
        </section> */}
        {/* faq */}
        <div id="recruit-faq">
          <RecruitFaq faqTop={faqTop} faqRef={faqRef} setFqaTop={setFqaTop} />
        </div>
      </div>
    </div>
  );
};

export default Recruit;
