import React, { useEffect, useRef, useState } from "react";
import styles from "../components/scss/research.module.scss";

import btnPrev from "../assets/images/button/pagination_prev.png";
import btnNext from "../assets/images/button/pagination_next.png";
import btnFirst from "../assets/images/button/pagination_first.png";
import btnLast from "../assets/images/button/pagination_last.png";
import Contact from "../components/layout/Contact";

import { getMainBoard, getMainBoardForPaging } from "../api/api";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../util/queryClient";
import parse from "html-react-parser";
import { useRecoilValue } from "recoil";
import { isLoginState } from "../admin/util/recoil";

// admin : http://43.203.193.82:7010/admin/workSpaceManages/contents
// RES RES_RS

const Researches = () => {
  const researchRef = useRef();
  const contactRef = useRef();
  const isAdmin = useRecoilValue(isLoginState);
  const [maximunPage, setMaximunPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageGroup = Math.ceil(currentPage / (9 / 2));
  const startIndex = Math.floor((currentPageGroup - 1) * (9 / 2)) + 1;
  const endIndex = Math.min(startIndex + Math.floor(9 / 2) - 1, maximunPage);
  const { data: researchList } = useQuery([QueryKeys.MAIN, "RES_RS"], () =>
    getMainBoardForPaging("RES", "RES_RS", 9, (currentPage - 1) * 9, "")
  );
  //첫페이지 이동
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  //다음 페이지 이동
  const handleNextPage = () => {
    if (currentPage >= maximunPage) return;
    else {
      setCurrentPage(currentPage + 1);
    }
  };
  // 이전 페이지 이동
  const handlePrevPage = () => {
    if (currentPage <= 1) return;
    else {
      setCurrentPage(currentPage - 1);
    }
  };
  //마지막 페이지로 이동
  const handleLastPage = () => {
    setCurrentPage(maximunPage);
  };
  useEffect(() => {
    if (researchList) {
      setMaximunPage(Math.ceil(researchList.paging[0].all_page / 9));
    }
  }, [researchList]);

  const [windowWidth, setWindowWidth] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [researchTop, setResearchTop] = useState(0);
  const [contactTop, setContactTop] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  let lastScroll = document.documentElement.scrollTop || 0;

  const onScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop > lastScroll) {
      // down
    } else {
      // up
    }
    lastScroll = scrollTop;

    setScrollY(window.scrollY);
    if (researchRef.current) {
      setResearchTop(researchRef.current.offsetTop);
      setContactTop(contactRef.current.offsetTop);
    }
  };

  const onResize = () => {
    setWindowWidth(window.innerWidth);
    if (windowWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollY, windowWidth]);
  if (!researchList)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;

  return (
    <>
      <div
        className={[
          styles["wrapper"],
          styles["research-wrapper"],
          isAdmin ? styles["is-admin"] : "",
        ].join(" ")}
      >
        {/* research */}
        <section className={styles["research"]} ref={researchRef}>
          <h2
            className={[
              styles["title"],
              `${scrollY > researchTop - 500 ? styles["active"] : ""}`,
            ].join(" ")}
          >
            씨앤에이아이의 연구활동을 전해드립니다.
          </h2>
          <div className={styles["scroll-x"]}>
            <ul className={styles["research-list"]}>
              {researchList.boardList.reverse().map((item) => (
                <li key={item.brd_id}>
                  <div className={styles["research-box"]}>
                    <strong className={styles["research-title"]}>
                      {item.ttl}
                    </strong>
                    {parse(item.dtl)}
                    <div className={styles["bottom-box"]}>
                      {item.tmp01 != "" ? (
                        <button
                          onClick={() => window.open(item.tmp01, "_blank")}
                        >
                          View More
                        </button>
                      ) : (
                        <button>View More</button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* pagination */}
          <div className={styles["pagination"]}>
            {/* 처음 페이지로 이동 */}
            <div
              className={[
                styles["page-btns"],
                styles["prev-btn-box"],
                "mr-1",
              ].join(" ")}
            >
              <button
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                <img src={btnFirst} alt="" />
              </button>
            </div>
            <div
              className={[styles["page-btns"], styles["prev-btn-box"]].join(
                " "
              )}
            >
              <button
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
                onClick={handlePrevPage}
              >
                <img src={btnPrev} alt="" />
              </button>
            </div>
            <ol className={styles["pagination-num"]}>
              {Array.from(
                { length: endIndex - startIndex + 1 },
                (_, i) => startIndex + i
              ).map((page) => (
                <button
                  className={[
                    styles["page-btns"],
                    styles["page-nums"],
                    "flex items-center justify-center",
                    // `${currentPage === page ? styles.active : ""}`,
                  ].join(" ")}
                  key={page}
                  onClick={() => {
                    setCurrentPage(page);
                  }}
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
            </ol>
            <div
              className={[styles["page-btns"], styles["next-btn-box"]].join(
                " "
              )}
            >
              <button
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
                onClick={handleNextPage}
              >
                <img src={btnNext} alt="" />
              </button>
            </div>
            <div
              className={[
                styles["page-btns"],
                styles["next-btn-box"],
                "ml-1",
              ].join(" ")}
            >
              <button
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
                onClick={handleLastPage}
              >
                <img src={btnLast} alt="" />
              </button>
            </div>
          </div>
        </section>
        <div className={styles["bg-wrapper"]}>
          <Contact
            text="씨앤에이아이에 대해 궁금하신가요?"
            isAdmin={isAdmin}
            contact={contactTop}
            contactRef={contactRef}
            isPadding={true}
          />
        </div>
      </div>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open(
              "/admin/media/social-media",
              "blank",
              "height=720,width=1280"
            );
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
    </>
  );
};

export default Researches;
