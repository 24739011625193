import React, { useEffect, useReducer, useRef, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest2, patchTest } from "../../api/api";
import ConfirmModal from "../../components/modal/ConfirmModal";
import deleteBtn from "../../assets/images/ico/btn_close_sm.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../util/font";

const modules = {
  toolbar: {
    container: [
      [
        {
          size: [
            "10px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "28px",
            "30px",
            "32px",
            "36px",
            "38px",
            "40px",
            "42px",
            "44px",
            "46px",
            "48px",
            "50px",
            "52px",
            "64px",
            "80px",
          ],
        },
      ],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      [{ align: ["left", "center", "right", "justify"] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};

const codeType = {
  "/admin/nextlook/main": {
    imgNum: 1,
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};
const NextLookIntroEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    tmp01: "",
    tmp02: "",
    tmp03: "",
    tmp04: "",
    tmp05: "",
    tmp06: "",
  });
  const fileRef = useRef(null);

  const location = window.location.pathname.split("/").slice(0, 4).join("/");

  let fileNum;
  if (codeType[location] !== undefined) {
    fileNum = codeType[location].imgNum;
  }
  const handleInputChange = (field, value) => {
    if (field === "ord_no") {
      const intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      if (intValue === 0 && data.brdInfo[0].ord_no !== 0) return;
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else {
      dispatch({ type: "CHANGE", field, payload: value });
    }
  };

  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [SecondModalState, setSecondModalState] = useState(false);
  const [btAble, setBtAble] = useState(false);
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, id],
    () => getTest2(id),
    {
      cacheTime: 0,
      staleTime: 0,
    },
    {
      select: (data) => {
        return data;
      },
    }
  );

  //input 함수
  const handleOnChangeData = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const { mutate: sartEditPost } = useMutation(
    (editData) => patchTest(editData),
    {
      onSuccess: () => {
        if (data.code !== "success") {
          navigate("/admin/sign-in");
        } else {
          setSecondModalState(true);
        }
      },
    }
  );

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
    if (window.opener) {
      window.opener.location.reload();
    }
  };
  // React-quill 웹 에디터의 value -> html태그를 포함하고 있기에 유효성 검사를 위해 태그를 제거
  const parsingHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // 유효성 검사
  const validation = () => {
    if (!reducerState.postTitle.trim()) {
      setModalText("제목을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!parsingHtml(reducerState.postCn).trim()) {
      setModalText("내용을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp01.trim()) {
      setModalText("A버튼 URL을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp02.includes("https://youtu.be")) {
      setModalText("올바른 유튜브 URL을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp05.trim()) {
      setModalText("A버튼 텍스트를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp06.trim()) {
      setModalText("B버튼 텍스트를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (files.length + uploadedFiles.length < fileNum && fileNum !== 0) {
      setModalText("파일을 첨부해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //파일을 선택하는 삼수
  const handleFileChange = (e) => {
    if (
      files.length + uploadedFiles.length >= fileNum ||
      e.target.files.length > uploadedFiles.length + fileNum - files.length
    ) {
      setModalText(`첨부파일 갯수는 ${fileNum}개까지 입니다.`);
      setModalState(true);
      e.target.value = "";
      return;
    }
    const FileArray = [...files];
    const newFiles = Array.from(e.target.files);
    for (let newFile of newFiles) {
      const file = { data: newFile, fileURL: URL.createObjectURL(newFile) };
      FileArray.push(file);
    }
    setFiles(FileArray);
    e.target.value = "";
  };
  //파일을 지우는 함수
  const fileDelete = (index) => {
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (fileRef.current) {
      fileRef.current.files = store.files;
    }
    setFiles(newFiles);
  };
  //서버쪽 파일을 로컬에서 지우는 함수
  const uploadedFileDelete = (index, id) => {
    setDeletedFile([...deletedFile, id]);
    const newFiles = [
      ...uploadedFiles.slice(0, index),
      ...uploadedFiles.slice(index + 1),
    ];
    setUploadedFiles(newFiles);
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //수정 완료시 호출되는 함수
  const submitEditPost = () => {
    setBtAble(true);
    if (validation()) {
      return;
    }
    const formData = new FormData();
    formData.append("brd_id", data.brdInfo[0].brd_id);
    formData.append("grp_code", data.brdInfo[0].grp_code);
    formData.append("dtl_code", data.brdInfo[0].dtl_code);
    formData.append("ttl", reducerState.postTitle);
    formData.append("dtl", reducerState.postCn);
    formData.append("tmp01", reducerState.tmp01);
    formData.append("tmp02", reducerState.tmp02);
    formData.append("tmp03", reducerState.tmp03);
    formData.append("tmp04", reducerState.tmp04);
    formData.append("tmp05", reducerState.tmp05);
    formData.append("tmp06", reducerState.tmp06);
    formData.append("file_del_array", deletedFile);
    formData.append("ord_no", data.brdInfo[0].ord_no);
    formData.append("use_yn", data.brdInfo[0].use_yn);
    for (let i = 0; i < files.length; i++) {
      formData.append("fileField", files[i].data);
    }
    sartEditPost(formData);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      } else {
        handleInputChange("postTitle", data.brdInfo[0]?.ttl);
        handleInputChange("postCn", data.brdInfo[0]?.dtl);
        handleInputChange("tmp01", data.brdInfo[0]?.tmp01);
        handleInputChange("tmp02", data.brdInfo[0]?.tmp02);
        handleInputChange("tmp03", data.brdInfo[0]?.tmp03);
        handleInputChange("tmp04", data.brdInfo[0]?.tmp04);
        handleInputChange("tmp05", data.brdInfo[0]?.tmp05);
        handleInputChange("tmp06", data.brdInfo[0]?.tmp06);
        setUploadedFiles(data?.fileInfo);
      }
    }
  }, [data, isLoading]);

  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <PageTitle title={"수정하기"} />
          <table>
            <tbody>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    value={reducerState.postTitle}
                    name="tilte"
                    type="text"
                    placeholder="제목을 입력해 주세요"
                    onChange={(e) =>
                      handleInputChange("postTitle", e.target.value)
                    }
                  />
                </td>
              </tr>
              {data.fileInfo.length ? (
                <tr className={styles["file-list"]}>
                  <th>
                    첨부파일
                    <div style={{ marginTop: "10px" }}>
                      가로: 5760px <br />
                      세로: 3240px
                    </div>
                  </th>
                  <td>
                    <label htmlFor="abc">파일첨부</label>
                    <input
                      ref={fileRef}
                      id="abc"
                      hidden
                      type="file"
                      onChange={handleFileChange}
                      multiple
                    />
                    <div className={styles["files"]}>
                      {uploadedFiles.map((file, index) => (
                        <div
                          className={styles["file-box"]}
                          key={`${file.rgnl_file_name}_${index}`}
                        >
                          <button
                            onClick={() => {
                              uploadedFileDelete(index, file.tchm_id);
                            }}
                            type="button"
                            className={styles["btn-delete"]}
                          >
                            <img src={deleteBtn} alt="" />
                          </button>
                          <img
                            src={`${process.env.REACT_APP_IMGURL}/${file.file_dntf_code}/${file.pld_file_name}`}
                            alt=""
                          />
                          <div className={styles["file"]}>
                            {file.rgnl_file_name}
                          </div>
                        </div>
                      ))}
                      {files.map((file, index) => (
                        <div
                          className={styles["file-box"]}
                          key={`${file.rgnl_file_name}_${index}`}
                        >
                          <button
                            onClick={() => {
                              fileDelete(index);
                            }}
                            type="button"
                            className={styles["btn-delete"]}
                          >
                            <img src={deleteBtn} alt="" />
                          </button>
                          <img src={file.fileURL} alt="" />
                          <div className={styles["file"]}>{file.data.name}</div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ) : null}
              <tr>
                <th>A 버튼 사용여부</th>
                <td>
                  <select
                    value={reducerState.tmp03}
                    onChange={(e) =>
                      handleOnChangeData("tmp03", e.target.value)
                    }
                  >
                    <option value={true}>사용</option>
                    <option value={false}>미사용</option>
                  </select>
                </td>
                <th>B 버튼 사용여부</th>
                <td>
                  <select
                    value={reducerState.tmp04}
                    onChange={(e) =>
                      handleOnChangeData("tmp04", e.target.value)
                    }
                  >
                    <option value={true}>사용</option>
                    <option value={false}>미사용</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>A 버튼 텍스트</th>
                <td>
                  <input
                    value={reducerState.tmp05}
                    name="tilte"
                    type="text"
                    placeholder="텍스트를 입력해 주세요"
                    onChange={(e) => handleInputChange("tmp05", e.target.value)}
                  />
                </td>
                <th>B 버튼 텍스트</th>
                <td>
                  <input
                    value={reducerState.tmp06}
                    name="tilte"
                    type="text"
                    placeholder="텍스트를 입력해 주세요"
                    onChange={(e) => handleInputChange("tmp06", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>A버튼 URL</th>
                <td>
                  <input
                    value={reducerState.tmp01}
                    name="tilte"
                    type="text"
                    placeholder="URL을 입력해 주세요"
                    onChange={(e) => handleInputChange("tmp01", e.target.value)}
                  />
                </td>
                <th>B버튼 URL</th>
                <td>
                  <input
                    value={reducerState.tmp02}
                    name="tilte"
                    type="text"
                    placeholder="URL을 입력해 주세요"
                    onChange={(e) => handleInputChange("tmp02", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>내용</th>
                <td>
                  <div style={{ height: "250px" }}>
                    <ReactQuill
                      modules={modules}
                      style={{ height: "200px" }}
                      theme="snow"
                      value={reducerState.postCn}
                      onChange={(value) => handleOnChangeData("postCn", value)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            disable={btAble}
            text="수정 완료"
            buttonFunction={submitEditPost}
          />
          {modalState ? (
            <ConfirmModal
              modalText={modalText}
              confirmText="확인"
              confirmFunction={closeModal}
            />
          ) : null}
          {SecondModalState ? (
            <ConfirmModal
              modalText="수정 완료"
              confirmText="확인"
              confirmFunction={successPost}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default NextLookIntroEdit;
