import React from "react";
import closePopup from "../../assets/images/ico/ico_close_menu.png";
import styles from "../scss/linkPopup.module.scss";

const LinkPopup = ({ setIsShowServicePopup, url }) => {
  return (
    <div className={styles["link-popup-wrap"]}>
      <button
        className={styles["btn-close-popup"]}
        onClick={() => setIsShowServicePopup(false)}
      >
        <img src={closePopup} alt="" />
      </button>
      <iframe
        id="player"
        type="text/html"
        width="100%"
        height="100%"
        src={`https://youtube.com/embed/${url}`}
        frameBorder="0"
        gesture="media"
        allow="encrypted-media"
        title="CNAI"
      ></iframe>
    </div>
  );
};

export default LinkPopup;
