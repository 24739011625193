import { QueryClient } from "@tanstack/react-query";

// getQueryClient
export const getQueryClient = (() => {
  let client = null;
  return () => {
    if (!client)
      client = new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            cacheTime: Infinity,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
        },
      });
    return client;
  };
})();

// QueryKeys
export const QueryKeys = {
  ADMIN: `${process.env.REACT_APP_ADMIN}`,
  MAIN: `${process.env.REACT_APP_LIST}`,
  LOGO: `${process.env.REACT_APP_TEMP}`,
  DETAIL: `${process.env.REACT_APP_DETAIL}`,
  DELETE: `${process.env.REACT_APP_DELETE}`,
  SIGN: `${process.env.REACT_APP_SIGN}`,
  FOOTER: `${process.env.REACT_APP_FOOTER}`,
  POPUP: `${process.env.REACT_APP_POPUP}`,
};
