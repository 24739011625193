import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import Table from "../../components/table/Table";

const Process = () => {
  const location = useLocation();
  const navigate = useNavigate();
  //마지막 페이지 값을 담을 State
  const [maximunPage, setMaximunPage] = useState();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;
  //get 요청에 필요한 parameters 선언
  const typeCodes = { grp_code: "POL", dtl_code: "POL_CR", limitLine: 10 };
  typeCodes.offset = (currentPage - 1) * typeCodes.limitLine;
  //data Get요청
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, typeCodes.dtl_code + currentPage],
    () =>
      getTest(
        typeCodes.grp_code,
        typeCodes.dtl_code,
        typeCodes.limitLine,
        typeCodes.offset
      ),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  //현재 보여질 페이지 목록 선언
  const currentPageGroup = Math.ceil(currentPage / (typeCodes.limitLine / 2));
  const startIndex = (currentPageGroup - 1) * (typeCodes.limitLine / 2) + 1;
  const endIndex = Math.min(
    startIndex + typeCodes.limitLine / 2 - 1,
    maximunPage
  );

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setMaximunPage(
          Math.ceil(data.paging[0].all_page / typeCodes.limitLine)
        );
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (currentPage < 0) navigate("/admin/pola/process?page=1");
  }, []);

  return (
    <div className="p-6">
      {!isLoading && data.code === "success" ? (
        <>
          <div className="flex w-full items-center justify-between">
            <Table
              layoutList={data.boardList}
              title={"생산과정"}
              isEnroll={true}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            startIndex={startIndex}
            endIndex={endIndex}
            maximunPage={maximunPage}
            path={"pola/process"}
          />
        </>
      ) : null}
    </div>
  );
};

export default Process;
