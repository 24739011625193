import React, { useEffect, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTest, getTest2 } from "../../api/api";
import parse from "html-react-parser";
import { QueryKeys } from "../../../util/queryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import ConfirmModal from "../../components/modal/ConfirmModal";

const RecruitInfoView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleClickMoveToListPage = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
  };

  const [modalState, setModalState] = useState(false);
  const handleClickMoveToEditPage = (id) => {
    const location = window.location.pathname.split("/");
    location.pop();
    navigate(`${location.join("/")}/edit/${id}`);
  };
  const { data, isLoading } = useQuery(
    [QueryKeys.DETAIL, id],
    () => getTest2(id),
    {
      staleTime: 0,
      cacheTime: 0,
    },
    {
      select: (data) => {
        return data;
      },
    }
  );

  const { mutate: deletePost } = useMutation((id) => deleteTest(id), {
    onSuccess: () => {
      const location = window.location.pathname.split("/");
      const path = location.slice(0, 4);
      navigate(`${path.join("/")}`);
    },
  });

  const deleteStart = () => {
    const file_del_array = [];
    for (const file of data.fileInfo) {
      file_del_array.push(file.tchm_id);
    }
    const deleteData = {
      brd_id: data.brdInfo[0].brd_id,
      file_del_array,
      dtl_code: data.brdInfo[0].dtl_code,
      tmp01: data.brdInfo[0].tmp01,
    };
    deletePost(deleteData);
  };
  const closeModal = () => {
    setModalState(false);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);

  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <div className="flex justify-between">
            <PageTitle title={"View"} />
            <Button
              className="grow-0"
              text="목록으로 이동"
              buttonFunction={handleClickMoveToListPage}
            />
          </div>
          <table>
            <tbody>
              <tr>
                <th>사용 여부</th>
                <td>{data.brdInfo[0].use_yn === "Y" ? "사용" : "미사용"}</td>
                <th>정렬 순서</th>
                <td>{data.brdInfo[0].ord_no}</td>
              </tr>
              <tr>
                <th>직군</th>
                <td>{data.brdInfo[0].tmp01}</td>
                <th>내용</th>
                <td>{data.brdInfo[0].dtl}</td>
              </tr>
              <tr>
                <th>포지션</th>
                <td>{data.brdInfo[0].ttl}</td>
                <th>경력구분</th>
                <td>{data.brdInfo[0].tmp02}</td>
              </tr>
              <tr>
                <th>고용형태</th>
                <td>{data.brdInfo[0].tmp03}</td>
                <th>채용일정</th>
                <td>{data.brdInfo[0].tmp04}</td>
              </tr>
              <tr>
                <th>URL</th>
                <td>{data.brdInfo[0].tmp05}</td>
              </tr>
              <tr>
                <th>수정자</th>
                <td>{data.brdInfo[0].mdfc_id}</td>
                <th>수정일시</th>
                <td>{data.brdInfo[0].mdfc_date}</td>
              </tr>
            </tbody>
          </table>
          <div
            className={[styles["btn-group"], "flex justify-end gap-3"].join(
              " "
            )}
          >
            <Button
              text="수정하기"
              buttonFunction={() => handleClickMoveToEditPage(id)}
            />
            {data.brdInfo[0].ord_no !== 1 ? (
              <Button
                text="삭제하기"
                buttonFunction={() => setModalState(true)}
              />
            ) : null}
          </div>
        </>
      ) : null}
      {modalState ? (
        <ConfirmModal
          modalText="삭제하시겠습니까?"
          confirmText="확인"
          closeText="취소"
          confirmFunction={deleteStart}
          handleClickCloseModal={closeModal}
        />
      ) : null}
    </div>
  );
};

export default RecruitInfoView;
