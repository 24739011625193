import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest3 } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import Tag from "../../components/tag/Tag";

function History() {
  const location = useLocation();
  const navigate = useNavigate();

  const [maximunPage, setMaximunPage] = useState();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;
  const [typeCodes, setTypeCodes] = useState({
    grp_code: "MAI",
    dtl_code: "MAI_CP",
    tmp01: "KOR",
    limitLine: 10,
  });
  const offset = (currentPage - 1) * typeCodes.limitLine;
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, typeCodes.dtl_code, typeCodes.tmp01, currentPage],
    () =>
      getTest3(
        typeCodes.grp_code,
        typeCodes.dtl_code,
        typeCodes.tmp01,
        typeCodes.limitLine,
        offset
      ),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  //현재 보여질 페이지 목록 선언
  const currentPageGroup = Math.ceil(currentPage / (typeCodes.limitLine / 2));
  const startIndex = (currentPageGroup - 1) * (typeCodes.limitLine / 2) + 1;
  const endIndex = Math.min(
    startIndex + typeCodes.limitLine / 2 - 1,
    maximunPage
  );

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setMaximunPage(
          Math.ceil(data.paging[0].all_page / typeCodes.limitLine)
        );
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (currentPage < 0) navigate("/admin/main/history?page=1");
  }, []);

  return (
    <>
      {!isLoading && data.code === "success" ? (
        <div className="p-6">
          {/* <Tag typeCodes={typeCodes} setTypeCodes={setTypeCodes} /> */}
          <div className="flex w-full items-center justify-between">
            <Table
              layoutList={data.boardList}
              title={"주요연혁"}
              isEnroll={true}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            startIndex={startIndex}
            endIndex={endIndex}
            maximunPage={maximunPage}
            path={"main/history"}
          />
        </div>
      ) : null}
    </>
  );
}
export default History;
