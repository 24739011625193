import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

// images
import language from "../../assets/images/ico/language.png";
import sendEmail from "../../assets/images/ico/sendEmail.png";
import setting from "../../assets/images/ico/setting.png";
import mobLanguage from "../../assets/images/ico/mobile_language.png";
import mobSetting from "../../assets/images/ico/mobile_setting.png";
import menu from "../../assets/images/ico/ico_menu.png";
import closeMenu from "../../assets/images/ico/ico_close_menu.png";
import { getCommon } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRecoilState } from "recoil";
import { isLoginState, languageSelector } from "../../admin/util/recoil";

const Header = ({ isBanner }) => {
  const navigate = useNavigate();
  const [languageSelect, setLanguageSelect] = useRecoilState(languageSelector);
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const location = useLocation();
  const { data } = useQuery(
    [QueryKeys.LOGO],
    () => getCommon("MAI", "MAI_LOG"),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );

  const menus = [
    { id: "pola", text: "Pola", src: "pola" },
    { id: "nexlook", text: "NexLook", src: "nexlook" },
    { id: "tivv", text: "Tivv", src: "tivv" },
    { id: "link", text: "AI Human Studio x KT", src: "" },
    { id: "people", text: "People", src: "people" },
    { id: "recruit", text: "Recruit", src: "recruit" },
    { id: "newsroom", text: "Newsroom", src: "newsroom" },
  ];

  const [windowWidth, setWindowWidth] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  // newsroom 메뉴
  const [isNewsroomMenu, setIsNewsroomhMenu] = useState(false);
  // 언어 설정 메뉴
  const [isLangMenu, setIsLangMenu] = useState(false);
  // 환경설정, 로그아웃 메뉴
  const [isSettingMenu, setIsSettingMenu] = useState(false);

  const settingRef = useRef(null);
  const menuRef = useRef(null);
  const langRef = useRef(null);

  const handleClickOutside = (event) => {
    console.log(isMobile);
    if (
      event.target.id !== "setting_btn" &&
      settingRef.current &&
      !settingRef.current.contains(event.target)
    ) {
      // 클릭된 요소가 드롭다운 외부인 경우, 드롭다운을 닫습니다.
      setIsLangMenu(false);
      setIsSettingMenu(false);
      setIsNewsroomhMenu(false);
      handleClickCloseMobileMenu();
    } else if (
      !isMobile &&
      event.target.id !== "nsMenu" &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setIsLangMenu(false);
      setIsSettingMenu(false);
      setIsNewsroomhMenu(false);
      handleClickCloseMobileMenu();
    } else if (langRef.current && !langRef.current.contains(event.target)) {
      setIsLangMenu(false);
      setIsSettingMenu(false);
      setIsNewsroomhMenu(false);
      handleClickCloseMobileMenu();
    }
  };
  const handleClickMobileMenu = () => {
    setIsOpenMobileMenu(true);
    document.documentElement.style.overflowY = "hidden";
  };
  const handleClickCloseMobileMenu = () => {
    console.log(123);
    setIsOpenMobileMenu(false);
    document.documentElement.style.overflowY = "auto";
  };
  const handleClickLinkItems = (src, id) => {
    setIsLangMenu(false);
    setIsSettingMenu(false);
    if (id === "home") {
      navigate("/");
      setIsOpenMobileMenu(false);
    } else if (id === "link") {
      window.open("https://aihumanstudio.ai/landing", "_blank", "noopener");
    } else {
      if (id !== "newsroom") {
        navigate(src);
        document.documentElement.style.overflowY = "auto";
      }
      if (id === "newsroom") {
        setIsNewsroomhMenu(!isNewsroomMenu);
        setIsLangMenu(false);
      } else {
        setIsNewsroomhMenu(false);
        handleClickCloseMobileMenu();
      }
      setIsSettingMenu(false);
    }
  };
  const handleClickDepthMenus = (src) => {
    if (src === "/newsroom/media") {
      navigate("/newsroom/media");
    } else if (src === "/newsroom/research") {
      navigate("/newsroom/research");
    } else if (src === "/newsroom/investment") {
      navigate("/newsroom/investment");
    } else if (src === "/admin/main/logo") {
      navigate("/admin/main/logo");
    } else if (src === "logout") {
      const logOut = async () => {
        const res = await axios.get(`http://43.203.193.82:7020/users/logout`,{ withCredentials: true });
        if ((res.data.code = "success")) {
          setIsLogin(false);
          navigate("/");
        }
      };
      logOut();
    }
    setIsNewsroomhMenu(false);
    setIsSettingMenu(false); // 환경설정, 로그아웃 메뉴
    handleClickCloseMobileMenu();
  };

  // 언어 설정 클릭시 호출되는 함수
  const handleClickLang = () => {
    setIsSettingMenu(false);
    setIsLangMenu(!isLangMenu);
    handleClickCloseMobileMenu();
    setIsNewsroomhMenu(false);
  };

  //setting 클릭시 호출되는 함수
  const handleClickSetting = (e) => {
    if (isLogin) {
      if (e.currentTarget.classList.contains) {
        setIsSettingMenu(true);
      }
      if (isSettingMenu) {
        setIsSettingMenu(false);
      }
    } else {
      navigate("admin/sign-in");
    }
    setIsLangMenu(false);
    setIsNewsroomhMenu(false);
  };
  //언어 dropDown의 버튼 클릭시 호출되는 함수
  const handleChangeLang = (location) => {
    navigate(`${location}`);
    setIsLangMenu(false);
    if (isMobile) {
      setIsOpenMobileMenu(false);
      document.documentElement.style.overflowY = "auto";
    }
  };

  const onResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    if (windowWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [windowWidth]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === "/en") {
      setLanguageSelect("ENG");
    } else {
      setLanguageSelect("KOR");
    }
    const checkSession = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/users/getSessionInfo`,{ withCredentials: true });
      if (res.data.code === "success") {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    };
    checkSession();
  }, [location]);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 이벤트 리스너를 추가합니다.
    document.addEventListener("mousedown", handleClickOutside);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile]);
  return (
    // 헤더
    <header
      className={[
        styles["user-header"],
        !isBanner ? styles["active"] : "",
      ].join(" ")}
    >
      <div className={[styles["inner"], "flex items-center"].join(" ")}>
        <h1 className={styles["logo"]}>
          <button
            onClick={() => {
              navigate("/");
              setIsLangMenu(false);
              setIsSettingMenu(false);
              handleClickCloseMobileMenu();
            }}
          >
            {data && (
              <img
                src={`${process.env.REACT_APP_IMGURL}/${data.fileInfo[0].file_dntf_code}/${data.fileInfo[0].pld_file_name}`}
                alt="CNAI 로고"
              />
            )}
          </button>
        </h1>
        <nav>
          <ul className="flex gap-20 md:gap-8 items-center">
            {menus.map((menu) => (
              <li key={menu.id} className="uppercase text-white">
                <button
                  id="nsMenu"
                  onClick={() => handleClickLinkItems(`/${menu.src}`, menu.id)}
                >
                  {menu.text}
                </button>
                {isNewsroomMenu && menu.id === "newsroom" && (
                  <div ref={menuRef} className={styles["depth-menu"]}>
                    <button
                      onClick={() => handleClickDepthMenus(`/newsroom/media`)}
                    >
                      Media
                    </button>
                    <button
                      onClick={() =>
                        handleClickDepthMenus(`/newsroom/research`)
                      }
                    >
                      Researches
                    </button>
                    <button
                      onClick={() =>
                        handleClickDepthMenus(`/newsroom/investment`)
                      }
                    >
                      Investment
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className={[styles["utils"], "flex"].join(" ")}>
          {isLogin && (
            <button onClick={handleClickSetting} className="setting">
              <img
                id="setting_btn"
                src={setting}
                className="block w-full h-full"
                alt=""
              />
            </button>
          )}

          {isSettingMenu && (
            <>
              <div
                ref={settingRef}
                className={[styles["sub-menu"], styles["setting-menu"]].join(
                  " "
                )}
              >
                <button
                  onClick={() => handleClickDepthMenus(`/admin/main/logo`)}
                >
                  환경설정
                </button>
                <button onClick={() => handleClickDepthMenus(`logout`)}>
                  로그아웃
                </button>
              </div>
            </>
          )}
          {/* <button className="">
            <img src={sendEmail} className="block w-full h-full" alt="" />
          </button> */}
          {isLangMenu && (
            <>
              <div
                ref={langRef}
                className={`${
                  isLogin ? styles["login-sub-menu"] : styles["sub-menu"]
                }`}
              >
                <button
                  onClick={() => {
                    handleChangeLang("/");
                  }}
                >
                  Kor
                </button>
                <button
                  onClick={() => {
                    handleChangeLang("/en");
                  }}
                >
                  Eng
                </button>
              </div>
            </>
          )}
          <button className="" onClick={handleClickLang}>
            <img src={language} className="block w-full h-full" alt="" />
          </button>
        </div>
        {isMobile && (
          <div className={styles["mobile-menu"]}>
            {!isOpenMobileMenu && (
              <button onClick={handleClickMobileMenu}>
                <img src={menu} alt="" />
              </button>
            )}
            {isOpenMobileMenu && (
              <button onClick={handleClickCloseMobileMenu}>
                <img src={closeMenu} alt="" />
              </button>
            )}
            {isOpenMobileMenu && (
              <div className={styles["mobile-menu-box"]}>
                <nav className={styles["mobile-nav"]}>
                  <ul className="flex flex-col gap-4">
                    {menus.map((menu) => (
                      <li
                        key={menu.id}
                        className="text-2xl uppercase text-white"
                      >
                        <button
                          onClick={() =>
                            handleClickLinkItems(`/${menu.src}`, menu.id)
                          }
                        >
                          {menu.text}
                        </button>
                        {isNewsroomMenu && menu.id === "newsroom" && (
                          <div
                            className={styles["depth-menu"]}
                            onMouseLeave={() => {
                              setIsNewsroomhMenu(false);
                            }}
                          >
                            <button
                              onClick={() => {
                                handleClickDepthMenus(`/newsroom/media`);
                              }}
                            >
                              Media
                            </button>
                            <button
                              onClick={() =>
                                handleClickDepthMenus(`/newsroom/research`)
                              }
                            >
                              Researches
                            </button>
                            <button
                              onClick={() =>
                                handleClickDepthMenus(`/newsroom/investment`)
                              }
                            >
                              Investment
                            </button>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div
                  className={[styles["utils"], "flex flex-col gap-6"].join(" ")}
                >
                  <div className="flex items-center mb-4 md:mb-6">
                    <img
                      src={mobLanguage}
                      alt=""
                      className="inline-block mr-[0.6875rem]"
                    />
                    <button
                      onClick={() => {
                        handleChangeLang("/");
                      }}
                      className="inline-block pr-[0.6875rem]"
                    >
                      Ko
                    </button>
                    <button
                      onClick={() => {
                        handleChangeLang("/en");
                      }}
                      className="pl-[0.6875rem]"
                    >
                      Eng
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
