import React, { useEffect, useState } from "react";
import styles from "../scss/main.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import Marquee from "react-fast-marquee";
function OurPartners({ partnerRef, partner, setPartner, languageSelect }) {
  //partners Get
  const { data: partnersData } = useQuery([QueryKeys.MAIN, "MAI_PT"], () =>
    getMainBoard("MAI", "MAI_PT", "")
  );
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  useEffect(() => {
    if (partnersData) {
      const half = Math.floor(partnersData.boardList.length / 2);
      setLeftList(partnersData.boardList.slice(0, half));
      setRightList(partnersData.boardList.slice(half));
      setPartner(1);
    }
  }, [partnersData]);
  if (!partnersData) return <div></div>;
  return (
    <section className={styles["partners"]} ref={partnerRef}>
      <h2
        className={[
          styles["title"],
          `${partner === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {partnersData.boardList[0].ttl}
      </h2>
      <span className={styles["sub-title"]}>
        {languageSelect === "KOR"
          ? parse(partnersData.boardList[0].dtl)
          : parse(partnersData.boardList[0].tmp01)}
      </span>
      <div className={[styles["marquee-wrapper"], "flex flex-col"].join(" ")}>
        <Marquee
          className={[styles["marquee-box"], "flex"].join(" ")}
          autoFill
          speed={25} // default: 50, 숫자가 작을수록 느림
        >
          <ul className="flex">
            {leftList.map((item) => (
              <li key={item.brd_id}>
                <div className="flex items-center justify-center py-2 px-4 text-center rounded bg-white">
                  <img
                    src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                    alt=""
                  />
                </div>
              </li>
            ))}
          </ul>
        </Marquee>
        <Marquee
          className={[styles["marquee-box"], "flex"].join(" ")}
          autoFill
          direction={"right"}
          speed={25} // default: 50, 숫자가 작을수록 느림
        >
          <ul className="flex">
            {rightList.map((item) => (
              <li key={item.brd_id}>
                <div className="flex items-center justify-center py-2 px-4 text-center rounded bg-white">
                  <img
                    src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                    alt=""
                  />
                </div>
              </li>
            ))}
          </ul>
        </Marquee>
      </div>
    </section>
  );
}

export default React.memo(OurPartners);
