import React from "react";
import parse from "html-react-parser";
import styles from "../scss/banner.module.scss";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import { getPopupList } from "../../api/api";
import { useLocation } from "react-router-dom";

const Banner = ({ isBanner, setIsBanner }) => {
  const location = useLocation();
  const { data: bannerData } = useQuery(
    [QueryKeys.MAIN, "OPE_PP"],
    () => getPopupList("OPE", "OPE_PP"),
    {
      select: (data) => {
        if (
          data.popList[0].xpsr_ndt.split("-").join("") >=
          new Date().toISOString().slice(0, 10).split("-").join("")
        ) {
          return data.popList[0];
        } else return false;
      },
    }
  );

  if (location.pathname === "/en") {
    return null;
  }

  return (
    <>
      {bannerData && isBanner ? (
        <div
          className={styles["banner"]}
          onClick={(event) => {
            window.open(bannerData.tmp05);
          }}
        >
          <div className="text-white">{parse(bannerData.dtl)}</div>
          <button
            className={styles["btn-delete-banner"]}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setIsBanner(false);
            }}
          >
            x
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Banner;
