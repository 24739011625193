import React, { useEffect } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { getMediaKit } from "../../api/api";
import parse from "html-react-parser";
import { QueryKeys } from "../../../util/queryClient";
import { useQuery } from "@tanstack/react-query";

const MediaKitView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    [QueryKeys.DETAIL],
    () => getMediaKit(id),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  //파일 다운로드 함수
  const downloadStart = async function (data) {
    const filePath = `${process.env.REACT_APP_IMGURL}/${data.file_dntf_code}/${data.pld_file_name}`;

    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = data.rgnl_file_name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);

  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <div className="flex justify-between">
            <PageTitle title={"Media-Kit"} />
          </div>
          <table>
            <tbody>
              <tr>
                <th>제목</th>
                <td>{data.polaInfo[0].ttl}</td>
              </tr>
              <tr>
                <th>수정자</th>
                <td>{data.polaInfo[0].mdfc_id}</td>
                <th>수정일시</th>
                <td>{data.polaInfo[0].mdfc_date}</td>
              </tr>
              <tr className={styles["file-list"]}>
                <th>CI첨부파일</th>
                <td>
                  <div className={styles["files"]}>
                    {data.polaFileInfo
                      .filter((files) => files.file_dscr === "tivv")
                      .map((file) => (
                        <div
                          className={styles["file-box"]}
                          key={file.file_id}
                          onClick={() => {
                            downloadStart(file);
                          }}
                        >
                          <div className={styles["file"]}>
                            {file.rgnl_file_name}
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
                <th>회사소개서 첨부파일</th>
                <td>
                  <div className={styles["files"]}>
                    {data.polaFileInfo
                      .filter((files) => files.file_dscr === "pola")
                      .map((file) => (
                        <div
                          className={styles["file-box"]}
                          key={file.file_id}
                          onClick={() => {
                            downloadStart(file);
                          }}
                        >
                          <div className={styles["file"]}>
                            {file.rgnl_file_name}
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th>내용</th>
                <td>{parse(data.polaInfo[0].dtl)}</td>
              </tr>
            </tbody>
          </table>
          <div
            className={[styles["btn-group"], "flex justify-end gap-3"].join(
              " "
            )}
          ></div>
        </>
      ) : null}
    </div>
  );
};

export default MediaKitView;
