import React, { useReducer, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import deleteBtn from "../../assets/images/ico/btn_close_sm.png";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { QueryKeys } from "../../../util/queryClient";
import { getDtlCodes, postPopUp } from "../../api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmModal";
import "../../util/font";
import { useEffect } from "react";

const modules = {
  toolbar: {
    container: [
      [
        {
          size: [
            "10px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "28px",
            "30px",
            "32px",
            "36px",
            "38px",
            "40px",
            "42px",
            "44px",
            "46px",
            "48px",
            "50px",
            "52px",
            "64px",
            "80px",
          ],
        },
      ],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      [{ align: ["left", "center", "right", "justify"] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};

function PopUpEnroll() {
  const navigate = useNavigate();
  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    dtlCode: "",
    ord_no: "",
    tmp01: "",
    tmp02: "",
    tmp03: "",
    tmp04: "",
    tmp05: "",
  });
  //input 함수
  const handleOnChangeData = (field, value) => {
    if (field === "ord_no") {
      const intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else if (field === "tmp01" || field === "tmp02") {
      let intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      if (isNaN(intValue)) {
        intValue = "";
      }
      if (intValue > 100) return;
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else {
      dispatch({ type: "CHANGE", field, payload: value });
    }
  };
  //오늘 날짜를 계산
  const today = new Date().toISOString().slice(0, 10);
  const [files, setFiles] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [SecondModalState, setSecondModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [YNState, setYNState] = useState("Y");
  const [startDate, setStartdDate] = useState(today);
  const [endDate, setEnddDate] = useState(today);
  const [btAble, setBtAble] = useState(false);
  const fileRef = useRef(null);
  //YN 값을 바꾸는 함수
  const handleYNChange = (event) => {
    if (YNState === "N") {
      setStartdDate(today);
      setEnddDate(today);
    } else {
      setStartdDate("");
      setEnddDate("");
    }
    setYNState(event.target.value);
  };

  const handleStartDate = (event) => {
    if (
      +event.target.value.split("-").join("") > +endDate.split("-").join("")
    ) {
      setEnddDate(event.target.value);
    }
    setStartdDate(event.target.value);
  };
  const handleEndtDate = (event) => {
    if (
      +event.target.value.split("-").join("") < +startDate.split("-").join("")
    ) {
      setStartdDate(event.target.value);
      setEnddDate(event.target.value);
      return;
    }
    setEnddDate(event.target.value);
  };

  //파일을 선택하는 삼수
  const handleFileChange = (e) => {
    if (files.length === 1 || e.target.files.length > 1 - files.length) {
      setModalText(`첨부파일 갯수는 1개까지 입니다.`);
      setModalState(true);
      e.target.value = "";
      return;
    }
    const FileArray = [...files];
    const newFiles = Array.from(e.target.files);
    for (let newFile of newFiles) {
      const file = { data: newFile, fileURL: URL.createObjectURL(newFile) };
      FileArray.push(file);
    }
    setFiles(FileArray);
    e.target.value = "";
  };
  //선택된 파일을 지우는 함수
  const fileDelete = (index) => {
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];

    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (fileRef.current) {
      fileRef.current.files = store.files;
    }
    setFiles(newFiles);
  };
  //작성시 호출되는 뮤테이션
  const { mutate: startPost } = useMutation((postData) => postPopUp(postData), {
    onSuccess: (data) => {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setSecondModalState(true);
      }
    },
  });
  //디테일 코드를 불러옴
  const { data, isLoading } = useQuery(
    [QueryKeys.DETAIL],
    () => getDtlCodes("POP_TYPE"),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
  };

  // React-quill 웹 에디터의 value -> html태그를 포함하고 있기에 유효성 검사를 위해 태그를 제거
  const parsingHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // 유효성 검사
  const validation = () => {
    if (!reducerState.postTitle.trim()) {
      setModalText("제목을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!parsingHtml(reducerState.postCn).trim()) {
      setModalText("내용을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.pop_type_code) {
      setModalText("유형을 선택해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (isNaN(reducerState.tmp01) || typeof reducerState.tmp01 === "string") {
      setModalText("X좌표를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (isNaN(reducerState.tmp02) || typeof reducerState.tmp02 === "string") {
      setModalText("Y좌표를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp03.trim()) {
      setModalText("가로길이를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp04.trim()) {
      setModalText("세로길이를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp05.trim()) {
      setModalText("URL을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (reducerState.ord_no < 2 && !isNaN(reducerState.ord_no)) {
      setModalText("정렬순서를 입력해주세요(2이상)");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //작성 완료시 호출되는 함수
  const submitPost = () => {
    setBtAble(true);
    if (validation()) {
      return;
    }
    const formData = new FormData();
    formData.append("ttl", reducerState.postTitle);
    formData.append("dtl", reducerState.postCn);
    formData.append("dtl_code", "OPE");
    formData.append("grp_code", "OPE_PP");
    formData.append("ord_no", reducerState.ord_no);
    formData.append("pop_type_code", reducerState.pop_type_code);
    formData.append("xpsr_stpr_yn", YNState);
    formData.append("xpsr_strt", startDate.split("-").join(""));
    formData.append("xpsr_ndt", endDate.split("-").join(""));
    formData.append("tmp01", reducerState.tmp01);
    formData.append("tmp02", reducerState.tmp02);
    formData.append("tmp03", reducerState.tmp03);
    formData.append("tmp04", reducerState.tmp04);
    formData.append("tmp05", reducerState.tmp05);

    for (let i = 0; i < files.length; i++) {
      formData.append("fileField", files[i].data);
    }

    startPost(formData);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <PageTitle title={"등록하기"} />
          <table>
            <tbody>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    value={reducerState.postTitle}
                    name="tilte"
                    type="text"
                    placeholder="제목을 입력해 주세요"
                    onChange={(e) =>
                      handleOnChangeData("postTitle", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>정렬순서</th>
                <td>
                  <input
                    value={reducerState.ord_no || ""}
                    name="ord_no"
                    type="text"
                    placeholder="정렬순서를 입력해주세요"
                    onChange={(e) =>
                      handleOnChangeData("ord_no", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>팝업 유형</th>
                <td>
                  <select
                    value={reducerState.pop_type_code}
                    onChange={(e) =>
                      handleOnChangeData("pop_type_code", e.target.value)
                    }
                  >
                    <option value={""}>선택</option>
                    {data.dtlCodeList.map((item) => (
                      <option key={item.dtl_code_id} value={item.dtl_code_id}>
                        {item.dtl_code_nm}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>노출기간설정</th>
                <td>
                  <select value={YNState} onChange={(e) => handleYNChange(e)}>
                    <option value={"Y"}>사용</option>
                    <option value={"N"}>미사용</option>
                  </select>
                </td>
              </tr>
              <tr
                className={`${[
                  styles["period"],
                  YNState === "N" ? styles["dimmed"] : "",
                ].join(" ")}`}
              >
                <th>공지기간</th>
                <td>
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDate}
                  />
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndtDate}
                  />
                </td>
              </tr>
              <tr>
                <th>X좌표</th>
                <td>
                  <input
                    value={reducerState.tmp01}
                    name="tmp01"
                    type="text"
                    placeholder="x좌표를 입력해주세요"
                    onChange={(e) =>
                      handleOnChangeData("tmp01", e.target.value)
                    }
                  />
                </td>
                <th>Y좌표</th>
                <td>
                  <input
                    value={reducerState.tmp02}
                    name="tmp02"
                    type="text"
                    placeholder="y좌표를 입력해주세요"
                    onChange={(e) =>
                      handleOnChangeData("tmp02", e.target.value)
                    }
                  />
                </td>
              </tr>

              <tr>
                <th>가로길이</th>
                <td>
                  <input
                    value={reducerState.tmp03}
                    name="tmp03"
                    type="text"
                    placeholder="가로길이를 입력해주세요"
                    onChange={(e) =>
                      handleOnChangeData("tmp03", e.target.value)
                    }
                  />
                </td>
                <th>세로길이</th>
                <td>
                  <input
                    value={reducerState.tmp04}
                    name="tmp04"
                    type="text"
                    placeholder="세로길이를 입력해주세요"
                    onChange={(e) =>
                      handleOnChangeData("tmp04", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>URL</th>
                <td>
                  <input
                    value={reducerState.tmp05}
                    name="tmp05"
                    type="text"
                    placeholder="URL을 입력해주세요"
                    onChange={(e) =>
                      handleOnChangeData("tmp05", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr className={styles["file-list"]}>
                <th>
                  첨부파일
                  <div style={{ marginTop: "10px", lineHeight: "1.18" }}>
                    가로: 가로길이 <br />
                    세로: 세로길이
                  </div>
                </th>
                <td>
                  <label htmlFor="abc">파일첨부</label>
                  <input
                    accept="image/*"
                    ref={fileRef}
                    id="abc"
                    hidden
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                  <div className={styles["files"]}>
                    {files.map((file, index) => (
                      <div
                        className={styles["file-box"]}
                        key={`${file.rgnl_file_name}_${index}`}
                      >
                        <button
                          onClick={() => {
                            fileDelete(index);
                          }}
                          type="button"
                          className={styles["btn-delete"]}
                        >
                          <img src={deleteBtn} alt="" />
                        </button>
                        <img src={file.fileURL} alt="" />
                        <div className={styles["file"]}>{file.data.name}</div>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th>내용</th>
                <td>
                  <div style={{ height: "350px" }}>
                    <ReactQuill
                      modules={modules}
                      style={{ height: "300px" }}
                      theme="snow"
                      value={reducerState.postCn}
                      onChange={(value) => handleOnChangeData("postCn", value)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : null}
      <Button disable={btAble} text="등록 완료" buttonFunction={submitPost} />
      {modalState ? (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          confirmFunction={closeModal}
        />
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="작성 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
}
export default PopUpEnroll;
