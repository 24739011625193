import React from "react";
import styles from "../scss/pagination/pagination.module.scss";

// images
import iconPrev from "../../assets/images/ico/arrow_right.png";
import iconNext from "../../assets/images/ico/arrow_right.png";
import { useNavigate } from "react-router-dom";

const Pagination = ({
  currentPage,
  startIndex,
  endIndex,
  maximunPage,
  path,
}) => {
  const navigate = useNavigate();
  //1페이지로 이동
  const handleFirstPage = () => {
    navigate(`/admin/${path}?page=1`);
  };
  //다음 페이지 이동
  const handleNextPage = () => {
    navigate(`/admin/${path}?page=${currentPage + 1}`);
  };
  // 이전 페이지 이동
  const handlePrevPage = () => {
    navigate(`/admin/${path}?page=${currentPage - 1}`);
  };
  //마지막 페이지로 이동
  const handleLastPage = () => {
    navigate(`/admin/${path}?page=${maximunPage}`);
  };
  const MoveToView = (page) => {
    navigate(`/admin/${path}?page=${page}`);
  };
  return (
    <div className="flex justify-center w-full p-6">
      {/* 처음 페이지로 이동 */}
      <div
        className={[styles["page-btns"], styles["prev-btn-box"], "mr-1"].join(
          " "
        )}
      >
        <button
          disabled={currentPage === 1}
          onClick={handleFirstPage}
          className={styles["btn-prev"]}
        >
          <img src={iconPrev} alt="" />
          <img className="-ml-[0.125rem]" src={iconPrev} alt="" />
        </button>
      </div>
      <div className={[styles["page-btns"], styles["prev-btn-box"]].join(" ")}>
        <button
          onClick={handlePrevPage}
          className={styles["btn-prev"]}
          disabled={currentPage === 1}
        >
          <img src={iconPrev} alt="" />
        </button>
      </div>
      <ol className="flex gap-2 max-w-[11.4rem] mx-3 overflow-hidden">
        {Array.from(
          { length: endIndex - startIndex + 1 },
          (_, i) => startIndex + i
        ).map((page) => (
          <button
            className={[
              styles["page-btns"],
              styles["page-nums"],
              "flex items-center justify-center",
              `${currentPage === page ? styles.active : ""}`,
            ].join(" ")}
            key={page}
            onClick={() => {
              MoveToView(page);
            }}
            disabled={page === currentPage}
          >
            {page}
          </button>
        ))}
      </ol>
      <div className={[styles["page-btns"], styles["next-btn-box"]].join(" ")}>
        <button
          onClick={handleNextPage}
          className={styles["btn-next"]}
          disabled={currentPage === maximunPage}
        >
          <img src={iconNext} alt="" />
        </button>
      </div>
      <div
        className={[styles["page-btns"], styles["next-btn-box"], "ml-1"].join(
          " "
        )}
      >
        <button
          onClick={handleLastPage}
          disabled={currentPage === maximunPage}
          className={styles["btn-next"]}
        >
          <img className="-mr-[0.125rem]" src={iconNext} alt="" />
          <img src={iconNext} alt="" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
