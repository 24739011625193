import React from "react";

/* -------- Style  -------- */
import styles from "../scss/layouts/header.module.scss";
import logo from "../../assets/images/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoginState } from "../../util/recoil";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getLogOut } from "../../api/api";
import axios from "axios";

function Header() {
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useRecoilState(isLoginState);

  const handleLogout = () => {
    const logOut = async () => {
      const res = await axios.get(`http://43.203.193.82:7020/users/logout`,{ withCredentials: true });
      if ((res.data.code = "success")) {
        setIsLogin(false);
        if (window.opener) {
          navigate("/admin/sign-in");
        } else {
          navigate("/");
        }
      }
    };
    logOut();
  };

  return (
    <>
      <header>
        <div className={styles["container"]}>
          <div
            className={[
              styles["inner"],
              "flex",
              "justify-between",
              "items-center",
              "w-full",
              "h-full",
            ].join(" ")}
          >
            <div className={styles["logo"]}>
              <div className={styles["img-logo"]}>
                <img src={logo} alt="" />
              </div>
            </div>

            <div className={[styles["user-select"], "flex gap-2"].join(" ")}>
              {isLogin ? (
                <button
                  className={styles["logout"]}
                  onClick={() => handleLogout()}
                >
                  로그아웃
                </button>
              ) : (
                <Link to={"/admin/sign-in"} className={styles["login"]}>
                  로그인
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
