import React, { useReducer, useRef, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { createMediaKit } from "../../api/api";
import ConfirmModal from "../../components/modal/ConfirmModal";
import deleteBtn from "../../assets/images/ico/btn_close_sm.png";
import ReactQuill from "react-quill";
import "../../util/font";

const modules = {
  toolbar: {
    container: [
      [
        {
          size: [
            "10px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "28px",
            "30px",
            "32px",
            "36px",
            "38px",
            "40px",
            "42px",
            "44px",
            "46px",
            "48px",
            "50px",
            "52px",
            "64px",
            "80px",
          ],
        },
      ],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      [{ align: ["left", "center", "right", "justify"] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};
const MediaKitEnroll = () => {
  const navigate = useNavigate();

  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    tmp01: "",
  });
  const tivvfileRef = useRef(null);
  const polafileRef = useRef(null);

  const handleInputChange = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const [tivvFiles, setTivvFiles] = useState([]);
  const [polaFiles, setPolaFiles] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [SecondModalState, setSecondModalState] = useState(false);
  const [btAble, setBtAble] = useState(false);

  //input 함수
  const handleOnChangeData = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const { mutate: sartEditPost } = useMutation(
    (editData) => createMediaKit(editData),
    {
      onSuccess: (data) => {
        if (data.code !== "success") {
          navigate("/admin/sign-in");
        } else {
          setSecondModalState(true);
        }
      },
    }
  );

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
    if (window.opener) {
      window.opener.location.reload();
    }
  };

  const validation = () => {
    if (!reducerState.postTitle.trim()) {
      setModalText("제목을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp01) {
      setModalText("언어를 선택해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.postCn.trim()) {
      setModalText("내용을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (tivvFiles.length < 1 && polaFiles.length < 1) {
      setModalText("파일을 첨부해주세요");
      setBtAble(false);
      setModalState(true);
      return true;
    }
  };
  //tivv파일을 선택하는 삼수
  const handleTivvFileChange = (e) => {
    if (e.target.files.length > 1) {
      setModalText(`첨부파일 갯수는 1개까지 입니다.`);
      setModalState(true);
      e.target.value = "";
      return;
    }
    const newFiles = Array.from(e.target.files);
    setTivvFiles(newFiles);
    e.target.value = "";
  };
  const handlePolaFileChange = (e) => {
    if (e.target.files.length > 1) {
      setModalText(`첨부파일 갯수는 1개까지 입니다.`);
      setModalState(true);
      e.target.value = "";
      return;
    }
    const newFiles = Array.from(e.target.files);
    setPolaFiles(newFiles);
    e.target.value = "";
  };
  //tivv파일을 지우는 함수
  const tivvFileDelete = (index) => {
    const newFiles = [
      ...tivvFiles.slice(0, index),
      ...tivvFiles.slice(index + 1),
    ];
    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (tivvfileRef.current) {
      tivvfileRef.current.files = store.files;
    }
    setTivvFiles(newFiles);
  };
  //pola 파일을 지우는 함수
  const polaFileDelete = (index) => {
    const newFiles = [
      ...polaFiles.slice(0, index),
      ...polaFiles.slice(index + 1),
    ];
    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (polafileRef.current) {
      polafileRef.current.files = store.files;
    }
    setPolaFiles(newFiles);
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //수정 완료시 호출되는 함수
  const submitEditPost = () => {
    setBtAble(true);
    if (validation()) {
      return;
    }
    const formData = new FormData();
    const polaInfo = {
      ttl: reducerState.postTitle,
      dtl: reducerState.postCn,
      tmp01: reducerState.tmp01,
      dtl_code: "MAI_KT",
      grp_code: "MAI",
      ord_no: 0,
    };

    formData.append("dtl_code", "MAI_KT");
    formData.append("grp_code", "MAI");
    formData.append("polaInfo", JSON.stringify(polaInfo));
    formData.append("polaField", polaFiles[0]);
    formData.append("tivvField", tivvFiles[0]);
    sartEditPost(formData);
  };
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <PageTitle title={""} />
      <table>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <input
                value={reducerState.postTitle}
                name="tilte"
                type="text"
                placeholder="제목을 입력해 주세요"
                onChange={(e) => handleInputChange("postTitle", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>언어</th>
            <td>
              <select
                value={reducerState.nncmType}
                onChange={(e) => handleInputChange("tmp01", e.target.value)}
              >
                <option value={""}>선택</option>
                <option value={"KOR"}>한국어</option>
                <option value={"ENG"}>영어</option>
              </select>
            </td>
          </tr>
          <tr className={styles["file-list"]}>
            <th>CI 첨부파일</th>
            <td>
              <label htmlFor="abc">파일첨부</label>
              <input
                ref={tivvfileRef}
                id="abc"
                hidden
                type="file"
                onChange={handleTivvFileChange}
                multiple
              />
              <div className={styles["files"]}>
                {tivvFiles.map((file, index) => (
                  <div
                    className={styles["file-box"]}
                    key={`${file.rgnl_file_name}_${index}`}
                  >
                    <button
                      onClick={() => {
                        tivvFileDelete(index);
                      }}
                      type="button"
                      className={styles["btn-delete"]}
                    >
                      <img src={deleteBtn} alt="" />
                    </button>
                    <div className={styles["file"]}>{file.name}</div>
                  </div>
                ))}
              </div>
            </td>
            <th>회사소개서 첨부파일</th>
            <td>
              <label htmlFor="cba">파일첨부</label>
              <input
                ref={polafileRef}
                id="cba"
                hidden
                type="file"
                onChange={handlePolaFileChange}
                multiple
              />
              <div className={styles["files"]}>
                {polaFiles.map((file, index) => (
                  <div
                    className={styles["file-box"]}
                    key={`${file.rgnl_file_name}_${index}`}
                  >
                    <button
                      onClick={() => {
                        polaFileDelete(index);
                      }}
                      type="button"
                      className={styles["btn-delete"]}
                    >
                      <img src={deleteBtn} alt="" />
                    </button>
                    <div className={styles["file"]}>{file.name}</div>
                  </div>
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td>
              <div style={{ height: "350px" }}>
                <ReactQuill
                  modules={modules}
                  style={{ height: "300px" }}
                  theme="snow"
                  value={reducerState.postCn}
                  onChange={(value) => handleOnChangeData("postCn", value)}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Button
        disable={btAble}
        text="등록 완료"
        buttonFunction={submitEditPost}
      />
      {modalState ? (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          confirmFunction={closeModal}
        />
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="등록 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
};

export default MediaKitEnroll;
