import React from "react";
import styles from "../../components/scss/pagination/pagination.module.scss";
import { useNavigate } from "react-router-dom";
function Tag({ typeCodes, setTypeCodes }) {
  const navigate = useNavigate();
  const handleTage = (tag) => {
    setTypeCodes({ ...typeCodes, tmp01: tag });
    navigate("");
  };
  return (
    <div className="flex items-center gap-2 mb-4">
      <button
        className={[
          styles["btn-tag"],
          "flex items-center gap-2 px-4 py-1 hover:border-slate-500 hover:text-slate-500 text-sm rounded border border-slate-400 border-solid text-slate-400 ease-in-out",
        ].join(" ")}
        onClick={() => handleTage("KOR")}
      >
        KOR
      </button>
      <button
        className={[
          styles["btn-tag"],
          "flex items-center gap-2 px-4 py-1 hover:border-slate-500 hover:text-slate-500 text-sm rounded border border-slate-400 border-solid text-slate-400 ease-in-out",
        ].join(" ")}
        onClick={() => handleTage("ENG")}
      >
        ENG
      </button>
    </div>
  );
}

export default Tag;
