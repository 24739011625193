import React, { useEffect, useState } from "react";
import styles from "../scss/table/codeManageTable.module.scss";
import Button from "../button/Button";
import DtlCodeTable from "./DtlCodeTable";
import PageTitle from "../title/PageTitle";
import CodeCreateModal from "../modal/CodeCreateModal";
import CodeEditModal from "../modal/CodeEditModal";
import ConfirmModal from "../modal/ConfirmModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postGrpCodes } from "../../api/api";
import { QueryKeys } from "../../../util/queryClient";

let groupHeaderList = [
  { id: 1, title: "그룹코드" },
  { id: 2, title: "그룹코드명" },
  { id: 3, title: "사용 여부" },
];

const CodeManageTable = ({ grpList }) => {
  const queryClient = useQueryClient();
  // State
  const [layoutHeaderList, setLayoutHeaderList] = useState(groupHeaderList);
  const [grpState, setGrpState] = useState([]);
  const [isAddCode, setIsAddCode] = useState(false);
  const [isEditCode, setIsEditCode] = useState(false);
  const [modalState, setModalState] = useState(false);
  //삭제 함수
  const { mutate: deleteCode } = useMutation(
    (postData) => postGrpCodes(postData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ADMIN],
          refetchType: "active",
        });
        setModalState(false);
      },
    }
  );

  //그룹 코드 추가 버튼
  const addGrpCode = () => {
    setIsAddCode(true);
  };
  const editGrpCode = () => {
    setIsEditCode(true);
  };
  const deleteGrpCode = () => {
    if (grpState.dtl_length > 0) {
      setModalState(2);
      return;
    }
    setModalState(1);
  };
  const handleClickCloseAddCodeModal = () => {
    setIsAddCode(false);
  };
  const handleClickCloseEditCodeModal = () => {
    setIsEditCode(false);
  };
  const handleClickConfirmModal = () => {
    setModalState(false);
  };
  const deleteStart = () => {
    const postData = {
      grp_code_id: grpState.grp_code_id,
      grp_code_dscr: grpState.grp_code_dscr,
      grp_code_nm: grpState.grp_code_nm,
      status: "D",
    };
    deleteCode(postData);
  };

  useEffect(() => {
    setGrpState(grpList[0]);
  }, [grpList]);
  return (
    <>
      <div className={styles["content-box"]}>
        <PageTitle title={"그룹 코드관리"} />
        <div className="flex gap-2 justify-end mb-4">
          <Button text="코드추가" buttonFunction={addGrpCode} />
          <Button text="수정하기" buttonFunction={editGrpCode} />
          <Button text="삭제" buttonFunction={deleteGrpCode} />
        </div>
        <div className="w-full flex align-middle">
          {/* 그룹코드 */}
          <div
            className={[
              styles["top-section"],
              "my-3 flex justify-between",
            ].join(" ")}
          ></div>
          <div className={[styles["list-wrapper"], "w-full"].join(" ")}>
            <div className={styles["list-header"]}>
              <ul className={[styles["list-box"], "flex"].join(" ")}>
                {layoutHeaderList.map((item, index) => (
                  <li
                    key={index + `${item.title}`}
                    className={[
                      styles["title-list-item"],
                      "flex items-center w-1/2",
                    ].join(" ")}
                  >
                    <div className={styles["header-text"]}>{item.title}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles["list-container"]}>
              <ul className={styles["list-box"]}>
                {grpList?.map((item, index) => (
                  <li
                    className={[
                      item.grp_code_id === grpState.grp_code_id
                        ? [
                            styles["content-list-item"],
                            styles["active"],
                            "flex items-center hover:bg-[#d3eee5]",
                          ].join(" ")
                        : styles["content-list-item"],
                      "flex items-center hover:bg-[#d3eee5]",
                    ].join(" ")}
                    key={item.grp_code_id}
                    onClick={() => setGrpState(item)}
                  >
                    {/* 그룹코드 */}
                    <div
                      className={[
                        styles["number"],
                        styles["list-item"],
                        "w-1/2",
                      ].join(" ")}
                    >
                      {item.grp_code_id}
                    </div>
                    {/* 그룹코드명 */}
                    <div
                      className={[
                        styles["name"],
                        styles["list-item"],
                        "w-1/2",
                      ].join(" ")}
                    >
                      {item.grp_code_nm}
                    </div>
                    {/* 사용여부 */}
                    <div
                      className={[
                        styles["name"],
                        styles["list-item"],
                        "w-1/2",
                      ].join(" ")}
                    >
                      {item.use_yn}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <DtlCodeTable grpCode={grpState.grp_code_id} />
      {isAddCode && (
        <CodeCreateModal
          onClick={handleClickCloseAddCodeModal}
          grpList={grpList}
        />
      )}
      {isEditCode && (
        <CodeEditModal
          onClick={handleClickCloseEditCodeModal}
          codeData={grpState}
          setCode={setGrpState}
        />
      )}
      {modalState === 1 ? (
        <ConfirmModal
          modalText="삭제하시겠습니까?"
          confirmText="확인"
          closeText="취소"
          confirmFunction={deleteStart}
          handleClickCloseModal={handleClickConfirmModal}
        />
      ) : null}
      {modalState === 2 ? (
        <ConfirmModal
          modalText="그룹코드내 상세코드가 존재합니다"
          confirmText="확인"
          confirmFunction={handleClickConfirmModal}
        />
      ) : null}
    </>
  );
};

export default CodeManageTable;
