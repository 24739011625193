import React, { useEffect, useState } from "react";
import styles from "../scss/main.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../scss/mainSwiper.scss";
import { Mousewheel } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import { getMainBoard } from "../../api/api";
function History({
  history,
  historyRef,
  historySwiperRef,
  setHistory,
  languageSelect,
}) {
  //history Get
  const { data: historyData } = useQuery(
    [QueryKeys.MAIN, "MAI_CP", languageSelect],
    () => getMainBoard("MAI", "MAI_CP", languageSelect),
    {
      select: (data) => {
        return data.boardList.reverse();
      },
    }
  );
  const [aaa, abasd] = useState();

  useEffect(() => {
    if (historyData) {
      setHistory(1);
    }
  }, []);
  if (!historyData) return <div></div>;
  return (
    <section className={styles["history"]} ref={historyRef}>
      <h2
        className={[
          styles["title"],
          `${history === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        History
      </h2>
      <div className={styles["history-group"]}>
        <div className={styles["history-box"]}>
          <Swiper
            modules={[Mousewheel]}
            spaceBetween={20}
            slidesPerView={"auto"}
            centeredSlides
            direction={"vertical"}
            ref={historySwiperRef}
            mousewheel
            slidesOffsetBefore={-167}
            onReachEnd={() => {
              if (historySwiperRef.current) {
                historySwiperRef.current.swiper.mousewheel.disable();
              }
              setTimeout(function () {
                historySwiperRef.current.swiper.params.touchReleaseOnEdges = true;
                historySwiperRef.current.swiper.params.mousewheel.releaseOnEdges = true;
              }, 500);
            }}
            onReachBeginning={() => {
              setTimeout(function () {
                historySwiperRef.current.swiper.params.touchReleaseOnEdges = true;
                historySwiperRef.current.swiper.params.mousewheel.releaseOnEdges = true;
              }, 500);
            }}
            onSlideChange={(swiper) => {
              setTimeout(function () {
                if (swiper.isEnd) {
                  historySwiperRef.current.swiper.params.slidesOffsetBefore = 27;
                  historySwiperRef.current.swiper.update();
                }
                if (swiper.isBeginning) {
                  historySwiperRef.current.swiper.params.slidesOffsetBefore =
                    -167;
                  historySwiperRef.current.swiper.update();
                }
                historySwiperRef.current.swiper.params.touchReleaseOnEdges = false;
                historySwiperRef.current.swiper.params.mousewheel.releaseOnEdges = false;
              }, 50);
            }}
            className={styles["history-list"]}
            breakpoints={{
              360: {
                spaceBetween: 48,
              },
              768: {
                spaceBetween: 10,
              },
              // 1024: {
              //   slidesPerView: 5,
              //   spaceBetween: 50,
              // },
            }}
          >
            {historyData?.map((item, index) => (
              <SwiperSlide
                key={item.brd_id}
                className={[
                  "history-item",
                  styles["history-item"],
                  "flex items-center gap-x-[6.75rem]",
                  index === historyData.length - 1 ? styles["last-slide"] : "",
                ].join(" ")}
              >
                <div className={styles["year"]}>{item.ttl}</div>
                <div className={styles["contents"]}>
                  <div className={styles["text"]}>{parse(item.dtl)}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* <div className={styles["blur"]}></div> */}
      </div>
    </section>
  );
}

export default React.memo(History);
