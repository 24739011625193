import React, { useEffect } from "react";
import styles from "../scss/nexlook.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function Advantage({ advantage, advantageRef, setAdvantage }) {
  //coreValue Get
  const { data: advantageData } = useQuery([QueryKeys.MAIN, "NXT_UN"], () =>
    getMainBoard("NXT", "NXT_UN", "")
  );
  useEffect(() => {
    if (advantageData) {
      setAdvantage(1);
    }
  }, [advantageData]);
  if (!advantageData) return <div></div>;
  return (
    <section className={styles["advantage"]} ref={advantageRef}>
      <div className={styles["text-box"]}>
        <h2
          className={[
            styles["title"],
            // `${scrollY > advantageTop - 500 ? styles["active"] : ""}`,
            `${advantage === 2 ? styles["active"] : ""}`,
          ].join(" ")}
        >
          {parse(advantageData.boardList[0].dtl)}
        </h2>
        <div className={styles["advantage-box"]}>
          <ul>
            {advantageData.boardList.slice(1).map((item) => (
              <li key={item.brd_id}>{parse(item.dtl)}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Advantage);
