import React, { useReducer, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { postTest } from "../../api/api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmModal";
import "../../util/font";
export const codeType = {
  "/admin/main/awards/enroll": {
    grp_code_id: "MAI",
    dtl_code_id: "MAI_AW",
  },
  "/admin/main/history/enroll": {
    grp_code_id: "MAI",
    dtl_code_id: "MAI_CP",
  },
  "/admin/main/core-values/enroll": {
    grp_code_id: "MAI",
    dtl_code_id: "MAI_CV",
  },
};
const modules = {
  toolbar: {
    container: [
      [
        {
          size: [
            "10px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "28px",
            "30px",
            "32px",
            "36px",
            "38px",
            "40px",
            "42px",
            "44px",
            "46px",
            "48px",
            "50px",
            "52px",
            "64px",
            "80px",
          ],
        },
      ],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      [{ align: ["left", "center", "right", "justify"] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};

const MainEnroll = () => {
  const navigate = useNavigate();
  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    ord_no: "",
    tmp01: "",
  });
  const handleInputChange = (field, value) => {
    if (field === "ord_no") {
      const intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      if (intValue === 0) return;
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else {
      dispatch({ type: "CHANGE", field, payload: value });
    }
  };
  const [btAble, setBtAble] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [SecondModalState, setSecondModalState] = useState(false);
  const [modalText, setModalText] = useState("");

  //input 함수
  const handleOnChangeData = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const { mutate: startPost } = useMutation((postData) => postTest(postData), {
    onSuccess: (data) => {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setSecondModalState(true);
      }
    },
  });

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
    if (window.opener) {
      window.opener.location.reload();
    }
  };
  // React-quill 웹 에디터의 value -> html태그를 포함하고 있기에 유효성 검사를 위해 태그를 제거
  const parsingHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // 유효성 검사
  const validation = () => {
    if (!reducerState.postTitle.trim()) {
      setModalText("제목을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!parsingHtml(reducerState.postCn).trim()) {
      setModalText("내용을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (reducerState.ord_no < 1 && !isNaN(reducerState.ord_no)) {
      if (reducerState.ord_no === 0) return false;
      else {
        setModalText("정렬순서를 입력해주세요");
        setModalState(true);
        setBtAble(false);
        return true;
      }
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };

  //작성 완료시 호출되는 함수
  const submitPost = () => {
    setBtAble(true);
    if (validation()) return;
    const formData = new FormData();
    formData.append("ttl", reducerState.postTitle);
    formData.append("dtl", reducerState.postCn);
    formData.append("ord_no", reducerState.ord_no);
    formData.append("tmp01", "KOR");
    formData.append(
      "dtl_code",
      codeType[`${window.location.pathname}`].dtl_code_id
    );
    formData.append(
      "grp_code",
      codeType[`${window.location.pathname}`].grp_code_id
    );
    startPost(formData);
  };
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <PageTitle title={"등록하기"} />
      <table>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <input
                value={reducerState.postTitle}
                name="tilte"
                type="text"
                placeholder="제목을 입력해 주세요"
                onChange={(e) => handleInputChange("postTitle", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>정렬순서</th>
            <td>
              <input
                value={reducerState.ord_no || ""}
                name="ord_no"
                type="text"
                placeholder="정렬순서를 입력해주세요"
                onChange={(e) => handleInputChange("ord_no", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td>
              <div style={{ height: "350px" }}>
                <ReactQuill
                  modules={modules}
                  style={{ height: "300px" }}
                  theme="snow"
                  value={reducerState.postCn}
                  onChange={(value) => handleOnChangeData("postCn", value)}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Button disable={btAble} text="등록 완료" buttonFunction={submitPost} />
      {modalState ? (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          confirmFunction={closeModal}
        />
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="작성 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
};

export default MainEnroll;
