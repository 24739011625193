import React, { useEffect } from "react";
import styles from "../scss/pola.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import { useState } from "react";

import faqShow from "../../assets/images/ico/ico_faq_show.png";
import faqHide from "../../assets/images/ico/ico_faq_hide.png";

function Faq({ faq, faqRef, setFaq }) {
  const [faqList, setFaqList] = useState();
  //coreValue Get
  const { data: faqData } = useQuery([QueryKeys.MAIN, "POL_FQ", ""], () =>
    getMainBoard("POL", "POL_FQ", "")
  );

  const handleClickFaqList = (id) => {
    if (faqList === id) {
      setFaqList(null);
    } else {
      setFaqList(id);
    }
  };

  useEffect(() => {
    if (faqData) {
      setFaq(1);
    }
  }, [faqData]);

  if (!faqData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["faq"]} ref={faqRef}>
      <h2
        className={[
          styles["title"],
          `${faq === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {parse(faqData.boardList[0].dtl)}
      </h2>
      {/* 질문 */}
      <ul className={styles["faq-box"]}>
        {faqData?.boardList.slice(1).map((item, index) => (
          <li
            key={item.brd_id}
            onClick={() => handleClickFaqList(item.brd_id)}
            className={styles["faq-list"]}
          >
            <div
              className={[
                styles["question-item"],
                "flex items-center",
                `${faqList === item.brd_id ? styles["active"] : ""}`,
              ].join(" ")}
            >
              <span className={styles["num"]}>Q{index + 1}.</span>
              <span className={styles["text"]}>{item.ttl}</span>
              <img
                src={faqList === item.brd_id ? faqShow : faqHide}
                className={styles["faq-img"]}
                alt=""
              />
            </div>
            {/* 답변 */}
            <ul
              className={[
                styles["answer-list"],
                `${faqList === item.brd_id ? styles["active"] : ""}`,
              ].join(" ")}
            >
              <li className={styles["answer-item"]}>
                <span className={styles["num"]}>A{index + 1}.</span>
                <div className={styles["content-box"]}>
                  <span className={styles["text"]}>{parse(item.dtl)}</span>
                  {item.fileInfo[0] && (
                    <span className="block">
                      <img
                        src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                        alt=""
                      />
                    </span>
                  )}
                </div>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default React.memo(Faq);
