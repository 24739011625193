import React, { useEffect, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { deleteUser, getDetailUser } from "../../api/api";
import { QueryKeys } from "../../../util/queryClient";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ConfirmModal from "../../components/modal/ConfirmModal";

function UserView() {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleClickMoveToListPage = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
  };

  const [modalState, setModalState] = useState(false);
  const handleClickMoveToEditPage = (id) => {
    const location = window.location.pathname.split("/");
    location.pop();
    navigate(`${location.join("/")}/edit/${id}`);
  };
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, id],
    () => getDetailUser({ user_id: id }),
    {
      select: (data) => {
        return data;
      },
    },
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
  const { mutate: deletePost } = useMutation((id) => deleteUser(id), {
    onSuccess: () => {
      const location = window.location.pathname.split("/");
      const path = location.slice(0, 4);
      navigate(`${path.join("/")}`);
    },
  });

  const closeModal = () => {
    setModalState(false);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <div className="flex justify-between">
            <PageTitle title={"View"} />
            <Button
              text="목록으로 이동"
              buttonFunction={handleClickMoveToListPage}
            />
          </div>
          <table>
            <tbody>
              <tr>
                <th>사용자 아이디</th>
                <td>{data.userInfo[0].user_id}</td>
              </tr>
              <tr>
                <th>사용 여부</th>
                <td>{data.userInfo[0].use_yn === "Y" ? "사용" : "미사용"}</td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{data.userInfo[0].name}</td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>{data.userInfo[0].ml}</td>
              </tr>
              <tr>
                <th>휴대폰</th>
                <td>
                  {data.userInfo[0].cel_phn
                    ? data.userInfo[0].cel_phn.replace(
                        /^(\d{2,3})(\d{3,4})(\d{4})$/,
                        `$1-$2-$3`
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <th>회사 코드</th>
                <td>{data.userInfo[0].cmpn_code}</td>
              </tr>
              <tr>
                <th>부서 코드</th>
                <td>{data.userInfo[0].dprt_code}</td>
              </tr>
            </tbody>
          </table>
          <div
            className={[styles["btn-group"], "flex justify-end gap-3"].join(
              " "
            )}
          >
            <Button
              text="수정하기"
              buttonFunction={() => handleClickMoveToEditPage(id)}
            />
            <Button
              text="삭제하기"
              buttonFunction={() => setModalState(true)}
            />
          </div>
        </>
      ) : null}
      {modalState ? (
        <ConfirmModal
          modalText="삭제하시겠습니까?"
          confirmText="확인"
          closeText="취소"
          confirmFunction={() => {
            deletePost({ user_id: data.userInfo[0].user_id });
          }}
          handleClickCloseModal={closeModal}
        />
      ) : null}
    </div>
  );
}
export default UserView;
