import React, { useEffect } from "react";
import styles from "../scss/pola.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
function PolaIntro({ isAdmin }) {
  //메인 Get
  const { data: mainData } = useQuery(
    [QueryKeys.MAIN, "POL_IT", "KOR"],
    () => getMainBoard("POL", "POL_IT", ""),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  //도입,방문하기 클릭시 호출되는 함수
  const UrlButtonHandler = (url) => {
    window.open(url);
  };
  if (!mainData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <section
        className={styles["intro"]}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMGURL}/${mainData.fileInfo[0].file_dntf_code}/${mainData.fileInfo[0].pld_file_name})`,
        }}
      >
        <div className={styles["inner"]}>
          <div
            className={[
              styles["intro-text"],
              styles["intro-en-text"],
              "gap-2 block md:flex",
            ].join(" ")}
          >
            <div className="overflow-hidden block">
              <span>{parse(mainData.ttl)}</span>
            </div>
          </div>
          <div
            className={[
              styles["intro-text"],
              styles["intro-ko-text"],
              "flex gap-2",
            ].join(" ")}
          >
            <div className="overflow-hidden">
              <span>{parse(mainData.dtl)}</span>
            </div>
          </div>
          <div className={[styles["btn-group"], "flex"].join(" ")}>
            {mainData.tmp03 === "true" ? (
              <button
                onClick={() => {
                  UrlButtonHandler(mainData.tmp01);
                }}
              >
                {mainData.tmp05}
              </button>
            ) : null}
            {mainData.tmp04 === "true" ? (
              <button
                onClick={() => {
                  UrlButtonHandler(mainData.tmp02);
                }}
              >
                {mainData.tmp06}
              </button>
            ) : null}
          </div>
        </div>
      </section>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open(
              "/admin/pola/service-intro",
              "blank",
              "height=720,width=1280"
            );
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
    </>
  );
}

export default PolaIntro;
