import React from "react";
import MenuBarActive from "./MenuBarActive";
import styles from "../scss/sideBar/menuBar.module.scss";
//Router
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { activeId, activeItemId } from "../../util/recoil";

function MenuBar() {
  //Recoil
  const [activedId, setActivedId] = useRecoilState(activeId);
  const [activedItemId, setActivedItemId] = useRecoilState(activeItemId);
  const menuBarActive = MenuBarActive();
  return (
    // 사이드 메뉴 wrapper
    <div className={styles["menubar-wrapper"]}>
      <ul className={styles["menubar-list"]}>
        {/* Main */}
        {activedId === 2 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 2 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(2)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 2 ? styles.active : ""
              }`}
            >
              <Link to="/admin/main/logo">Main</Link>
            </div>
            {activedId === 2 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/main/logo"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    Logo
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/main/main"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    Main
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/main/solutions"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    OurSolutions
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(4)}
                >
                  <Link
                    to="/admin/main/partners"
                    className={`${activedItemId === 4 ? styles.active : ""}`}
                  >
                    Our Partners
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(5)}
                >
                  <Link
                    to="/admin/main/awards"
                    className={`${activedItemId === 5 ? styles.active : ""}`}
                  >
                    Awards
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(6)}
                >
                  <Link
                    to="/admin/main/history"
                    className={`${activedItemId === 6 ? styles.active : ""}`}
                  >
                    주요연혁
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(7)}
                >
                  <Link
                    to="/admin/main/core-values"
                    className={`${activedItemId === 7 ? styles.active : ""}`}
                  >
                    Core Values
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(8)}
                >
                  <Link
                    to="/admin/main/media-kit"
                    className={`${activedItemId === 8 ? styles.active : ""}`}
                  >
                    Media Kit
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}

        {/* Pola */}
        {activedId === 3 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 3 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(3)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 3 ? styles.active : ""
              }`}
            >
              <Link to="/admin/pola/service-intro">Pola</Link>
            </div>
            {activedId === 3 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/pola/service-intro"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    서비스 소개
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/pola/service-info"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    서비스 설명
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/pola/utilize"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    활용
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(4)}
                >
                  <Link
                    to="/admin/pola/features"
                    className={`${activedItemId === 4 ? styles.active : ""}`}
                  >
                    특장점
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(5)}
                >
                  <Link
                    to="/admin/pola/process"
                    className={`${activedItemId === 5 ? styles.active : ""}`}
                  >
                    생산과정
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(6)}
                >
                  <Link
                    to="/admin/pola/faq"
                    className={`${activedItemId === 6 ? styles.active : ""}`}
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
        {/* NextLook */}
        {activedId === 10 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 10 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(3)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 10 ? styles.active : ""
              }`}
            >
              <Link to="/admin/nextlook/main">NextLook</Link>
            </div>
            {activedId === 10 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/nextlook/main"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    Main
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/nextlook/service"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    서비스 설명
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/nextlook/advantage"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    특장점
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
        {/* Tivv */}
        {activedId === 4 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 4 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(4)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 4 ? styles.active : ""
              }`}
            >
              <Link to="/admin/tivv/service-intro">Tivv</Link>
            </div>
            {activedId === 4 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/tivv/service-intro"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    Main
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/tivv/service"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    서비스 소개
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/tivv/definition"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    서비스 설명
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(4)}
                >
                  <Link
                    to="/admin/tivv/application"
                    className={`${activedItemId === 4 ? styles.active : ""}`}
                  >
                    활용
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
        {/* People */}
        {activedId === 5 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 5 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(5)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 5 ? styles.active : ""
              }`}
            >
              <Link to="/admin/people/intro">People</Link>
            </div>
            {activedId === 5 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/people/intro"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    Intro
                  </Link>
                </li>
                {/* <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/people/our-leaders"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    Our Leaders
                  </Link>
                </li> */}
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/people/people"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    People
                  </Link>
                </li>
                {/* <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(4)}
                >
                  <Link
                    to="/admin/people/photo"
                    className={`${activedItemId === 4 ? styles.active : ""}`}
                  >
                    Photo
                  </Link>
                </li> */}
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(5)}
                >
                  <Link
                    to="/admin/people/culture"
                    className={`${activedItemId === 5 ? styles.active : ""}`}
                  >
                    Culture
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
        {/* Recruit */}
        {activedId === 6 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 6 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(6)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 6 ? styles.active : ""
              }`}
            >
              <Link to="/admin/recruit/main">Recruit</Link>
            </div>
            {activedId === 6 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/recruit/main"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    Main
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/recruit/interview"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    인터뷰
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/recruit/recruit"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    채용정보
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(4)}
                >
                  <Link
                    to="/admin/recruit/faq"
                    className={`${activedItemId === 4 ? styles.active : ""}`}
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
        {/* Media */}
        {activedId === 7 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 7 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(7)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 7 ? styles.active : ""
              }`}
            >
              <Link to="/admin/media/media">Media</Link>
            </div>
            {activedId === 7 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/media/media"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    기사
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/media/investment"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    투자
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/media/social-media"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    소셜미디어
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
        {/* Researches */}
        {activedId === 8 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 8 ? styles.active : ""
            }`}
            onClick={() => setActivedId(8)}
          >
            <div
              className={`${styles["menu-item"]} ${styles["last-item"]} ${
                activedId === 8 ? styles.active : ""
              }`}
            >
              <Link to="/admin/researches/contents">연구내용</Link>
            </div>
          </li>
        ) : null}
        {/* 운영관리 */}
        {activedId === 9 ? (
          <li
            className={`${styles["menu-box"]} ${
              activedId === 9 ? styles.active : ""
            }`}
            onClick={() => menuBarActive.handleActiveItemId(9)}
          >
            <div
              className={`${styles["menu-item"]} ${
                activedId === 9 ? styles.active : ""
              }`}
            >
              <Link to="/admin/management/member">운영관리</Link>
            </div>
            {activedId === 9 && (
              <ul className={styles["accordion-box"]}>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(1)}
                >
                  <Link
                    to="/admin/management/member"
                    className={`${activedItemId === 1 ? styles.active : ""}`}
                  >
                    회원관리
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(2)}
                >
                  <Link
                    to="/admin/management/popup"
                    className={`${activedItemId === 2 ? styles.active : ""}`}
                  >
                    팝업관리
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(3)}
                >
                  <Link
                    to="/admin/management/code"
                    className={`${activedItemId === 3 ? styles.active : ""}`}
                  >
                    코드관리
                  </Link>
                </li>
                <li
                  className={styles["accordion-item"]}
                  onClick={() => menuBarActive.handleActiveItemId(4)}
                >
                  <Link
                    to="/admin/management/pref/footerinfo"
                    className={`${activeItemId === 4 ? styles.active : ""}`}
                  >
                    환경설정
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default MenuBar;
