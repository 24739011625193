import React, { useEffect } from "react";
import styles from "../scss/main.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../scss/mainSwiper.scss";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import { getMainBoard } from "../../api/api";
function Awards({
  awards,
  awardsRef,
  awardsSwiperRef,
  awardsSlideRef,
  setAwards,
  languageSelect,
}) {
  //Awards Get
  const { data: awardsData } = useQuery(
    [QueryKeys.MAIN, "MAI_AW", languageSelect],
    () => getMainBoard("MAI", "MAI_AW", languageSelect)
  );

  useEffect(() => {
    if (awardsData) {
      setAwards(1);
    }
  }, [awardsData]);
  if (!awardsData) return <div></div>;
  return (
    <section className={styles["awards"]} ref={awardsRef}>
      <h2
        className={[
          styles["title"],
          `${awards === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        Awards
      </h2>
      <div className="awards-list">
        <Swiper
          modules={[Mousewheel, Pagination, Navigation]}
          spaceBetween={16}
          slidesPerView={2}
          navigation
          pagination={{
            type: "progressbar",
          }}
          onReachEnd={() => {
            if (awardsSwiperRef.current) {
              awardsSwiperRef.current.swiper.mousewheel.disable();
            }
            setTimeout(function () {
              if (awardsSlideRef.current.swiper) {
                awardsSwiperRef.current.swiper.params.touchReleaseOnEdges = true;
                awardsSwiperRef.current.swiper.params.mousewheel.releaseOnEdges = true;
              }
            }, 500);
          }}
          onReachBeginning={() => {
            // if (awardsSwiperRef.current) {
            //   awardsSwiperRef.current.swiper.mousewheel.disable();
            // }
            setTimeout(function () {
              awardsSwiperRef.current.swiper.params.touchReleaseOnEdges = true;
              awardsSwiperRef.current.swiper.params.mousewheel.releaseOnEdges = true;
            }, 500);
          }}
          onSlideChange={() =>
            setTimeout(function () {
              awardsSwiperRef.current.swiper.params.touchReleaseOnEdges = false;
              awardsSwiperRef.current.swiper.params.mousewheel.releaseOnEdges = false;
            })
          }
          breakpoints={{
            360: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 16,
            },
            1280: {
              slidesPerView: 2,
              spaceBetween: 16,
            },
          }}
          ref={awardsSwiperRef}
        >
          {awardsData?.boardList.reverse().map((item) => (
            <SwiperSlide
              key={item.brd_id}
              className={styles["swiper-slide"]}
              ref={awardsSlideRef}
            >
              <div className={styles["trophy-image"]}></div>
              <div className={styles["list-box"]}>
                <strong className={styles["list-title"]}>{item.ttl}</strong>
                <div className={styles["list-detail"]}>{parse(item.dtl)}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default React.memo(Awards);
