import React from "react";
import styles from "../scss/goTop.module.scss";
import goTop from "../../assets/images/ico/icon_arrow_rigth.png";

const GoTopButton = ({ handleClickGoTopButton }) => {
  return (
    <div>
      <button className={styles["btn-go-top"]} onClick={handleClickGoTopButton}>
        <img src={goTop} alt="" />
      </button>
    </div>
  );
};

export default GoTopButton;
