import React, { useEffect, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTest, getTest2 } from "../../api/api";
import parse from "html-react-parser";
import { QueryKeys } from "../../../util/queryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import ConfirmModal from "../../components/modal/ConfirmModal";

const PeopleView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleClickMoveToListPage = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
  };

  const [modalState, setModalState] = useState(false);
  const handleClickMoveToEditPage = (id) => {
    const location = window.location.pathname.split("/");
    location.pop();
    navigate(`${location.join("/")}/edit/${id}`);
  };
  const { data, isLoading } = useQuery(
    [QueryKeys.DETAIL, id],
    () => getTest2(id),
    {
      staleTime: 0,
      cacheTime: 0,
    },
    {
      select: (data) => {
        return data;
      },
    }
  );

  //파일 다운로드 함수
  const downloadStart = async function (data) {
    const filePath = `${process.env.REACT_APP_IMGURL}/${data.file_dntf_code}/${data.pld_file_name}`;

    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = data.rgnl_file_name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const { mutate: deletePost } = useMutation((id) => deleteTest(id), {
    onSuccess: () => {
      const location = window.location.pathname.split("/");
      const path = location.slice(0, 4);
      navigate(`${path.join("/")}`);
    },
  });

  const deleteStart = () => {
    const file_del_array = [];
    for (const file of data.fileInfo) {
      file_del_array.push(file.tchm_id);
    }
    const deleteData = {
      brd_id: data.brdInfo[0].brd_id,
      file_del_array,
      dtl_code: data.brdInfo[0].dtl_code,
      tmp01: data.brdInfo[0].tmp01,
    };
    deletePost(deleteData);
  };
  const closeModal = () => {
    setModalState(false);
  };

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);

  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <div className="flex justify-between">
            <PageTitle title={"View"} />
            <Button
              className="grow-0"
              text="목록으로 이동"
              buttonFunction={handleClickMoveToListPage}
            />
          </div>
          <table>
            <tbody>
              <tr>
                <th>제목</th>
                <td>{data.brdInfo[0].ttl}</td>
              </tr>
              <tr>
                <th>사용 여부</th>
                <td>{data.brdInfo[0].use_yn === "Y" ? "사용" : "미사용"}</td>
                {data.brdInfo[0].ord_no !== 1 ? (
                  <>
                    <th>정렬 순서</th>
                    <td>{data.brdInfo[0].ord_no}</td>
                  </>
                ) : null}
              </tr>
              <tr>
                <th>등록자</th>
                <td>{data.brdInfo[0].rgst_id}</td>
                <th>등록일시</th>
                <td>{data.brdInfo[0].rgst_date}</td>
              </tr>
              <tr>
                <th>수정자</th>
                <td>{data.brdInfo[0].mdfc_id}</td>
                <th>수정일시</th>
                <td>{data.brdInfo[0].mdfc_date}</td>
              </tr>
              {data.fileInfo.length ? (
                <tr className={styles["file-list"]}>
                  <th>첨부파일</th>
                  <td>
                    <div className={styles["files"]}>
                      {data.fileInfo.map((file, index) => (
                        <div
                          className={styles["file-box"]}
                          key={`${file.rgnl_file_name}_${index}`}
                          onClick={() => {
                            downloadStart(file);
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMGURL}/${file.file_dntf_code}/${file.pld_file_name}`}
                            alt=""
                          />
                          <div className={styles["file"]}>
                            {file.rgnl_file_name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ) : null}
              <tr>
                <th>내용</th>
                <td>
                  <div className={styles["contents"]}>
                    {parse(data.brdInfo[0].dtl)}
                  </div>
                </td>
              </tr>
              {data.brdInfo[0].ord_no !== 1 ? (
                <tr>
                  <th>추가 내용</th>
                  <td>{parse(data.brdInfo[0].tmp05)}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <div
            className={[styles["btn-group"], "flex justify-end gap-3"].join(
              " "
            )}
          >
            <Button
              text="수정하기"
              buttonFunction={() => handleClickMoveToEditPage(id)}
            />
            {data.brdInfo[0].ord_no !== 1 ? (
              <Button
                text="삭제하기"
                buttonFunction={() => setModalState(true)}
              />
            ) : null}
          </div>
        </>
      ) : null}
      {modalState ? (
        <ConfirmModal
          modalText="삭제하시겠습니까?"
          confirmText="확인"
          closeText="취소"
          confirmFunction={deleteStart}
          handleClickCloseModal={closeModal}
        />
      ) : null}
    </div>
  );
};

export default PeopleView;
