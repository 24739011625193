import axios from "axios";
//common GET
const BASE_URL = `${process.env.REACT_APP_URL}`;
export const getTest = async (grp_code, dtl_code, limitLine, offset) => {
  const res = await axios.get(
    `${BASE_URL}/boardBasic/allBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}&limitLine=${limitLine}&offset=${offset}`
    , { withCredentials: true }
  );
  return res.data;
};
export const getTest3 = async (
  grp_code,
  dtl_code,
  searchTmp01,
  limitLine,
  offset
) => {
  const res = await axios.get(
    `${BASE_URL}/boardBasic/allBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}&limitLine=${limitLine}&offset=${offset}&searchTmp01=${searchTmp01}`
    , { withCredentials: true }
  );
  return res.data;
};
export const getTemp = async (grp_code, dtl_code) => {
  const res = await axios.get(
    `${BASE_URL}/boardBasic/getTmp01List?grp_code=${grp_code}&dtl_code=${dtl_code}`, { withCredentials: true }
  );
  return res.data;
};
export const getTest2 = async (id) => {
  const res = await axios.get(`${BASE_URL}/boardBasic/boardWrite?brd_id=${id}`, { withCredentials: true });
  return res.data;
};
//admin POST
export const postTest = async (post) => {
  const req = await axios.post(`${BASE_URL}/boardBasic/fileWrite`, post, {
    withCredentials:true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return post;
      },
    ],
  });
  return req.data;
};
//admin PATCH (UPDATE)
export const patchTest = async (editData) => {
  const res = await axios.post(`${BASE_URL}/boardBasic/fileWrite`, editData, {
    withCredentials:true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return editData;
      },
    ],
  });

  return res.data;
};
//admin DELETE
export const deleteTest = async (params) => {
  const res = await axios
    .post(`${BASE_URL}/boardBasic/fileDelete`, params, { withCredentials: true })
    .then((res) => {});
  // return res.data;
};
//signIn
export const postSignIn = async (data) => {
  const res = await axios
    .post(`${BASE_URL}/users/login`, data, { withCredentials: true })
  return res.data;
};
//logOut
export const getLogOut = async () => {
  const res = await axios.get(`${BASE_URL}/users/`, { withCredentials: true });
  return res.data;
};
export const postSignUp = async (data) => {
  const res = await axios.post(`${BASE_URL}/users/signUp`, data, { withCredentials: true });
  return res.data;
};
//management GET
export const getMangement = async () => {
  const res = await axios.get(`${BASE_URL}/pref/footerInfo`, { withCredentials: true });
  return res.data;
};

export const getMangement2 = async () => {
  const res = await axios.get(`${BASE_URL}/pref/footerInfo`, { withCredentials: true });
  return res.data;
};

export const postMangement = async (editData) => {
  const res = await axios.post(`${BASE_URL}/pref/footerInfoRegist`, editData, { withCredentials: true });
  return res.editData;
};

//Mangement PATCH (UPDATE)
// export const postMangement = async (editData) => {
//   const res = await axios.post(`${BASE_URL}/pref/footerInfoRegist`, editData);

//   return res;
// };

//management DELETE
export const deleteMangement = async (data) => {
  const res = await axios.post(
    // `${BASE_URL}/pref/footerInfoRemove?frt_id=${data.frt_id}&mdfc_id=${data.mdfc_id}`
    `${BASE_URL}/pref/footerInfoRemove`,
    data, { withCredentials: true }
  );

  return res;
};
//social(Link) GET
export const getSocial = async (limitLine, offset) => {
  const res = await axios.get(
    `${BASE_URL}/link/getLinkList?limitLine=${limitLine}&offset=${offset}`, { withCredentials: true }
  );
  return res.data;
};

export const getSocial2 = async (id) => {
  const res = await axios.get(`${BASE_URL}/link/getLinkInfo?link_id=${id}`, { withCredentials: true });
  return res.data;
};

export const postSocial = async (data) => {
  const res = await axios.post(`${BASE_URL}/link/updateLinkInfo`, data, { withCredentials: true });
  return res;
};

//social DELETE
export const deleteSocial = async (data) => {
  const res = await axios.post(`${BASE_URL}/link/deleteLinkInfo`, data, { withCredentials: true });

  return res;
};
//Nncm
export const getNncm = async (limitLine, offset) => {
  const res = await axios.get(
    `${BASE_URL}/nncm/getNncmList?limitLine=${limitLine}&offset=${offset}`, { withCredentials: true }
  );
  return res.data;
};
export const postNncm = async (post) => {
  const req = await axios.post(`${BASE_URL}/nncm/updateNncmInfo`, post, { withCredentials: true });
  return req.data;
};
export const getDetailNncm = async (id) => {
  const res = await axios.get(`${BASE_URL}/nncm/getNncmInfo?nncm_id=${id}`, { withCredentials: true });
  return res.data;
};
//PopUp
export const getPopUp = async (limitLine, offset) => {
  const res = await axios.get(
    `${BASE_URL}/pop/getPopList?limitLine=${limitLine}&offset=${offset}`, { withCredentials: true }
  );
  return res.data;
};
export const postPopUp = async (post) => {
  const req = await axios.post(`${BASE_URL}/pop/updatePopInfo`, post, { withCredentials: true });
  return req.data;
};
export const getDetailPop = async (id) => {
  const res = await axios.get(`${BASE_URL}/pop/getPopInfo?pop_id=${id}`, { withCredentials: true });
  return res.data;
};
//Faq
export const getFaq = async (grp_code, dtl_code, limitLine, offset) => {
  const res = await axios.get(
    `${BASE_URL}/faq/getFaqList?grp_code=${grp_code}&dtl_code=${dtl_code}&limitLine=${limitLine}&offset=${offset}`, { withCredentials: true }
  );
  return res.data;
};
export const postFaq = async (post) => {
  const req = await axios.post(`${BASE_URL}/faq/updateFaqInfo`, post, { withCredentials: true });
  return req.data;
};
export const getDetailFaq = async (id) => {
  const res = await axios.get(`${BASE_URL}/faq/getFaqInfo?faq_id=${id}`, { withCredentials: true });
  return res.data;
};
//userManagement
export const getUsers = async (limitLine, offset) => {
  const res = await axios.get(
    `${BASE_URL}/userManage/getAllUserInfo?limitLine=${limitLine}&offset=${offset}`, { withCredentials: true }
  );
  return res.data;
};
export const getDetailUser = async (id) => {
  const res = await axios.post(`${BASE_URL}/userManage/userInfo`, id, { withCredentials: true });
  return res.data;
};
export const postUser = async (user) => {
  const req = await axios.post(`${BASE_URL}/userManage/updateUserInfo`, user, { withCredentials: true });
  return req.data;
};
export const deleteUser = async (id) => {
  const req = await axios.post(`${BASE_URL}/userManage/deleteUser`, id, { withCredentials: true });
  return req.data;
};

export const changeYn = async (id) => {
  const req = await axios.post(`${BASE_URL}/boardBasic/updateUseYn`, id, { withCredentials: true });
  return req.data;
};

//mediaKit
export const getMediaKit = async (id) => {
  const res = await axios.get(`${BASE_URL}/boardBasic/getMediaKitInfo?brd_id=${id}`, { withCredentials: true });
  return res.data;
};
export const createMediaKit = async (user) => {
  const req = await axios.post(`${BASE_URL}/boardBasic/uploadMediaKit`, user, {
    withCredentials : true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return user;
      },
    ],
  });
  return req.data;
};

//grpCodeManage
export const getGrpCodes = async () => {
  const res = await axios.get(`${BASE_URL}/code/getGrpList`, { withCredentials: true });
  return res.data;
};
export const postGrpCodes = async (postData) => {
  const res = await axios.post(`${BASE_URL}/code/updateGrpCodeInfo`, postData, { withCredentials: true });
  return res.data;
};

export const getDtlCodes = async (grp_code) => {
  const res = await axios.get(`${BASE_URL}/code/getDtlList?grp_code_id=${grp_code}`, { withCredentials: true });
  return res.data;
};
export const postDtlCodes = async (postData) => {
  const res = await axios.post(`${BASE_URL}/code/updateDtlCodeInfo`, postData, { withCredentials: true });
  return res.data;
};
