import styles from "./confirmModal.module.scss";
//Image

function ConfirmModal({
  modalText,
  closeText,
  confirmText,
  handleClickCloseModal,
  confirmFunction,
  colorRed,
}) {
  const hasButtonText = closeText === undefined || confirmText === undefined;

  // 닫기
  function handleCloseModal() {}
  // 수정
  function handleEditBtn() {}
  // 삭제
  function handleDelete() {}
  // 확인(저장)
  function handleSave() {}

  return (
    <>
      <div className={`${colorRed ? styles["red-box"] : styles["green-box"]}`}>
        {/* image */}
        <div className={styles["img-wrap"]}>
          <div
            className={`${colorRed ? styles["warning"] : styles["save"]}`}
          ></div>
        </div>
        {/* modal text  */}
        <div className={styles["modal-text"]}>{modalText}</div>
        {/* 모달 button */}
        <div
          className={hasButtonText ? styles["btn-box"] : styles["btn-group"]}
        >
          {/* 확인, 컨펌 버튼 */}
          {confirmText !== undefined && (
            <button
              className={`${colorRed ? styles["warning"] : styles["confirm"]}`}
              onClick={confirmFunction}
            >
              {confirmText}
            </button>
          )}
          {/* 닫기 버튼 */}
          {closeText !== undefined && (
            <button className={styles["close"]} onClick={handleClickCloseModal}>
              {closeText}
            </button>
          )}
        </div>
      </div>

      {/* 모달창 생성시 화면 클릭을 방지 */}
      <div className={styles["dim"]}></div>
    </>
  );
}

export default ConfirmModal;
