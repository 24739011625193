import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { activeId, activeItemId } from "../../util/recoil";

const MenuBarActive = () => {
  //Recoil
  const [activedId, setActivedId] = useRecoilState(activeId);
  const setActivedItemId = useSetRecoilState(activeItemId);

  const location = useLocation();

  // 메뉴 활성화를 설정하는 함수
  const handleSetActive = (id) => {
    setActivedId(id);
  };
  const handlSetActiveItemId = (id) => {
    setActivedItemId(id);
  };
  // 초기화 버튼
  const handleResetMenuItem = (id) => {
    if (activedId !== id) {
      handleSetActive(id);
    }
  };
  const handleActiveItemId = (id) => {
    handlSetActiveItemId(id);
  };
  // 페이지가 변경될 때마다 activeId 업데이트
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes("/admin/home")) {
      handleSetActive(1);
    } else if (pathname.includes("/admin/main")) {
      handleSetActive(2);
    } else if (pathname.includes("/admin/pola")) {
      handleSetActive(3);
    } else if (pathname.includes("/admin/tivv")) {
      handleSetActive(4);
    } else if (pathname.includes("/admin/people")) {
      handleSetActive(5);
    } else if (pathname.includes("/admin/recruit")) {
      handleSetActive(6);
    } else if (pathname.includes("/admin/media")) {
      handleSetActive(7);
    } else if (pathname.includes("/admin/workSpaceManages")) {
      handleSetActive(8);
    } else if (pathname.includes("/admin/management")) {
      handleSetActive(9);
    } else if (pathname.includes("/admin/nextlook")) {
      handleSetActive(10);
    }

    if (pathname.includes("/home/myPage")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/home/notice")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/main/logo")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/main/main")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/main/solutions")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("/main/partners")) {
      handlSetActiveItemId(4);
    } else if (pathname.includes("/main/awards")) {
      handlSetActiveItemId(5);
    } else if (pathname.includes("/main/history")) {
      handlSetActiveItemId(6);
    } else if (pathname.includes("/main/core-values")) {
      handlSetActiveItemId(7);
    } else if (pathname.includes("/main/media-kit")) {
      handlSetActiveItemId(8);
    } else if (pathname.includes("/pola/service-intro")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/pola/service-info")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/pola/utilize")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("/pola/features")) {
      handlSetActiveItemId(4);
    } else if (pathname.includes("/pola/process")) {
      handlSetActiveItemId(5);
    } else if (pathname.includes("/pola/faq")) {
      handlSetActiveItemId(6);
    } else if (pathname.includes("/nextlook/main")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/nextlook/service")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/nextlook/advantage")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("/tivv/service-intro")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/tivv/service")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/tivv/definition")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("/tivv/application")) {
      handlSetActiveItemId(4);
    } else if (pathname.includes("/people/intro")) {
      handlSetActiveItemId(1);
    }
    // else if (pathname.includes("/people/our-leaders")) {
    //   handlSetActiveItemId(2);
    // }
    else if (pathname.includes("/people/people")) {
      handlSetActiveItemId(3);
    }
    //  else if (pathname.includes("/people/photo")) {
    //   handlSetActiveItemId(4);
    // }
    else if (pathname.includes("/people/culture")) {
      handlSetActiveItemId(5);
    } else if (pathname.includes("/recruit/main")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/recruit/interview")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/recruit/recruit")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("/recruit/faq")) {
      handlSetActiveItemId(4);
    } else if (pathname.includes("/media/media")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/media/investment")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/media/social-media")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("researches/contents")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/management/member")) {
      handlSetActiveItemId(1);
    } else if (pathname.includes("/management/popup")) {
      handlSetActiveItemId(2);
    } else if (pathname.includes("/management/code")) {
      handlSetActiveItemId(3);
    } else if (pathname.includes("/management/pref/footerinfo")) {
      handlSetActiveItemId(4);
    }
  }, [location]);
  return {
    handleSetActive,
    handleResetMenuItem,
    handleActiveItemId,
  };
};

export default MenuBarActive;
