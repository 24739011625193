import React, { useEffect } from "react";
import styles from "../scss/people.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function Culture({ culture, cultureRef, setCulture }) {
  //coreValue Get
  const { data: cultureData } = useQuery([QueryKeys.MAIN, "PEO_CL", ""], () =>
    getMainBoard("PEO", "PEO_CL", "")
  );
  useEffect(() => {
    if (cultureData) {
      setCulture(1);
    }
  }, [cultureData]);

  const UrlButtonHandler = (url) => {
    window.open(url);
  };
  if (!cultureData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["culture"]} ref={cultureRef}>
      <h2
        className={[
          styles["title"],
          // scrollY > cultureTop - 500 ? styles["active"] : "",
          `${culture === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {parse(cultureData.boardList[0].ttl)}
      </h2>
      <span className={styles["sub-title"]}>
        {parse(cultureData.boardList[0].dtl)}
      </span>

      <ul className={styles["culture-list"]}>
        <li className={styles["culture-item"]}>
          <div>
            <div className={styles["item-title"]}>
              {parse(cultureData.boardList[1].ttl)}
            </div>
            <div className={styles["item-desc"]}>
              {parse(cultureData.boardList[1].dtl)}
            </div>
            <button
              onClick={() => {
                UrlButtonHandler(cultureData.boardList[1].tmp01);
              }}
            >
              View More
            </button>
          </div>
        </li>
        <li className={styles["culture-item"]}>
          <div>
            <div className={styles["item-title"]}>
              {parse(cultureData.boardList[2].ttl)}
            </div>
            <div className={styles["item-desc"]}>
              {parse(cultureData.boardList[2].dtl)}
            </div>
            <button
              onClick={() => {
                UrlButtonHandler(cultureData.boardList[2].tmp01);
              }}
            >
              View More
            </button>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default React.memo(Culture);
