import { atom } from "recoil";

export const activeId = atom({
  key: "activeId",
  default: 1,
});

export const activeItemId = atom({
  key: "activeItemId",
  default: 1,
});

export const partnersItem = atom({
  key: "partnersItem",
  default: [],
});

export const isLoginState = atom({
  key: "isLoginState",
  default: false,
});
export const languageSelector = atom({
  key: "languageSelector",
  default: "KOR",
});
