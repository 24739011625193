import React, { useEffect, useReducer, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getMangement2, postMangement } from "../../api/api";
import ConfirmModal from "../../components/modal/ConfirmModal";

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    case "UPDATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

function FooterEdit() {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { data, isLoading } = useQuery(
    [QueryKeys.DETAIL, "OPE_PR", lang],
    () => getMangement2(lang),
    {
      staleTime: 0,
      cacheTime: 0,
    },
    {
      select: (data) => {
        return data;
      },
    }
  );
  const [reducerState, dispatch] = useReducer(reducer, {
    frt_id: "",
    cmpn_name: "",
    cmpn_rgst_nmbr: "",
    rprs_name: "",
    ml: "",
    cpyr: "",
    drs1: "",
    drs2: "",
    phn_nmbr1: "",
    phn_nmbr2: "",
    mdfc_id: "",
  });

  // CHANGE
  const handleInputChange = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };
  // State
  const [modalState, setModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [SecondModalState, setSecondModalState] = useState(false);
  const [btAble, setBtAble] = useState(false);
  const { mutate: sartEditPost } = useMutation(
    (editData) => postMangement(editData),
    {
      onSuccess: () => {
        setSecondModalState(true);
      },
    }
  );
  const successPost = () => {
    navigate(`/admin/management/pref/footerInfo`);
  };
  //   유효성 검사
  const validation = () => {
    if (!reducerState.cmpn_name.trim()) {
      setModalText("상호명을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.cmpn_rgst_nmbr.trim()) {
      setModalText("사업자 등록번호를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.rprs_name.trim()) {
      setModalText("대표자명을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.ml.trim()) {
      setModalText("이메일를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.cpyr.trim()) {
      setModalText("카피라이트를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.drs1.trim()) {
      setModalText("주소를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.drs2.trim()) {
      setModalText("주소2를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.phn_nmbr1.trim()) {
      setModalText("전화번호를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.phn_nmbr2.trim()) {
      setModalText("전화번호2를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //수정 완료시 호출되는 함수
  const submitEditPost = () => {
    setBtAble(true);
    if (validation()) {
      return;
    }

    const editData = {
      frt_id: data.footerInfoList[0].frt_id,
      lang: data.footerInfoList[0].lang,
      cmpn_name: reducerState.cmpn_name,
      cmpn_rgst_nmbr: reducerState.cmpn_rgst_nmbr,
      rprs_name: reducerState.rprs_name,
      ml: reducerState.ml,
      cpyr: reducerState.cpyr,
      drs1: reducerState.drs1,
      drs2: reducerState.drs2,
      phn_nmbr1: reducerState.phn_nmbr1,
      phn_nmbr2: reducerState.phn_nmbr2,
    };
    sartEditPost(editData);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      } else {
        handleInputChange("cmpn_name", data.footerInfoList[0].cmpn_name);
        handleInputChange(
          "cmpn_rgst_nmbr",
          data.footerInfoList[0].cmpn_rgst_nmbr
        );
        handleInputChange("cpyr", data.footerInfoList[0].cpyr);
        handleInputChange("drs1", data.footerInfoList[0].drs1);
        handleInputChange("drs2", data.footerInfoList[0].drs2);
        handleInputChange("ml", data.footerInfoList[0].ml);
        handleInputChange("phn_nmbr1", data.footerInfoList[0].phn_nmbr1);
        handleInputChange("phn_nmbr2", data.footerInfoList[0].phn_nmbr2);
        handleInputChange("rprs_name", data.footerInfoList[0].rprs_name);
      }
    }
  }, [data, isLoading]);
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      {!isLoading && data.code === "success" ? (
        <>
          <PageTitle title={"수정하기"} />
          <table>
            <tbody>
              <tr>
                <th>상호명</th>
                <td>
                  <input
                    value={reducerState.cmpn_name}
                    name="tilte"
                    type="text"
                    placeholder="상호명을 입력해 주세요"
                    onChange={(e) =>
                      handleInputChange("cmpn_name", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>사업자 등록번호</th>
                <td>
                  <input
                    value={reducerState.cmpn_rgst_nmbr}
                    type="text"
                    placeholder="사업자 등록번호를 입력해 주세요"
                    onChange={(e) =>
                      handleInputChange("cmpn_rgst_nmbr", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>대표자명</th>
                <td>
                  <input
                    value={reducerState.rprs_name}
                    type="text"
                    placeholder="대표자명을 입력해 주세요"
                    onChange={(e) =>
                      handleInputChange("rprs_name", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>
                  <input
                    value={reducerState.ml}
                    type="text"
                    placeholder="이메일를 입력해 주세요"
                    onChange={(e) => handleInputChange("ml", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>카피라이트</th>
                <td>
                  <input
                    value={reducerState.cpyr}
                    type="text"
                    placeholder="카피라이트를 입력해 주세요"
                    onChange={(e) => handleInputChange("cpyr", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td>
                  <input
                    value={reducerState.drs1}
                    type="text"
                    placeholder="주소를 입력해 주세요"
                    onChange={(e) => handleInputChange("drs1", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>주소2</th>
                <td>
                  <input
                    value={reducerState.drs2}
                    type="text"
                    placeholder="주소2를 입력해 주세요"
                    onChange={(e) => handleInputChange("drs2", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>
                  <input
                    value={reducerState.phn_nmbr1}
                    type="text"
                    placeholder="전화번호를 입력해 주세요"
                    onChange={(e) =>
                      handleInputChange("phn_nmbr1", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>전화번호2</th>
                <td>
                  <input
                    value={reducerState.phn_nmbr2}
                    type="text"
                    placeholder="전화번호2를 입력해 주세요"
                    onChange={(e) =>
                      handleInputChange("phn_nmbr2", e.target.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            disable={btAble}
            text="수정 완료"
            buttonFunction={submitEditPost}
          />
          {modalState ? (
            <ConfirmModal
              modalText={modalText}
              confirmText="확인"
              confirmFunction={closeModal}
            />
          ) : null}
        </>
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="수정 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
}

export default FooterEdit;
