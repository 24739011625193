import React, { useEffect } from "react";
import styles from "../scss/main.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
// images
import download from "../../assets/images/ico/ico_download.png";

function MediaKit({ mediaRef, media, setMedia, languageSelect }) {
  const { data: mediaData } = useQuery(
    [QueryKeys.MAIN, "MAI_KT", languageSelect],
    () => getMainBoard("MAI", "MAI_KT", languageSelect),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  //파일 다운로드 함수
  const downloadStart = async function (file) {
    const data = mediaData.fileInfo.filter((item) => item.file_dscr === file);

    const filePath = `${process.env.REACT_APP_IMGURL}/${data[0].file_dntf_code}/${data[0].pld_file_name}`;

    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = data[0].rgnl_file_name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  useEffect(() => {
    if (mediaData) {
      setMedia(1);
    }
  }, []);
  if (!mediaData) return <div></div>;
  return (
    <section className={styles["media-kit"]} ref={mediaRef}>
      <h2
        className={[
          styles["title"],
          `${media === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        Media Kit
      </h2>
      <div className={styles["media-kit-card"]}>
        <button
          onClick={() => downloadStart("tivv")}
          className={languageSelect === "ENG" ? styles["en-btn"] : ""}
        >
          {languageSelect === "KOR" ? "CI 다운받기" : "Download CI"}
          <img src={download} className="md:w-6 md:h-6 w-4 h-4" alt="" />
        </button>
        <button
          onClick={() => downloadStart("pola")}
          className={languageSelect === "ENG" ? styles["en-btn"] : ""}
        >
          {languageSelect === "KOR" ? "회사소개서" : "Download Company Profile"}
          <img src={download} className="md:w-6 md:h-6 w-4 h-4" alt="" />
        </button>
      </div>
    </section>
  );
}

export default React.memo(MediaKit);
