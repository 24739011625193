import React, { useEffect, useState } from "react";
import styles from "../scss/recruit.module.scss";
import { getMainBoardPaging, getTmp01List } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import btnPrev from "../../assets/images/button/pagination_prev.png";
import btnNext from "../../assets/images/button/pagination_next.png";
import btnFirst from "../../assets/images/button/pagination_first.png";
import btnLast from "../../assets/images/button/pagination_last.png";

// 채용 공고 컨텐츠
let recruitHeaderList = [
  { id: 1, title: "직군" },
  { id: 2, title: "포지션" },
  { id: 3, title: "경력 구분" },
  { id: 4, title: "고용형태" },
  { id: 5, title: "채용일정" },
  { id: 6, title: "비고" },
];

function RecruitInfo({ recruitRef, recruitTop, setRecruitRefTop }) {
  const [maximunPage, setMaximunPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageGroup = Math.ceil(currentPage / (5 / 2));
  const startIndex = (currentPageGroup - 1) * (5 / 2) + 1;
  const endIndex = Math.min(startIndex + 5 / 2 - 1, maximunPage);
  const { data: Tmp01Data } = useQuery(
    [QueryKeys.DETAIL, "REC_RE"],
    () => getTmp01List("REC", "REC_RE"),
    {
      select: (data) => {
        return data;
      },
    }
  );
  const [tag, setTag] = useState("");
  const { data: recuitInfoDate } = useQuery(
    [QueryKeys.MAIN, "REC_RE", currentPage, tag],
    () => getMainBoardPaging("REC", "REC_RE", tag, 5, (currentPage - 1) * 5)
  );
  const handleClickTag = (tagName) => {
    setTag(tagName);
    setCurrentPage(1);
  };
  //첫페이지 이동
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  //다음 페이지 이동
  const handleNextPage = () => {
    if (currentPage >= maximunPage) return;
    else {
      setCurrentPage(currentPage + 1);
    }
  };
  // 이전 페이지 이동
  const handlePrevPage = () => {
    if (currentPage <= 1) return;
    else {
      setCurrentPage(currentPage - 1);
    }
  };
  //마지막 페이지로 이동
  const handleLastPage = () => {
    setCurrentPage(maximunPage);
  };
  useEffect(() => {
    if (recuitInfoDate) {
      setMaximunPage(Math.ceil(recuitInfoDate.paging[0].all_page / 5));
    }
  }, [recuitInfoDate]);
  useEffect(() => {
    if (recuitInfoDate && Tmp01Data) {
      setRecruitRefTop(1);
    }
  }, [Tmp01Data, recuitInfoDate]);
  if (!Tmp01Data || !recuitInfoDate)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["recruit"]} ref={recruitRef}>
      <h2
        className={[
          styles["title"],
          `${recruitTop === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        이러한 인재 분들을 기다려요!
      </h2>
      <div className={styles["tag"]}>
        <ul className={"flex"}>
          <li>
            <div
              className={styles["tag-box"]}
              onClick={() => {
                handleClickTag("");
              }}
            >
              전체
            </div>
          </li>
          {Tmp01Data.tmp01List.map((item, index) => (
            <li key={index + item.tmp01}>
              <div
                className={styles["tag-box"]}
                onClick={() => {
                  handleClickTag(item.tmp01);
                }}
              >
                {item.tmp01}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles["list-wrapper"]}>
        <ul className={[styles["list-box"], "flex"].join(" ")}>
          {recruitHeaderList.map((item, index) => (
            <li
              key={index + `${item.title}`}
              className={[styles["list-item"], "flex items-center w-full"].join(
                " "
              )}
            >
              {item.title}
            </li>
          ))}
        </ul>
        <div className={styles["list-container"]}>
          <ul className={styles["list-box"]}>
            {recuitInfoDate.boardList.map((item, index) => (
              <li
                key={item.brd_id}
                className={"flex"}
                onClick={() => {
                  window.open(`${item.tmp05}`);
                }}
              >
                {/* 직군 */}
                <div className={[styles["list-item"], styles["occ"]].join(" ")}>
                  {item.tmp01}
                </div>
                {/* 포지션 */}
                <div
                  className={[styles["list-item"], styles["position"]].join(
                    " "
                  )}
                >
                  {item.ttl}
                </div>
                {/* 경력 구분*/}
                <div
                  className={[
                    styles["list-item"],
                    styles["newOrExpCategory"],
                  ].join(" ")}
                >
                  {item.tmp02}
                </div>
                {/* 고용형태 */}
                <div
                  className={[
                    styles["list-item"],
                    styles["workerCategory"],
                  ].join(" ")}
                >
                  {item.tmp03}
                </div>
                {/* 채용일정 */}
                <div
                  className={[styles["list-item"], styles["schedule"]].join(
                    " "
                  )}
                >
                  {item.tmp04}
                </div>
                {/* 비고 */}
                <div
                  className={[styles["list-item"], styles["note"]].join(" ")}
                >
                  {item.dtl}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* pagination */}
      <div className={styles["pagination"]}>
        {/* 처음 페이지로 이동 */}
        <div
          className={[styles["page-btns"], styles["prev-btn-box"], "mr-1"].join(
            " "
          )}
        >
          <button
            className={styles["btn-prev"]}
            disabled={currentPage === 1}
            onClick={handleFirstPage}
          >
            <img src={btnFirst} alt="" />
          </button>
        </div>
        <div
          className={[styles["page-btns"], styles["prev-btn-box"]].join(" ")}
        >
          <button
            className={styles["btn-prev"]}
            disabled={currentPage === 1}
            onClick={handlePrevPage}
          >
            <img src={btnPrev} alt="" />
          </button>
        </div>
        <ol className={styles["pagination-num"]}>
          {Array.from(
            { length: endIndex - startIndex + 1 },
            (_, i) => startIndex + i
          ).map((page) => (
            <button
              className={[
                styles["page-btns"],
                styles["page-nums"],
                "flex items-center justify-center",
                // `${currentPage === page ? styles.active : ""}`,
              ].join(" ")}
              key={page}
              onClick={() => {
                setCurrentPage(page);
              }}
              disabled={page === currentPage}
            >
              {page}
            </button>
          ))}
        </ol>
        <div
          className={[styles["page-btns"], styles["next-btn-box"]].join(" ")}
        >
          <button
            className={styles["btn-next"]}
            disabled={currentPage === maximunPage}
            onClick={handleNextPage}
          >
            <img src={btnNext} alt="" />
          </button>
        </div>
        <div
          className={[styles["page-btns"], styles["next-btn-box"], "ml-1"].join(
            " "
          )}
        >
          <button
            className={styles["btn-next"]}
            disabled={currentPage}
            onClick={handleLastPage}
          >
            <img src={btnLast} alt="" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default RecruitInfo;
