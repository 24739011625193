/**index.js와 연결됨
 * 1. react-router 해당 공간에서 진행됩니다
 * 2. react-query 해당 공간에서 진행됩니다
 * 3. recoil 해당 공간에 global 영역입니다
 */

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import GoTopButton from "./components/goTop/GoTopButton";
import Banner from "./components/banner/Banner";

const App = () => {
  const handleClickGoTopButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [isBanner, setIsBanner] = useState(true);
  return (
    <>
      <div className="top-wrapper" style={{ position: "fixed", zIndex: 20 }}>
        <Banner isBanner={isBanner} setIsBanner={setIsBanner} />
        <Header isBanner={isBanner} />
      </div>
      <Outlet />
      <Footer />
      <GoTopButton handleClickGoTopButton={handleClickGoTopButton} />
    </>
  );
};

export default App;
