import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
import { QueryClientProvider } from "@tanstack/react-query";
import { getQueryClient } from "./util/queryClient";
import { RecoilRoot } from "recoil";
//React-Query
const queryClient = getQueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </QueryClientProvider>
);
