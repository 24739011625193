import React, { useReducer, useState } from "react";
import styles from "./codeCreateModal.module.scss";
import Button from "../button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postDtlCodes } from "../../api/api";
import { QueryKeys } from "../../../util/queryClient";
import ConfirmModal from "./ConfirmModal";

const headerList = [
  { id: 1, title: "상세코드" },
  { id: 2, title: "상세코드명" },
  { id: 3, title: "코드 설명" },
  { id: 4, title: "정렬 기준" },
];
const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};

const DtlCodeCreateModal = ({ onClick, grpCode, dtlList }) => {
  const queryClient = useQueryClient();

  const [modalState, setModalState] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [reducerState, dispatch] = useReducer(reducer, {
    dtl_code_id: "",
    dtl_code_dscr: "",
    dtl_code_nm: "",
    sort_rdr: "",
  });

  const handleInputChange = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const { mutate: startPost } = useMutation(
    (postData) => postDtlCodes(postData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.LIST],
          refetchType: "active",
        });
        onClick();
      },
    }
  );

  const handleClickConfirmModal = () => {
    setModalState(false);
  };
  const checkDuplication = () => {
    for (const item of dtlList) {
      if (item.dtl_code_id === reducerState.dtl_code_id) return true;
    }
  };
  const invalidate = () => {
    if (!reducerState.dtl_code_id) {
      setModalText("상세 코드를 입력해주세요");
      setModalState(true);
      return true;
    }
    if (!reducerState.dtl_code_nm) {
      setModalText("상세 코드 명을 입력해주세요");
      setModalState(true);
      return true;
    }
    if (!reducerState.dtl_code_dscr) {
      setModalText("코드 설명을 입력해주세요");
      setModalState(true);
      return true;
    }
    if (!reducerState.sort_rdr) {
      setModalText("정렬 기준을 입력해주세요");
      setModalState(true);
      return true;
    }
    if (checkDuplication()) {
      setModalText("이미 존재하는 상세코드 입니다.");
      setModalState(true);
      return true;
    }
  };
  const postCode = () => {
    if (invalidate()) {
      return;
    }
    const postData = {
      grp_code_id: grpCode,
      dtl_code_id: reducerState.dtl_code_id,
      dtl_code_dscr: reducerState.dtl_code_dscr,
      dtl_code_nm: reducerState.dtl_code_nm,
      sort_rdr: reducerState.sort_rdr,
      status: "I",
    };
    startPost(postData);
  };

  return (
    <>
      <div className={styles["dim"]}>
        <div className={[styles["list-wrapper"]].join(" ")}>
          <button
            className="absolute top-[1.25rem] right-[1.25rem]"
            onClick={onClick}
          >
            X
          </button>
          <div className={[styles["title"], "mb-3"].join(" ")}>코드 추가</div>
          <div className={styles["list-header"]}>
            <ul className={[styles["list-box"], "flex"].join(" ")}>
              {headerList.map((item, index) => (
                <li
                  key={index + `${item.title}`}
                  className={[
                    styles["title-list-item"],
                    "flex items-center w-full justify-center",
                  ].join(" ")}
                >
                  <div className={styles["header-text"]}>{item.title}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles["list-container"]}>
            <ul className={[styles["list-box"], "mb-4"].join(" ")}>
              <li
                className={[
                  styles["content-list-item"],
                  styles["active"],
                  "flex items-center",
                ].join(" ")}
              >
                {/* code_id */}
                <div
                  className={[
                    styles["name"],
                    styles["list-item"],
                    "w-1/3",
                  ].join(" ")}
                >
                  <input
                    type="text"
                    placeholder="code_id"
                    value={reducerState.dtl_code_id}
                    onChange={(e) =>
                      handleInputChange("dtl_code_id", e.target.value)
                    }
                  />
                </div>
                {/* code_name */}
                <div
                  className={[
                    styles["number"],
                    styles["list-item"],
                    "w-1/3",
                  ].join(" ")}
                >
                  <input
                    type="text"
                    placeholder="code_name"
                    value={reducerState.dtl_code_nm}
                    onChange={(e) =>
                      handleInputChange("dtl_code_nm", e.target.value)
                    }
                  />
                </div>
                {/* description */}
                <div
                  className={[
                    styles["name"],
                    styles["list-item"],
                    "w-1/3",
                  ].join(" ")}
                >
                  <input
                    type="text"
                    placeholder="description"
                    value={reducerState.dtl_code_dscr}
                    onChange={(e) =>
                      handleInputChange("dtl_code_dscr", e.target.value)
                    }
                  />
                </div>
                {/* sort_rdr */}
                <div
                  className={[
                    styles["name"],
                    styles["list-item"],
                    "w-1/3",
                  ].join(" ")}
                >
                  <input
                    type="text"
                    placeholder="sort_rdr"
                    value={reducerState.sort_rdr}
                    onChange={(e) =>
                      handleInputChange("sort_rdr", e.target.value)
                    }
                  />
                </div>
              </li>
            </ul>
            <Button text={"완료"} buttonFunction={postCode} />
          </div>
        </div>
      </div>
      {modalState && (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          confirmFunction={handleClickConfirmModal}
        />
      )}
    </>
  );
};

export default DtlCodeCreateModal;
