import React, { useEffect, useState } from "react";
import styles from "../scss/nexlook.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import LinkPopup from "../popup/LinkPopup";

function Service({ service, serviceRef, setService, languageSelect }) {
  //coreValue Get
  const { data: serviceData } = useQuery([QueryKeys.MAIN, "NXT_IX"], () =>
    getMainBoard("NXT", "NXT_IX", "")
  );
  const [isShowServicePopup, setIsShowServicePopup] = useState(false);
  useEffect(() => {
    if (serviceData) {
      setService(1);
    }
  }, [serviceData]);
  if (!serviceData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <section className={styles["service"]} ref={serviceRef}>
        <h2
          className={[
            styles["title"],
            // `${scrollY > serviceTop - 500 ? styles["active"] : ""}`,
            `${service === 2 ? styles["active"] : ""}`,
          ].join(" ")}
        >
          {parse(serviceData.boardList[0].ttl)}
        </h2>
        <span className={styles["sub-title"]}>
          {parse(serviceData.boardList[0].dtl)}
        </span>
        <div className={styles["service-box"]}>
          {/* <video controls>
            <source
              src={`${process.env.REACT_APP_IMGURL}/${serviceData.boardList[0].fileInfo[0].file_dntf_code}/${serviceData.boardList[0].fileInfo[0].pld_file_name}`}
              type="video/mp4"
            />
          </video> */}
          <iframe
            id="player"
            type="text/html"
            width="100%"
            height="100%"
            src={`https://youtube.com/embed/${
              serviceData.boardList[0].tmp01.split("/").slice(-1)[0]
            }`}
            frameBorder="0"
            gesture="media"
            allow="encrypted-media"
            title="CNAI"
          ></iframe>
          <iframe
            id="player"
            type="text/html"
            width="100%"
            height="100%"
            src={`https://youtube.com/embed/${
              serviceData.boardList[0].tmp01.split("/").slice(-1)[0]
            }`}
            frameBorder="0"
            gesture="media"
            allow="encrypted-media"
            title="CNAI"
          ></iframe>
          {/* <div className={[styles["btn-group"], "flex"].join(" ")}>
            <button onClick={() => setIsShowServicePopup(true)}>
              NextLook 소개영상 보기
            </button>
          </div> */}
        </div>
      </section>
      {isShowServicePopup && (
        <LinkPopup
          setIsShowServicePopup={setIsShowServicePopup}
          url={serviceData.boardList[0].tmp02.split("/").slice(-1)[0]}
        />
      )}
    </>
  );
}

export default React.memo(Service);
