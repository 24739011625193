import React, { useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "../components/scss/investment.module.scss";
import Contact from "../components/layout/Contact";
import { useRecoilValue } from "recoil";
import { isLoginState, languageSelector } from "../admin/util/recoil";
import { Suspense } from "react";
import parse from "html-react-parser";
import contactBgImgL from "../assets/images/bg/main_contact_1.png";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../util/queryClient";
import { getDetail } from "../api/api";

const InvestmentView = () => {
  const { id } = useParams();
  const investmentViewRef = useRef();
  const contactRef = useRef();

  const languageSelect = useRecoilValue(languageSelector);
  const isAdmin = useRecoilValue(isLoginState);

  const [contact, setContact] = useState(0);
  const [windowWidth, setWindowWidth] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [viewTop, setViewTop] = useState(0);
  const [view, setView] = useState(0);
  const [contactTop, setContactTop] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const { data: resData } = useQuery([QueryKeys.MAIN, "RES_RS", id], () =>
    getDetail("RES", "RES_RS", id)
  );
  let lastScroll = document.documentElement.scrollTop || 0;

  const onScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop > lastScroll) {
      // down
    } else {
      // up
    }
    lastScroll = scrollTop;

    setScrollY(window.scrollY);
    if (investmentViewRef.current) {
      setViewTop(investmentViewRef.current.offsetTop);
      setContactTop(contactRef.current.offsetTop);
    }
  };

  const onResize = () => {
    setWindowWidth(window.innerWidth);
    if (windowWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case investmentViewRef.current:
              setView(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContact(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);

    // 감지할 요소들을 옵저버에 등록
    if (investmentViewRef.current) {
      observer.observe(investmentViewRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
      window.removeEventListener("scroll", onScroll);
    };
  }, [investmentViewRef.current]);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollY, windowWidth]);
  if (!resData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <section className={styles["investment-view"]} ref={investmentViewRef}>
        <div className={styles["investment-view-group"]}>
          <div className={styles["investment-view-box"]}>
            <h3 className={styles["title"]}>CNAI 주주 공지사항</h3>
            <span className={styles["content-title"]}>
              주주님의 성원에 감사드립니다.
            </span>
            <div className={styles["content"]}>
              <div className={styles["content-box"]}>
                {parse(resData.brdInfo[0].dtl)}
              </div>
              <Link to={"/newsroom/investment"}>목록보기</Link>
            </div>
          </div>
        </div>
      </section>
      {/* contact */}
      <div className={styles["bg-area"]}>
        <Suspense fallback="">
          <Contact
            contact={contact}
            contactRef={contactRef}
            text={
              languageSelect === "KOR"
                ? "씨앤에이아이에 대해 궁금하신가요?"
                : "Are you curious about C&AI?"
            }
            bgImgL={contactBgImgL}
            bgImgR={""}
            isPadding={true}
          />
        </Suspense>
      </div>
    </>
  );
};

export default InvestmentView;
