import React, { useEffect, useRef, useState } from "react";
import styles from "../components/scss/tivv.module.scss";

import TivvService from "../components/tivv/TivvService";
import TivvIntro from "../components/tivv/TivvIntro";
import Contact from "../components/layout/Contact";

import bottomBgImg from "../assets/images/bg/pola_bottom.png";

import { useRecoilValue } from "recoil";
import { isLoginState, languageSelector } from "../admin/util/recoil";
import { Suspense } from "react";
import Definition from "../components/tivv/Definition";
import Adventage from "../components/tivv/Adventage";

const Tivv = () => {
  const serviceRef = useRef();
  const definitionRef = useRef();
  const advantageRef = useRef();
  const contactRef = useRef();

  const languageSelect = useRecoilValue(languageSelector);

  const isAdmin = useRecoilValue(isLoginState);
  const [serviceTop, setServiceTop] = useState(0);
  const [definitionTop, setDefinitionTop] = useState(0);
  const [advantageTop, setAdvantageTop] = useState(0);
  const [contact, setContact] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  let lastScroll = document.documentElement.scrollTop || 0;

  const onScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop > lastScroll) {
      // down
    } else {
      // up
    }
    lastScroll = scrollTop;
  };

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case serviceRef.current:
              setServiceTop(2);
              observer.unobserve(target);
              break;
            case definitionRef.current:
              setDefinitionTop(2);
              observer.unobserve(target);
              break;
            case advantageRef.current:
              setAdvantageTop(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContact(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);

    // 감지할 요소들을 옵저버에 등록
    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }
    if (definitionRef.current) {
      observer.observe(definitionRef.current);
    }
    if (advantageRef.current) {
      observer.observe(advantageRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
      window.removeEventListener("scroll", onScroll);
    };
  }, [
    serviceRef.current,
    advantageRef.current,
    definitionRef.current,
    advantageRef.current,
  ]);

  return (
    <div
      className={[
        styles["wrapper"],
        styles["tivv-wrapper"],
        isAdmin ? styles["is-admin"] : "",
      ].join(" ")}
    >
      {/* intro */}

      <TivvIntro isAdmin={isAdmin} />
      {/* service */}
      <div id="tivv-service">
        <TivvService
          serviceTop={serviceTop}
          serviceRef={serviceRef}
          setServiceTop={setServiceTop}
          languageSelect={languageSelect}
        />
      </div>
      {/* definition */}
      <div id="tivv-definition">
        <Definition
          definitionTop={definitionTop}
          definitionRef={definitionRef}
          setDefinitionTop={setDefinitionTop}
          languageSelect={languageSelect}
        />
      </div>
      <div
        className={styles["bg-area"]}
        style={{
          backgroundImage: `url(${bottomBgImg})`,
          backgroundColor: "#1d212e",
        }}
      >
        {/* advantage */}
        <div id="tivv-application">
          <Adventage
            advantageTop={advantageTop}
            advantageRef={advantageRef}
            setAdvantageTop={setAdvantageTop}
          />
        </div>
        {/* contact */}
        <Suspense fallback="">
          <Contact
            isAdmin={isAdmin}
            contact={contact}
            contactRef={contactRef}
            isPadding={true}
            text={"Tivv가 궁금하신가요?"}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default Tivv;
