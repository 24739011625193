import React, { useEffect, useRef, useState } from "react";
import styles from "../components/scss/pola.module.scss";

import PolaIntro from "../components/pola/PolaIntro";
import Service from "../components/pola/Service";
import Application from "../components/pola/Application";
import Advantage from "../components/pola/Advantage";
import Process from "../components/pola/Process";
import Faq from "../components/pola/Faq";
import Contact from "../components/layout/Contact";

import { useRecoilValue } from "recoil";
import { isLoginState, languageSelector } from "../admin/util/recoil";

import bottomBgImg from "../assets/images/bg/pola_bottom.png";

const Pola = () => {
  const serviceRef = useRef();
  const applicationRef = useRef();
  const advantageRef = useRef();
  const processRef = useRef();
  const faqRef = useRef();
  const contactRef = useRef();
  const languageSelect = useRecoilValue(languageSelector);

  const isAdmin = useRecoilValue(isLoginState);
  const [service, setService] = useState(0);
  const [application, setApplication] = useState(0);
  const [advantage, setAdvantage] = useState(0);
  const [process, setProcess] = useState(0);
  const [faq, setFaq] = useState(0);

  const [contact, setContact] = useState(0);

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case serviceRef.current:
              setService(2);
              observer.unobserve(target);
              break;
            case applicationRef.current:
              setApplication(2);
              observer.unobserve(target);
              break;
            case advantageRef.current:
              setAdvantage(2);
              observer.unobserve(target);
              break;
            case processRef.current:
              setProcess(2);
              observer.unobserve(target);
              break;
            case faqRef.current:
              setFaq(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContact(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);
    // 감지할 요소들을 옵저버에 등록
    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }
    if (applicationRef.current) {
      observer.observe(applicationRef.current);
    }
    if (advantageRef.current) {
      observer.observe(advantageRef.current);
    }
    if (processRef.current) {
      observer.observe(processRef.current);
    }
    if (faqRef.current) {
      observer.observe(faqRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
    };
  }, [
    serviceRef.current,
    applicationRef.current,
    advantageRef.current,
    processRef.current,
    faqRef.current,
  ]);
  return (
    <div
      className={[
        styles["wrapper"],
        styles["pola-wrapper"],
        isAdmin ? styles["is-admin"] : "",
      ].join(" ")}
    >
      <PolaIntro isAdmin={isAdmin} />
      <div id="pola-definition">
        <Service
          service={service}
          serviceRef={serviceRef}
          setService={setService}
          languageSelect={languageSelect}
        />
      </div>
      <div className={styles["gradient-bg-section"]}>
        <div id="pola-application">
          <Application
            application={application}
            applicationRef={applicationRef}
            setApplication={setApplication}
            languageSelect={languageSelect}
          />
        </div>
        <div id="pola-advantage">
          <Advantage
            advantage={advantage}
            advantageRef={advantageRef}
            setAdvantage={setAdvantage}
          />
        </div>
      </div>
      <div
        className={styles["bg-area"]}
        style={{
          backgroundImage: `url(${bottomBgImg})`,
          backgroundColor: "#1d212e",
          backgroundSize: "cover",
        }}
      >
        <div id="pola-process">
          <Process
            processui={process}
            processRef={processRef}
            setProcess={setProcess}
          />
        </div>
        <div id="pola-faq">
          <Faq faq={faq} faqRef={faqRef} setFaq={setFaq} />
        </div>
        {/* faq */}

        {/* contact */}
        <Contact
          isAdmin={isAdmin}
          contact={contact}
          contactRef={contactRef}
          isPadding={true}
          text={"Pola와 AI휴먼이 궁금하신가요?"}
        />
      </div>
    </div>
  );
};

export default Pola;
