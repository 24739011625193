import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest3 } from "../../api/api";
import Tag from "../../components/tag/Tag";
import { useNavigate } from "react-router-dom";

const OurSolutions = () => {
  const navigate = useNavigate();

  const [typeCodes, setTypeCodes] = useState({
    grp_code: "MAI",
    dtl_code: "MAI_SOL",
    tmp01: "KOR",
  });
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, typeCodes.dtl_code + typeCodes.tmp01],
    () => getTest3(typeCodes.grp_code, typeCodes.dtl_code, typeCodes.tmp01),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") navigate("/admin/sign-in");
    }
  }, [data, isLoading]);
  return (
    <div className="p-6">
      {!isLoading && data.code === "success" ? (
        <>
          <Tag typeCodes={typeCodes} setTypeCodes={setTypeCodes} />
          <div className="flex w-full items-center justify-between">
            <Table
              layoutList={data.boardList}
              title={"OurSolutions"}
              isEnroll={false}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OurSolutions;
