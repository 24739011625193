import React, { useEffect } from "react";
import styles from "../scss/people.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
function PeopleIntro({ isAdmin }) {
  //메인 Get
  const { data: mainData } = useQuery(
    [QueryKeys.MAIN, "PEO_IN", ""],
    () => getMainBoard("PEO", "PEO_IN", ""),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  if (!mainData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <section
        className={styles["intro"]}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMGURL}/${mainData.fileInfo[0].file_dntf_code}/${mainData.fileInfo[0].pld_file_name})`,
        }}
      >
        <div className={styles["inner"]}>
          <div
            className={[
              styles["intro-text"],
              styles["intro-main-text"],
              "gap-2 block md:flex",
            ].join(" ")}
          >
            <div className="overflow-hidden inline md:inline-block">
              <span>{parse(mainData.ttl)}</span>
            </div>
          </div>
          <div
            className={[
              styles["intro-text"],
              styles["intro-ko-text"],
              "gap-2",
            ].join(" ")}
          >
            <div
              className="overflow-hidden inline md:inline-block"
              style={{
                backgroundColor: "#ffffffc9",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span>{parse(mainData.dtl)}</span>
            </div>
          </div>
        </div>
      </section>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open(
              "/admin/people/intro",
              "blank",
              "height=720,width=1280"
            );
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
    </>
  );
}

export default PeopleIntro;
