import React, { useEffect, useRef, useState } from "react";
import styles from "../components/scss/media.module.scss";

import btnPrev from "../assets/images/button/pagination_prev.png";
import btnNext from "../assets/images/button/pagination_next.png";

import btnFirst from "../assets/images/button/pagination_first.png";
import btnLast from "../assets/images/button/pagination_last.png";
import Contact from "../components/layout/Contact";
import ytubeImg from "../assets/images/ytubeImg.png";

import { getLinkSocialListForPaging, getMainBoard } from "../api/api";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../util/queryClient";
import parse from "html-react-parser";

import { useLocation, useNavigate } from "react-router-dom";
import { isLoginState } from "../admin/util/recoil";
import { useRecoilValue } from "recoil";
// http://43.203.193.82:7010/admin/media/media
// admin : http://43.203.193.82:7010/admin/media/social-media

const Thumbnail = function extractVideoID(url) {
  const urlId = url.split("/").splice(-1)[0];
  return `https://img.youtube.com/vi/${urlId}/default.jpg`;
};

const Media = () => {
  const mediaRef = useRef();
  const contactRef = useRef();
  const isAdmin = useRecoilValue(isLoginState);
  const [maximunPage, setMaximunPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageGroup = Math.ceil(currentPage / (9 / 2));
  const startIndex = Math.floor((currentPageGroup - 1) * (9 / 2)) + 1;
  const endIndex = Math.min(startIndex + Math.floor(9 / 2) - 1, maximunPage);
  const { data: mediaList } = useQuery(
    [QueryKeys.MAIN, "MED_SO", currentPage],
    () =>
      getLinkSocialListForPaging(
        "MED_NS",
        "MED_SO",
        9,
        (currentPage - 1) * 9,
        ""
      )
  );
  //첫페이지 이동
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  //다음 페이지 이동
  const handleNextPage = () => {
    if (currentPage >= maximunPage) return;
    else {
      setCurrentPage(currentPage + 1);
    }
  };
  // 이전 페이지 이동
  const handlePrevPage = () => {
    if (currentPage <= 1) return;
    else {
      setCurrentPage(currentPage - 1);
    }
  };
  //마지막 페이지로 이동
  const handleLastPage = () => {
    setCurrentPage(maximunPage);
  };
  useEffect(() => {
    if (mediaList) {
      setMaximunPage(Math.ceil(mediaList.paging[0].all_page / 9));
    }
  }, [mediaList]);
  const [windowWidth, setWindowWidth] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [mediaTop, setMediaTop] = useState(0);
  const [contactTop, setContactTop] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  let lastScroll = document.documentElement.scrollTop || 0;

  const onScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop > lastScroll) {
      // down
    } else {
      // up
    }
    lastScroll = scrollTop;

    setScrollY(window.scrollY);
    if (mediaRef.current) {
      setMediaTop(mediaRef.current.offsetTop);
      setContactTop(contactRef.current.offsetTop);
    }
  };

  const onResize = () => {
    setWindowWidth(window.innerWidth);
    if (windowWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollY, windowWidth]);
  if (!mediaList)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <div
        className={[
          styles["wrapper"],
          styles["media-wrapper"],
          isAdmin ? styles["is-admin"] : "",
        ].join(" ")}
      >
        {/* media */}
        <section className={styles["media"]} ref={mediaRef}>
          <h2
            className={[
              styles["title"],
              `${scrollY > mediaTop - 500 ? styles["active"] : ""}`,
            ].join(" ")}
          >
            씨앤에이아이의 소식을 전해드립니다.
          </h2>
          <div className={styles["scroll-x"]}>
            <ul className={styles["media-list"]}>
              {mediaList.boardList.map((item) => (
                <li key={item.brd_id}>
                  {}
                  {item.fileInfo == "" ? (
                    <div className={styles["img-box"]}>
                      <img alt="" src={Thumbnail(item.tmp01)} />
                    </div>
                  ) : (
                    item.fileInfo.map((file, index) => (
                      <div
                        className={styles["img-box"]}
                        key={`${file.rgnl_file_name}_${index}`}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMGURL}/${file.file_dntf_code}/${file.pld_file_name}`}
                          alt=""
                        />
                      </div>
                    ))
                  )}
                  <div className={styles["media-box"]}>
                    <strong className={styles["media-title"]}>
                      {item.ttl}
                    </strong>
                    <div className={styles["content-box"]}>
                      {parse(item.dtl)}
                    </div>
                    <div className={styles["bottom-box"]}>
                      <span className={styles["date"]}>{item.tmp02}</span>
                      {item.tmp01 != "" ? (
                        <button
                          onClick={() => window.open(item.tmp01, "_blank")}
                        >
                          View More
                        </button>
                      ) : (
                        <button>View More</button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* pagination */}

          <div className={styles["pagination"]}>
            {/* 처음 페이지로 이동 */}
            <div
              className={[
                styles["page-btns"],
                styles["prev-btn-box"],
                "mr-1",
              ].join(" ")}
            >
              <button
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                <img src={btnFirst} alt="" />
              </button>
            </div>
            <div
              className={[styles["page-btns"], styles["prev-btn-box"]].join(
                " "
              )}
            >
              <button
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
                onClick={handlePrevPage}
              >
                <img src={btnPrev} alt="" />
              </button>
            </div>
            <ol className={styles["pagination-num"]}>
              {Array.from(
                { length: endIndex - startIndex + 1 },
                (_, i) => startIndex + i
              ).map((page) => (
                <button
                  className={[
                    styles["page-btns"],
                    styles["page-nums"],
                    "flex items-center justify-center",
                    // `${currentPage === page ? styles.active : ""}`,
                  ].join(" ")}
                  key={page}
                  onClick={() => {
                    setCurrentPage(page);
                  }}
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
            </ol>
            <div
              className={[styles["page-btns"], styles["next-btn-box"]].join(
                " "
              )}
            >
              <button
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
                onClick={handleNextPage}
              >
                <img src={btnNext} alt="" />
              </button>
            </div>
            <div
              className={[
                styles["page-btns"],
                styles["next-btn-box"],
                "ml-1",
              ].join(" ")}
            >
              <button
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
                onClick={handleLastPage}
              >
                <img src={btnLast} alt="" />
              </button>
            </div>
          </div>
        </section>
        <div className={styles["bg-wrapper"]}>
          <Contact
            text="씨앤에이아이에 대해 궁금하신가요?"
            isAdmin={isAdmin}
            contact={contactTop}
            contactRef={contactRef}
            isPadding={true}
          />
        </div>
      </div>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open("/admin/media/media", "blank", "height=720,width=1280");
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
    </>
  );
};

export default Media;
