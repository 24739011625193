import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTemp, getTest3 } from "../../api/api";

import styles from "../../components/scss/pagination/pagination.module.scss";

// images
import iconPrev from "../../assets/images/ico/arrow_right.png";
import iconNext from "../../assets/images/ico/arrow_right.png";
import { useLocation, useNavigate } from "react-router-dom";

const RecruitInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;
  const [typeCodes, setTypeCodes] = useState({
    grp_code: "REC",
    dtl_code: "REC_RE",
    limitLine: 10,
    tmp01: "",
  });
  typeCodes.offset = (currentPage - 1) * typeCodes.limitLine;
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, currentPage + typeCodes.tmp01],
    () =>
      getTest3(
        typeCodes.grp_code,
        typeCodes.dtl_code,
        typeCodes.tmp01,
        typeCodes.limitLine,
        typeCodes.offset
      ),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const { data: tagData } = useQuery(
    [QueryKeys.TEMP],
    () => getTemp(typeCodes.grp_code, typeCodes.dtl_code),
    {
      staleTime: 0,
      cacheTime: 0,
    },
    { enabled: !!data }
  );
  const [maximunPage, setMaximunPage] = useState();

  // 태그 리스트 불러오기
  const tags = tagData?.tmp01List.map((item) => item.tmp01);

  //현재 보여질 페이지 목록 선언
  const currentPageGroup = Math.ceil(currentPage / (typeCodes.limitLine / 2));
  const startIndex = (currentPageGroup - 1) * (typeCodes.limitLine / 2) + 1;
  const endIndex = Math.min(
    startIndex + typeCodes.limitLine / 2 - 1,
    maximunPage
  );

  const handleClickTagBtn = (tag) => {
    if (tag === "전체") {
      setTypeCodes({ ...typeCodes, tmp01: "" });
      navigate(`/admin/recruit/recruit?page=1`);
    } else {
      setTypeCodes({ ...typeCodes, tmp01: tag });
      navigate(`/admin/recruit/recruit?page=1`);
    }
  };

  //1페이지로 이동
  const handleFirstPage = () => {
    navigate(`/admin/recruit/recruit?page=1`);
  };
  //다음 페이지 이동
  const handleNextPage = () => {
    navigate(`/admin/recruit/recruit?page=${currentPage + 1}`);
  };
  // 이전 페이지 이동
  const handlePrevPage = () => {
    navigate(`/admin/recruit/recruit?page=${currentPage - 1}`);
  };
  //마지막 페이지로 이동
  const handleLastPage = () => {
    navigate(`/admin/recruit/recruit?page=${maximunPage}`);
  };

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setMaximunPage(
          Math.ceil(data.paging[0].all_page / typeCodes.limitLine)
        );
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (currentPage < 0) navigate("/admin/recruit/recruit?page=1");
  }, []);

  return (
    <>
      {!isLoading && data ? (
        <div className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <button
              className={[
                styles["btn-tag"],
                "flex items-center gap-2 px-4 py-1 hover:border-slate-500 hover:text-slate-500 text-sm rounded border border-slate-400 border-solid text-slate-400 ease-in-out",
              ].join(" ")}
              onClick={() => handleClickTagBtn("전체")}
            >
              전체
            </button>
            {tags?.map((item, index) => (
              <button
                className={[
                  styles["btn-tag"],
                  "flex items-center gap-2 px-4 py-1 hover:border-slate-500 hover:text-slate-500 text-sm rounded border border-slate-400 border-solid text-slate-400 ease-in-out",
                ].join(" ")}
                key={index}
                onClick={() => handleClickTagBtn(item)}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="flex w-full items-center justify-between">
            <Table
              layoutList={data.boardList}
              title={"채용정보"}
              isEnroll={true}
            />
          </div>
          <div className="flex justify-center w-full p-6">
            {/* 처음 페이지로 이동 */}
            <div
              className={[
                styles["page-btns"],
                styles["prev-btn-box"],
                "mr-1",
              ].join(" ")}
            >
              <button
                disabled={currentPage === 1}
                onClick={handleFirstPage}
                className={styles["btn-prev"]}
              >
                <img src={iconPrev} alt="" />
                <img className="-ml-[0.125rem]" src={iconPrev} alt="" />
              </button>
            </div>
            <div
              className={[styles["page-btns"], styles["prev-btn-box"]].join(
                " "
              )}
            >
              <button
                onClick={handlePrevPage}
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
              >
                <img src={iconPrev} alt="" />
              </button>
            </div>
            <ol className="flex gap-2 max-w-[11.4rem] mx-3 overflow-hidden">
              {Array.from(
                { length: endIndex - startIndex + 1 },
                (_, i) => startIndex + i
              ).map((page) => (
                <button
                  className={[
                    styles["page-btns"],
                    styles["page-nums"],
                    "flex items-center justify-center",
                    `${currentPage === page ? styles.active : ""}`,
                  ].join(" ")}
                  key={page}
                  onClick={() => {
                    navigate(`/admin/recruit/recruit?page=${page}`);
                  }}
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
            </ol>
            <div
              className={[styles["page-btns"], styles["next-btn-box"]].join(
                " "
              )}
            >
              <button
                onClick={handleNextPage}
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
              >
                <img src={iconNext} alt="" />
              </button>
            </div>
            <div
              className={[
                styles["page-btns"],
                styles["next-btn-box"],
                "ml-1",
              ].join(" ")}
            >
              <button
                onClick={handleLastPage}
                disabled={currentPage === maximunPage}
                className={styles["btn-next"]}
              >
                <img className="-mr-[0.125rem]" src={iconNext} alt="" />
                <img src={iconNext} alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default RecruitInfo;
