import React, { useEffect } from "react";
import styles from "../scss/pola.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function Advantage({ advantage, advantageRef, setAdvantage }) {
  //coreValue Get
  const { data: advantageData } = useQuery([QueryKeys.MAIN, "POL_UN", ""], () =>
    getMainBoard("POL", "POL_UN", "")
  );
  useEffect(() => {
    if (advantageData) {
      setAdvantage(1);
    }
  }, []);
  if (!advantageData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["advantage"]} ref={advantageRef}>
      <h2
        className={[
          styles["title"],
          // `${scrollY > advantageTop - 500 ? styles["active"] : ""}`,
          `${advantage === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {parse(advantageData.boardList[0].dtl)}
      </h2>
      <ul className={styles["advantage-list"]}>
        {advantageData?.boardList.slice(1).map((item) => (
          <li key={item.brd_id} className={styles["advantage-item"]}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <div className={styles["content-box"]}>
              <span className={styles["num"]}>0{item.ord_no - 1}</span>
              <strong className={styles["content-title"]}>
                {parse(item.ttl)}
              </strong>
              <div className={styles["content-text"]}>{parse(item.dtl)}</div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default React.memo(Advantage);
