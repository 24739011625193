import React, { useEffect } from "react";
import styles from "../scss/people.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import { useState } from "react";

import btn_plus from "../../assets/images/button/btn_plus.png";

function PeopleUi({ people, peopleRef, setPeople }) {
  const [clickedItemId, setClickedItemId] = useState();

  //coreValue Get
  const { data: peopleData } = useQuery([QueryKeys.MAIN, "PEO_PE", ""], () =>
    getMainBoard("PEO", "PEO_PE", "")
  );

  const handleClickShowPeoplePopup = (id) => {
    if (clickedItemId === id) {
      setClickedItemId(null);
    } else {
      setClickedItemId(id);
    }
  };

  useEffect(() => {
    if (peopleData) {
      setPeople(1);
    }
  }, [peopleData]);

  if (!peopleData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["people"]} ref={peopleRef}>
      <h2
        className={[
          styles["title"],
          //   scrollY > preopleTop - 500 ? styles["active"] : "",
          `${people === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {parse(peopleData.boardList[0].ttl)}
      </h2>
      <span className={styles["sub-title"]}>
        {parse(peopleData.boardList[0].dtl)}
      </span>
      <div className={styles["people-list"]}>
        {peopleData?.boardList.slice(1).map((item, index) => (
          <React.Fragment key={item.brd_id}>
            <div
              className={styles["people-item"]}
              style={{
                backgroundSize: "auto 100%",
                backgroundImage: `url(${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name})`,
              }}
            >
              <div className={styles["content-box"]}>
                <strong className={styles["content-title"]}>
                  {parse(item.ttl)}
                </strong>
                <div className={styles["content-text"]}>{parse(item.dtl)}</div>
                <div
                  className={`${[
                    styles["btn-show-popup"],
                    clickedItemId === item.brd_id ? styles["active"] : "",
                  ].join(" ")}`}
                  onClick={() => handleClickShowPeoplePopup(item.brd_id)}
                >
                  <button>
                    <img src={btn_plus} alt="" />
                  </button>
                </div>
              </div>
            </div>
            {clickedItemId === item.brd_id && (
              <span
                className={`${[
                  styles["popup-item"],
                  styles[`popup-${index + 1}`],
                ].join(" ")}`}
              >
                {parse(item.tmp05)}
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}

export default React.memo(PeopleUi);
