import React, { useEffect, useState } from "react";
import styles from "../scss/footer.module.scss";
import { Link, useNavigate } from "react-router-dom";

import instagram from "../../assets/images/ico/ico_instagram.png";
import youtube from "../../assets/images/ico/ico_youtube.png";
import meet from "../../assets/images/ico/ico_team.png";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import { getFooter, getMainBoard } from "../../api/api";
import { useRecoilValue } from "recoil";
import { languageSelector } from "../../admin/util/recoil";

const footerList = [
  {
    id: 1,
    list: "Main",
    item: [
      <div key="main-intro">Main</div>,
      <div key="main-solutions">Solutions</div>,
      <div key="main-partners">Partners</div>,
      <div key="main-awards">Awards</div>,
      <div key="main-history">History</div>,
      <div key="main-values">Core Values</div>,
      <div key="main-mediakit">Media Kit</div>,
    ],
  },
  {
    id: 2,
    list: "Our Solutions",
    item: [<div key="pola-main">Pola</div>, <div key="tivv-main">Tivv</div>],
  },
  {
    id: 3,
    list: "People",
    item: [
      <div key="people-main">Who we are</div>,
      <div key="recruit-main">Recruit</div>,
    ],
  },
  {
    id: 4,
    list: "Newsroom",
    item: [
      <div key="media">Media</div>,
      <div key="researches">Researches</div>,
      <div key="investment">Investment</div>,
    ],
  },
  {
    id: 5,
    list: "Contact us",
    item: [
      <div key="coalition">사업제휴</div>,
      <div key="marketing-pr">마케팅 PR</div>,
    ],
  },
];
const Footer = () => {
  const [windowWidth, setWindowWidth] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const languageSelect = useRecoilValue(languageSelector);
  const navigate = useNavigate();
  const scrollToComponent = (id, list) => {
    // 해당 컴포넌트로 스크롤
    if (list === "Main") {
      navigate("/");
    } else if (list === "Our Solutions") {
      if (id === "pola-main") {
        navigate("/pola");
      } else {
        navigate("/tivv");
      }
    } else if (list === "People") {
      if (id === "people-main") {
        navigate("/people");
      } else {
        navigate("/recruit");
      }
    } else if (list === "Contact us") {
      if (id === "coalition") {
        const mailAppUrl = `mailto:Contact@cnai.ai`;
        window.open(mailAppUrl);
      } else {
        const mailAppUrl = `mailto:pr@cnai.ai`;
        window.open(mailAppUrl);
      }
    } else {
      if (id === "media") {
        navigate("/newsroom/media");
      } else if (id === "investment") {
        navigate("/newsroom/investment");
      } else {
        navigate("/newsroom/research");
      }
    }
    setTimeout(() => {
      const myComponent = document.getElementById(`${id}`);
      if (myComponent) {
        myComponent.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 400);
  };

  const onResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    if (windowWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const { data: footerData } = useQuery(
    [QueryKeys.FOOTER, languageSelect],
    () => getFooter(languageSelect),
    {
      select: (data) => {
        return data.footerInfo[0];
      },
    }
  );
  const { data: mediaData } = useQuery(
    [QueryKeys.MAIN, "MAI_KT", languageSelect],
    () => getMainBoard("MAI", "MAI_KT", languageSelect),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );

  //파일 다운로드 함수
  const downloadStart = async function (file) {
    const data = mediaData.fileInfo.filter((item) => item.file_dscr === file);

    const filePath = `${process.env.REACT_APP_IMGURL}/${data[0].file_dntf_code}/${data[0].pld_file_name}`;

    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = data[0].rgnl_file_name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [windowWidth]);

  return (
    <footer className={styles["footer"]}>
      {footerData ? (
        <>
          {languageSelect !== "ENG" ? (
            <div
              className={[styles["menu"], "flex-col md:flex-row flex"].join(
                " "
              )}
            >
              <ul
                className={[
                  styles["menu-list"],
                  "md:inline-flex md:flex-row md:gap-[0] flex flex-col gap-[4rem]",
                ].join(" ")}
              >
                {footerList.map((menu) => (
                  <li key={menu.id}>
                    <div className={styles["menu-title"]}>{menu.list}</div>
                    <ul className={styles["menu-item"]}>
                      {menu.item.map((list, index) => (
                        <li
                          key={list.key}
                          onClick={() => {
                            scrollToComponent(list.key, menu.list);
                          }}
                        >
                          {list}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              {isMobile && (
                <div className={styles["info"]}>
                  <p>
                    {footerData.cmpn_name} <br />
                    사업자등록번호 : {footerData.cmpn_rgst_nmbr} <br /> 대표 :{" "}
                    {footerData.rprs_name} <br />
                    {footerData.ml} <br />
                    {footerData.drs1} <br />
                    {footerData.drs2}
                  </p>
                </div>
              )}
              <ul
                className={[
                  styles["social-group"],
                  "md:inline-flex md:flex-row flex flex-col gap-0",
                ].join(" ")}
              >
                <li className="mb-[5rem] md:mb-0">
                  <div className="mb-4">Channel</div>
                  <div className="flex gap-2">
                    <span>
                      <img
                        src={instagram}
                        alt=""
                        onClick={() => {
                          window.open("https://www.instagram.com/cnai.ai/");
                        }}
                      />
                    </span>
                    <span>
                      <img
                        src={youtube}
                        alt=""
                        onClick={() => {
                          window.open(
                            "https://www.youtube.com/channel/UC2VAxP6sgZWvlVNpaNxMXLQ"
                          );
                        }}
                      />
                    </span>
                    <span>
                      <img
                        src={meet}
                        alt=""
                        onClick={() => {
                          window.open(
                            "https://grape-kilometer-e8e.notion.site/CN-AI-f7aee83f325146fea8bda0f91ddfff29"
                          );
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li>
                  <div className="mb-4">Media Kit</div>
                  <button
                    className={styles["btn-download"]}
                    onClick={() => {
                      downloadStart("tivv");
                    }}
                  >
                    C.I Download
                  </button>
                </li>
              </ul>
            </div>
          ) : (<div
          className={
            languageSelect === "ENG" &&
            [styles["en-info"], styles["info"]].join(" ")
          }
        >
          <p>
            {footerData.cmpn_name} <br />
            Company Registration Number : {footerData.cmpn_rgst_nmbr} ㅣ
            CEO :{footerData.rprs_name} ㅣ {footerData.ml}
            <br />
            {footerData.drs1} ㅣ {footerData.drs2} <br />
            {footerData.cpyr}
          </p>
        </div>)}
          {!isMobile && (
            <>
              {languageSelect !== "ENG" ? (
                <div className={styles["info"]}>
                  <p>
                    {footerData.cmpn_name} <br />
                    사업자등록번호 : {footerData.cmpn_rgst_nmbr} ㅣ 대표 :
                    {footerData.rprs_name} ㅣ {footerData.ml}
                    <br />
                    {footerData.drs1} ㅣ {footerData.drs2} <br />
                    {footerData.cpyr}
                  </p>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : null}
    </footer>
  );
};

export default Footer;
