import React, { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { postSignUp } from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../components/scss/sign/signUp.module.scss";
import ConfirmModal from "../../components/modal/ConfirmModal";

function UserManagementEnroll() {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comCode, setComCode] = useState("");
  const [teamCode, setTeamCode] = useState("");
  const [signUpStatus, setSignUpStatus] = useState("");

  const [activeId, setActiveId] = useState();
  const [activeItemId, setActiveItemId] = useState();

  //Method : POST
  const { mutate } = useMutation((signData) => postSignUp(signData), {
    onSuccess: (data) => {
      if (data.code === "fail") {
        setSignUpStatus(data.msg);
      } else {
        setModalState(true);
      }
    },
  });
  //모달창을 닫는 함수
  const successSignUp = () => {
    navigate(`/admin/management/member`);
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    if (password !== checkPassword) {
      setSignUpStatus("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }
    const signInfo = {
      user_id: userId,
      ml: email,
      pswr: password,
      name: name,
      cel_phn: phoneNumber,
      cmpn_code: comCode,
      dprt_code: teamCode,
    };

    mutate(signInfo);
  };

  return (
    <div className="flex flex-col h-full items-center p-6 pt-[5rem]">
      <h1 className="text-xl font-bold">Join</h1>
      <form
        onSubmit={handleSignUp}
        className={[styles["join-form"], "w-[30rem] mt-6"].join(" ")}
      >
        <div className={styles["join-box"]}>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            required
            minLength={4}
            maxLength={20}
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="아이디를 입력하세요"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="이메일 주소를 입력하세요"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            required
            minLength={4}
            maxLength={10}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="password">Password 확인</label>
          <input
            type="password"
            id="password"
            required
            minLength={4}
            maxLength={10}
            value={checkPassword}
            onChange={(e) => setCheckPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="password">이름</label>
          <input
            type="text"
            id="name"
            required
            minLength={2}
            maxLength={10}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="이름을 입력하세요"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="password">연락처</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber || ""}
            onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ""))}
            maxLength={11}
            placeholder="연락처를 입력하세요(선택)"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="password">회사코드</label>
          <input
            type="text"
            id="comCode"
            value={comCode}
            onChange={(e) => setComCode(e.target.value)}
            placeholder="회사코드를 입력하세요(선택)"
          />
        </div>
        <div className={styles["join-box"]}>
          <label htmlFor="password">부서코드</label>
          <input
            type="text"
            id="teamCode"
            value={teamCode}
            onChange={(e) => setTeamCode(e.target.value)}
            placeholder="부서코드를 입력하세요(선택)"
          />
        </div>

        <button type="submit" className={styles["btn-join"]}>
          회원생성
        </button>
      </form>
      <div className={styles["helper-text"]}>{signUpStatus}</div>
      {modalState ? (
        <ConfirmModal
          modalText={"가입 완료"}
          confirmText="확인"
          confirmFunction={successSignUp}
        />
      ) : null}
    </div>
  );
}

export default UserManagementEnroll;
