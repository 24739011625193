import React, { useEffect, useState } from "react";
import styles from "../scss/main.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function MainIntro({ isAdmin, languageSelect }) {
  const [isVideo, setIsVideo] = useState(false);

  //메인 Get
  const { data: mainData } = useQuery(
    [QueryKeys.MAIN, "MAI_MAI", languageSelect],
    () => getMainBoard("MAI", "MAI_MAI", languageSelect),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  useEffect(() => {
    if (mainData) {
      const xtns = mainData.fileInfo[0].xtns;
      if (xtns === ".mp4") {
        setIsVideo(true);
      }
    }
  }, [mainData]);

  if (!mainData) return <div></div>;

  return (
    <>
      <section className={styles["intro"]}>
        <div className={styles["scroll-icon"]}></div>
        {isVideo ? (
          <div className={styles["bg-video"]}>
            <video autoPlay muted loop>
              <source
                src={`${process.env.REACT_APP_IMGURL}/${mainData.fileInfo[0].file_dntf_code}/${mainData.fileInfo[0].pld_file_name}`}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          <div
            className={styles["bg-img"]}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_IMGURL}/${mainData.fileInfo[0].file_dntf_code}/${mainData.fileInfo[0].pld_file_name})`,
            }}
          ></div>
        )}
        <div className={styles["inner"]}>
          {languageSelect === "KOR" && (
            <>
              <div
                className={[
                  styles["intro-text"],
                  styles["intro-en-text"],
                  "gap-2 block md:flex",
                ].join(" ")}
              >
                <div className="overflow-hidden inline md:inline-block">
                  <span>Generate</span>
                </div>
                <div className="overflow-hidden inline md:inline-block">
                  <span>AI,</span>
                </div>
                <br />
                <div className="overflow-hidden inline md:inline-block">
                  <span>Create</span>
                </div>
                <div className="overflow-hidden inline md:inline-block">
                  <span>Opportunity</span>
                </div>
              </div>
              <div
                className={[
                  styles["intro-text"],
                  styles["intro-ko-text"],
                  "flex gap-2",
                ].join(" ")}
              >
                <div className="overflow-hidden">
                  <span>나의</span>
                </div>
                <div className="overflow-hidden">
                  <span>상상을</span>
                </div>
                <div className="overflow-hidden">
                  <span>현실로</span>
                </div>
                <div className="overflow-hidden">
                  <span>만들어</span>
                </div>
                <div className="overflow-hidden">
                  <span>보세요</span>
                </div>
              </div>
            </>
          )}
          {languageSelect === "ENG" && (
            <>
              <div
                className={[
                  styles["intro-text"],
                  styles["intro-en-text"],
                  "gap-2 block md:flex",
                ].join(" ")}
              >
                <div className="overflow-hidden inline md:inline-block">
                  <span>Generate</span>
                </div>
                <div className="overflow-hidden inline md:inline-block">
                  <span>AI,</span>
                </div>
                <br />
                <div className="overflow-hidden inline md:inline-block">
                  <span>Create</span>
                </div>
                <div className="overflow-hidden inline md:inline-block">
                  <span>Opportunity</span>
                </div>
              </div>
            </>
          )}
        </div>
        {isAdmin && languageSelect === "KOR" ? (
          <button
            onClick={() => {
              window.open("/admin/main/logo", "blank", "height=720,width=1280");
            }}
            className={styles["btn-show-layer"]}
          >
            admin
          </button>
        ) : null}
      </section>
    </>
  );
}

export default MainIntro;
