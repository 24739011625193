import React, { useEffect, useState } from "react";
import CodeManageTable from "../../components/table/CodeManageTable";
import { getGrpCodes } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { useLocation, useNavigate } from "react-router-dom";

function Code() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;
  const typeCodes = { grp_code: "MAI", dtl_code: "MAI_CV", limitLine: 10 };
  typeCodes.offset = (currentPage - 1) * typeCodes.limitLine;

  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, typeCodes],
    () => getGrpCodes(),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);
  return (
    <>
      {!isLoading && data.code === "success" ? (
        <div className="p-6">
          <div className="flex w-full items-start justify-between gap-4 mt-4">
            <CodeManageTable grpList={data.grpCodeList} />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Code;
