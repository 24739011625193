import React, { useEffect, useState } from "react";
import styles from "../scss/nexlook.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
import LinkPopup from "../popup/LinkPopup";

function NexLookIntro({ isAdmin }) {
  //메인 Get
  const { data: mainData } = useQuery(
    [QueryKeys.MAIN, "NXT_IT"],
    () => getMainBoard("NXT", "NXT_IT", ""),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  const [isShowServicePopup, setIsShowServicePopup] = useState(false);
  //도입,방문하기 클릭시 호출되는 함수
  const UrlButtonHandler = (url) => {
    const mailAppUrl = "mailto:" + url;
    window.open(mailAppUrl);
  };

  if (!mainData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <section
        className={styles["intro"]}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMGURL}/${mainData.fileInfo[0].file_dntf_code}/${mainData.fileInfo[0].pld_file_name})`,
        }}
      >
        <div className={styles["inner"]}>
          <div
            className={[
              styles["intro-text"],
              styles["intro-ko-text"],
              "gap-2 block md:flex",
            ].join(" ")}
          >
            <div>{parse(mainData.dtl)}</div>
          </div>
          <div className={[styles["btn-group"], "flex"].join(" ")}>
            {mainData.tmp03 === "true" ? (
              <button
                onClick={() => {
                  UrlButtonHandler(mainData.tmp01);
                }}
              >
                {mainData.tmp05}
              </button>
            ) : null}
            {mainData.tmp04 === "true" ? (
              <button
                onClick={() => {
                  setIsShowServicePopup(true);
                }}
              >
                {mainData.tmp06}
              </button>
            ) : null}
          </div>
        </div>
      </section>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open(
              "/admin/nextlook/main",
              "blank",
              "height=720,width=1280"
            );
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
      {isShowServicePopup && (
        <LinkPopup
          setIsShowServicePopup={setIsShowServicePopup}
          url={mainData.tmp02.split("/").slice(-1)[0]}
        />
      )}
    </>
  );
}

export default NexLookIntro;
