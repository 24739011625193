import React, { useReducer, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { postTest } from "../../api/api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmModal";
import deleteBtn from "../../assets/images/ico/btn_close_sm.png";
import "../../util/font";

const modules = {
  toolbar: {
    container: [
      [
        {
          size: [
            "10px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "28px",
            "30px",
            "32px",
            "36px",
            "38px",
            "40px",
            "42px",
            "44px",
            "46px",
            "48px",
            "50px",
            "52px",
            "64px",
            "80px",
          ],
        },
      ],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      [{ align: ["left", "center", "right", "justify"] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};

export const codeType = {
  "/admin/recruit/recruit/enroll": {
    grp_code_id: "REC",
    dtl_code_id: "REC_RE",
  },
};

const RecruitInfoEnroll = () => {
  const navigate = useNavigate();
  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    ord_no: "",
    tmp01: "",
    tmp02: "",
    tmp03: "",
    tmp04: "",
    tmp05: "",
  });
  const handleInputChange = (field, value) => {
    if (field === "ord_no") {
      const intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      if (intValue === 0) return;
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else {
      dispatch({ type: "CHANGE", field, payload: value });
    }
  };
  const [files, setFiles] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [SecondModalState, setSecondModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [btAble, setBtAble] = useState(false);
  const { mutate: startPost } = useMutation((postData) => postTest(postData), {
    onSuccess: (data) => {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setSecondModalState(true);
      }
    },
  });

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
    if (window.opener) {
      window.opener.location.reload();
    }
  };
  // React-quill 웹 에디터의 value -> html태그를 포함하고 있기에 유효성 검사를 위해 태그를 제거
  const parsingHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // 유효성 검사
  const validation = () => {
    if (reducerState.ord_no < 1 || isNaN(reducerState.ord_no)) {
      setModalText("정렬순서를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp01.trim()) {
      setModalText("직군을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.postTitle.trim()) {
      setModalText("포지션을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp02.trim()) {
      setModalText("경력구분을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp03.trim()) {
      setModalText("고용형태를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp04.trim()) {
      setModalText("채용일정을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!parsingHtml(reducerState.postCn).trim()) {
      setModalText("비고를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp05.trim()) {
      setModalText("URL을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //작성 완료시 호출되는 함수
  const submitPost = () => {
    setBtAble(true);
    if (validation()) return;
    const formData = new FormData();
    formData.append("ttl", reducerState.postTitle);
    formData.append("dtl", reducerState.postCn);
    formData.append(
      "dtl_code",
      codeType[`${window.location.pathname}`].dtl_code_id
    );
    formData.append(
      "grp_code",
      codeType[`${window.location.pathname}`].grp_code_id
    );
    formData.append("tmp01", reducerState.tmp01);
    formData.append("tmp02", reducerState.tmp02);
    formData.append("tmp03", reducerState.tmp03);
    formData.append("tmp04", reducerState.tmp04);
    formData.append("tmp05", reducerState.tmp05);
    formData.append("ord_no", reducerState.ord_no);
    for (let i = 0; i < files.length; i++) {
      formData.append("fileField", files[i].data);
    }
    startPost(formData);
  };
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <PageTitle title={"등록하기"} />
      <table>
        <tbody>
          <tr>
            <th>정렬순서</th>
            <td>
              <input
                value={reducerState.ord_no || ""}
                name="ord_no"
                type="text"
                placeholder="정렬순서를 입력해주세요"
                onChange={(e) => handleInputChange("ord_no", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>직군</th>
            <td>
              <input
                value={reducerState.tmp01}
                name="tmp01"
                type="text"
                placeholder="직군을 입력해주세요"
                onChange={(e) => handleInputChange("tmp01", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>포지션</th>
            <td>
              <input
                value={reducerState.postTitle}
                name="tilte"
                type="text"
                placeholder="포지션을 입력해 주세요"
                onChange={(e) => handleInputChange("postTitle", e.target.value)}
              />
            </td>
          </tr>

          <tr>
            <th>경력 구분</th>
            <td>
              <input
                value={reducerState.tmp02}
                name="tmp02"
                type="text"
                placeholder="경력 구분을 입력해주세요"
                onChange={(e) => handleInputChange("tmp02", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>고용 형태</th>
            <td>
              <input
                value={reducerState.tmp03}
                name="tmp03"
                type="text"
                placeholder="고용 형태 입력해주세요"
                onChange={(e) => handleInputChange("tmp03", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>채용일정</th>
            <td>
              <input
                value={reducerState.tmp04}
                name="tmp04"
                type="text"
                placeholder="채용일정을 입력해주세요"
                onChange={(e) => handleInputChange("tmp04", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>비고</th>
            <td>
              <input
                value={reducerState.postCn}
                name="postCn"
                type="text"
                placeholder="비고를 입력해주세요"
                onChange={(e) => handleInputChange("postCn", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>URL</th>
            <td>
              <input
                value={reducerState.tmp05}
                name="URL을"
                type="text"
                placeholder="URL을 입력해주세요"
                onChange={(e) => handleInputChange("tmp05", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Button disable={btAble} text="등록 완료" buttonFunction={submitPost} />
      {modalState ? (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          confirmFunction={closeModal}
        />
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="작성 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
};
export default RecruitInfoEnroll;
