import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styles from "../components/scss/people.module.scss";

import PeopleIntro from "../components/people/PeopleIntro";
import Leaders from "../components/people/Leaders";
import PeopleUi from "../components/people/People";
import Photo from "../components/people/Photo";
import Culture from "../components/people/Culture";

// images
import bg_culture from "../assets/images/bg/people_culture.png";
import Contact from "../components/layout/Contact";
import { useRecoilValue } from "recoil";
import { isLoginState } from "../admin/util/recoil";

const People = () => {
  const leadersRef = useRef();
  const peopleRef = useRef();
  const photoRef = useRef();
  const cultureRef = useRef();
  const contactRef = useRef();

  const [leaders, setLeaders] = useState(0);
  const [people, setPeople] = useState(0);
  const [photo, setPhoto] = useState(0);
  const [culture, setCulture] = useState(0);

  const isAdmin = useRecoilValue(isLoginState);
  const [windowWidth, setWindowWidth] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [readersTop, setLeadersTop] = useState(0);
  const [preopleTop, setPeopleTop] = useState(0);
  const [photoTop, setPhotoTop] = useState(0);
  const [cultureTop, setCultureTop] = useState(0);
  const [contact, setContact] = useState(0);
  const [isMobile, setIsMobile] = useState(true);
  const [clickedItemId, setClickedItemId] = useState();

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case leadersRef.current:
              setLeaders(2);
              observer.unobserve(target);
              break;
            case peopleRef.current:
              setPeople(2);
              observer.unobserve(target);
              break;
            case photoRef.current:
              setPhoto(2);
              observer.unobserve(target);
              break;
            case cultureRef.current:
              setCulture(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContact(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);
    // 감지할 요소들을 옵저버에 등록
    if (leadersRef.current) {
      observer.observe(leadersRef.current);
    }
    if (peopleRef.current) {
      observer.observe(peopleRef.current);
    }
    if (photoRef.current) {
      observer.observe(photoRef.current);
    }
    if (cultureRef.current) {
      observer.observe(cultureRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
    };
  }, [
    leadersRef.current,
    peopleRef.current,
    photoRef.current,
    cultureRef.current,
  ]);

  return (
    <>
      <div className={[styles["wrapper"], styles["people-wrapper"]].join(" ")}>
        {/* intro */}

        <PeopleIntro isAdmin={isAdmin} />
        {/* <div id="people-leaders">
          <Leaders
            leaders={leaders}
            leadersRef={leadersRef}
            setLeaders={setLeaders}
          />
        </div> */}
        <div id="people-people">
          <PeopleUi
            people={people}
            peopleRef={peopleRef}
            setPeople={setPeople}
          />
        </div>
        {/* <div id="people-photo">
          <Photo photo={photo} photoeRef={photoRef} setPhoto={setPhoto} />
        </div> */}

        {/* culture */}
        <div
          className={styles["bg-section"]}
          style={{ backgroundImage: `url(${bg_culture})` }}
        >
          <div id="people-culture">
            <Culture
              culture={culture}
              cultureRef={cultureRef}
              setCulture={setCulture}
            />
          </div>

          <Contact
            contact={contact}
            contactRef={contactRef}
            text={`씨앤에이아이에 대해 궁금하신가요?`}
            isAdmin={isAdmin}
            bgColor={`transparent`}
            isPadding={true}
          />
        </div>
      </div>
    </>
  );
};

export default People;
