import React, { useReducer, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { postTest } from "../../api/api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmModal";
import deleteBtn from "../../assets/images/ico/btn_close_sm.png";
import "../../util/font";

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      // [{ align: [] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};

export const codeType = {
  "/admin/main/partners/enroll": {
    grp_code_id: "MAI",
    dtl_code_id: "MAI_PT",
    imgNum: 1,
  },
};

const PartnersEnroll = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    ord_no: "",
    tmp01: "",
  });
  const fileRef = useRef(null);
  const handleInputChange = (field, value) => {
    if (field === "ord_no") {
      const intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      if (intValue === 0) return;
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else {
      dispatch({ type: "CHANGE", field, payload: value });
    }
  };
  const fileNum = codeType[location].imgNum;
  const [files, setFiles] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [SecondModalState, setSecondModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [btAble, setBtAble] = useState(false);
  //파일을 선택하는 삼수
  const handleFileChange = (e) => {
    if (
      files.length === fileNum ||
      e.target.files.length > fileNum - files.length
    ) {
      setModalText(`첨부파일 갯수는 ${fileNum}개까지 입니다.`);
      setModalState(true);
      e.target.value = "";
      return;
    }
    const FileArray = [...files];
    const newFiles = Array.from(e.target.files);
    for (let newFile of newFiles) {
      const file = { data: newFile, fileURL: URL.createObjectURL(newFile) };
      FileArray.push(file);
    }
    setFiles(FileArray);
    e.target.value = "";
  };
  //선택된 파일을 지우는 함수
  const fileDelete = (index) => {
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];

    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (fileRef.current) {
      fileRef.current.files = store.files;
    }
    setFiles(newFiles);
  };

  //input 함수
  const handleOnChangeData = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const { mutate: startPost } = useMutation((postData) => postTest(postData), {
    onSuccess: (data) => {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      } else {
        setSecondModalState(true);
      }
    },
  });

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
    if (window.opener) {
      window.opener.location.reload();
    }
  };
  // React-quill 웹 에디터의 value -> html태그를 포함하고 있기에 유효성 검사를 위해 태그를 제거
  const parsingHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // 유효성 검사
  const validation = () => {
    if (!reducerState.postTitle.trim()) {
      setModalText("제목을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!parsingHtml(reducerState.postCn).trim()) {
      setModalText("내용을 입력해주세요");
      setBtAble(false);
      setModalState(true);
      return true;
    }
    if (files.length < fileNum && fileNum !== 0) {
      setModalText("파일을 첨부해주세요");
      setBtAble(false);
      setModalState(true);
      return true;
    }
    if (reducerState.ord_no < 2 || isNaN(reducerState.ord_no)) {
      setModalText("정렬순서를 입력해주세요(2이상)");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //작성 완료시 호출되는 함수
  const submitPost = () => {
    setBtAble(true);
    if (validation()) return;
    const formData = new FormData();
    formData.append("ttl", reducerState.postTitle);
    formData.append("dtl", reducerState.postCn);
    formData.append(
      "dtl_code",
      codeType[`${window.location.pathname}`].dtl_code_id
    );
    formData.append(
      "grp_code",
      codeType[`${window.location.pathname}`].grp_code_id
    );
    formData.append("tmp01", reducerState.tmp01);
    formData.append("ord_no", reducerState.ord_no);
    for (let i = 0; i < files.length; i++) {
      formData.append("fileField", files[i].data);
    }
    startPost(formData);
  };
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <PageTitle title={"등록하기"} />
      <table>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <input
                value={reducerState.postTitle}
                name="tilte"
                type="text"
                placeholder="제목을 입력해 주세요"
                onChange={(e) => handleInputChange("postTitle", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>정렬순서</th>
            <td>
              <input
                value={reducerState.ord_no || ""}
                name="ord_no"
                type="text"
                placeholder="정렬순서를 입력해주세요(2이상)"
                onChange={(e) => handleInputChange("ord_no", e.target.value)}
              />
            </td>
          </tr>
          {fileNum ? (
            <tr className={styles["file-list"]}>
              <th>
                첨부파일
                <div style={{ marginTop: "10px" }}>
                  가로: 318px <br />
                  세로: 128px
                </div>
              </th>
              <td>
                <label htmlFor="abc">파일첨부</label>
                <input
                  accept="image/*"
                  ref={fileRef}
                  id="abc"
                  hidden
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
                <div className={styles["files"]}>
                  {files.map((file, index) => (
                    <div
                      className={styles["file-box"]}
                      key={`${file.rgnl_file_name}_${index}`}
                    >
                      <button
                        onClick={() => {
                          fileDelete(index);
                        }}
                        type="button"
                        className={styles["btn-delete"]}
                      >
                        <img src={deleteBtn} alt="" />
                      </button>
                      <img src={file.fileURL} alt="" />
                      <div className={styles["file"]}>{file.data.name}</div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          ) : null}
          <tr>
            <th>내용</th>
            <td>
              <div style={{ height: "350px" }}>
                <ReactQuill
                  modules={modules}
                  style={{ height: "300px" }}
                  theme="snow"
                  value={reducerState.postCn}
                  onChange={(value) => handleOnChangeData("postCn", value)}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Button disable={btAble} text="등록 완료" buttonFunction={submitPost} />
      {modalState ? (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          disable={btAble}
          confirmFunction={closeModal}
        />
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="작성 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
};

export default PartnersEnroll;
