import React, { useEffect, useState } from "react";
import styles from "../scss/table/codeManageTable.module.scss";
import Button from "../button/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getDtlCodes, postDtlCodes } from "../../api/api";
import PageTitle from "../title/PageTitle";
import DtlCodeCreateModal from "../modal/DtlCodeCreateModal";
import ConfirmModal from "../modal/ConfirmModal";
import DtlCodeEditModal from "../modal/DtlCodeEditModal";

let detailHeaderList = [
  { id: 1, title: "상세코드" },
  { id: 2, title: "상세코드명" },
  { id: 3, title: "사용 여부" },
];
function DtlCodeTable({ grpCode }) {
  const queryClient = useQueryClient();
  //State
  const [datailLayoutHeaderList, setDetailLayoutHeaderList] =
    useState(detailHeaderList);
  const [dtlState, setDtlState] = useState([]);
  const [isAddCode, setIsAddCode] = useState(false);
  const [isEditCode, setIsEditCode] = useState(false);
  const [modalState, setModalState] = useState(false);
  const { data, isLoading } = useQuery(
    [QueryKeys.LIST, grpCode],
    () => getDtlCodes(grpCode),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  //Delete Post요청
  const { mutate: postDelete } = useMutation(
    (postData) => postDtlCodes(postData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ADMIN],
          refetchType: "active",
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.LIST],
          refetchType: "active",
        });
        setModalState(false);
      },
    }
  );

  //그룹 코드 추가 버튼
  const addGrpCode = () => {
    setIsAddCode(true);
  };
  const editGrpCode = () => {
    if (!dtlState?.dtl_code_id) {
      setModalState(2);
      return;
    }

    setIsEditCode(true);
  };
  const deleteGrpCode = () => {
    if (!dtlState?.dtl_code_id) {
      setModalState(2);
      return;
    }
    setModalState(1);
  };
  const handleClickCloseAddCodeModal = () => {
    setIsAddCode(false);
  };
  const handleClickCloseEditCodeModal = () => {
    setIsEditCode(false);
  };
  const handleClickConfirmModal = () => {
    setModalState(false);
  };
  //삭제 확인시 호출되는 함수
  const deleteStart = () => {
    const postData = {
      dtl_code_id: dtlState.dtl_code_id,
      dtl_code_dscr: dtlState.dtl_code_dscr,
      dtl_code_nm: dtlState.dtl_code_nm,
      sort_rdr: dtlState.sort_rdr,
      grp_code_id: dtlState.grp_code_id,
      status: "D",
    };
    postDelete(postData);
  };
  useEffect(() => {
    if (!isLoading && data) {
      setDtlState(data.dtlCodeList[0]);
    }
  }, [data, isLoading]);
  return (
    <>
      <div className={styles["content-box"]}>
        {!isLoading && data.code === "success" ? (
          <>
            <PageTitle title={"상세 코드관리"} />
            <div className="flex gap-2 justify-end mb-4">
              <Button text="코드추가" buttonFunction={addGrpCode} />
              <Button text="수정하기" buttonFunction={editGrpCode} />
              <Button text="삭제" buttonFunction={deleteGrpCode} />
            </div>
            <div className="w-full flex align-middle">
              {/* 상세코드 */}
              <div
                className={[
                  styles["top-section"],
                  "my-3 flex justify-between",
                ].join(" ")}
              ></div>
              <div className={[styles["list-wrapper"], "w-full"].join(" ")}>
                <div className={styles["list-header"]}>
                  <ul className={[styles["list-box"], "flex"].join(" ")}>
                    {datailLayoutHeaderList.map((item, index) => (
                      <li
                        key={index + `${item.title}`}
                        className={[
                          styles["title-list-item"],
                          "flex items-center w-1/2",
                        ].join(" ")}
                      >
                        <div className={styles["header-text"]}>
                          {item.title}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={styles["list-container"]}>
                  <ul className={styles["list-box"]}>
                    {data?.dtlCodeList?.map((item, index) => (
                      <li
                        className={[
                          item.dtl_code_id === dtlState?.dtl_code_id
                            ? [
                                styles["content-list-item"],
                                styles["active"],
                                "flex items-center hover:bg-[#d3eee5]",
                              ].join(" ")
                            : styles["content-list-item"],
                          "flex items-center hover:bg-[#d3eee5]",
                        ].join(" ")}
                        key={item.dtl_code_id}
                        onClick={() => setDtlState(item)}
                      >
                        {/* 번호 */}
                        <div
                          className={[
                            styles["number"],
                            styles["list-item"],
                            "w-1/2",
                          ].join(" ")}
                        >
                          {item.dtl_code_id}
                        </div>
                        {/* 제목 */}
                        <div
                          className={[
                            styles["name"],
                            styles["list-item"],
                            "w-1/2",
                          ].join(" ")}
                        >
                          {item.dtl_code_nm}
                        </div>
                        <div
                          className={[
                            styles["name"],
                            styles["list-item"],
                            "w-1/2",
                          ].join(" ")}
                        >
                          {item.use_yn}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {isAddCode && (
        <DtlCodeCreateModal
          onClick={handleClickCloseAddCodeModal}
          grpCode={grpCode}
          dtlList={data.dtlCodeList}
        />
      )}
      {isEditCode && (
        <DtlCodeEditModal
          onClick={handleClickCloseEditCodeModal}
          codeData={dtlState}
        />
      )}
      {modalState === 1 ? (
        <ConfirmModal
          modalText="삭제하시겠습니까?"
          confirmText="확인"
          closeText="취소"
          confirmFunction={deleteStart}
          handleClickCloseModal={handleClickConfirmModal}
        />
      ) : null}
      {modalState === 2 ? (
        <ConfirmModal
          colorRed={true}
          modalText="선택된 코드가 존재하지 않습니다?"
          confirmText="확인"
          confirmFunction={handleClickConfirmModal}
          handleClickCloseModal={handleClickConfirmModal}
        />
      ) : null}
    </>
  );
}

export default DtlCodeTable;
