import React, { useEffect, useReducer, useRef, useState } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest2, patchTest } from "../../api/api";
import ConfirmModal from "../../components/modal/ConfirmModal";
import deleteBtn from "../../assets/images/ico/btn_close_sm.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../util/font";

const modules = {
  toolbar: {
    container: [
      [
        {
          size: [
            "10px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "28px",
            "30px",
            "32px",
            "36px",
            "38px",
            "40px",
            "42px",
            "44px",
            "46px",
            "48px",
            "50px",
            "52px",
            "64px",
            "80px",
          ],
        },
      ],
      [{ font: ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"] }],
      [{ align: ["left", "center", "right", "justify"] }],
      ["bold", "italic", "underline"],
      [
        {
          color: [
            "#000",
            "#8d96ab",
            "#fff",
            "red",
            "yellow",
            "blue",
            "#3d1466",
            "#5f5aff",
            "green",
          ],
        },
        { background: [] },
      ],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};
const SocialMediaEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [reducerState, dispatch] = useReducer(reducer, {
    postTitle: "",
    postCn: "",
    tmp01: "",
    ord_no: "",
  });

  const handleInputChange = (field, value) => {
    if (field === "ord_no") {
      const intValue = parseInt(String(value).replace(/[^0-9-]/g, ""), 10);
      dispatch({ type: "CHANGE", field, payload: intValue });
    } else {
      dispatch({ type: "CHANGE", field, payload: value });
    }
  };

  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [SecondModalState, setSecondModalState] = useState(false);
  const [btAble, setBtAble] = useState(false);
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, id],
    () => getTest2(id),
    {
      cacheTime: 0,
      staleTime: 0,
    },
    {
      select: (data) => {
        return data;
      },
    }
  );

  //input 함수
  const handleOnChangeData = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const { mutate: sartEditPost } = useMutation(
    (editData) => patchTest(editData),
    {
      onSuccess: () => {
        if (data.code !== "success") {
          navigate("/admin/sign-in");
        } else {
          setSecondModalState(true);
        }
      },
    }
  );

  const successPost = () => {
    const location = window.location.pathname.split("/");
    const path = location.slice(0, 4);
    navigate(`${path.join("/")}`);
    if (window.opener) {
      window.opener.location.reload();
    }
  };
  // React-quill 웹 에디터의 value -> html태그를 포함하고 있기에 유효성 검사를 위해 태그를 제거
  const parsingHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // 유효성 검사
  const validation = () => {
    if (!reducerState.postTitle.trim()) {
      setModalText("제목을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp02.trim()) {
      setModalText("날짜를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.tmp01.includes("https://youtu.be")) {
      setModalText("올바른 유튜브 URL을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!parsingHtml(reducerState.postCn).trim()) {
      setModalText("내용을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //수정 완료시 호출되는 함수
  const submitEditPost = () => {
    setBtAble(true);
    if (validation()) {
      return;
    }
    const formData = new FormData();
    formData.append("brd_id", data.brdInfo[0].brd_id);
    formData.append("grp_code", data.brdInfo[0].grp_code);
    formData.append("dtl_code", data.brdInfo[0].dtl_code);
    formData.append("ttl", reducerState.postTitle);
    formData.append("dtl", reducerState.postCn);
    formData.append("tmp01", reducerState.tmp01);
    formData.append("tmp02", reducerState.tmp02.split("-").join("."));
    formData.append("tmp03", reducerState.tmp02.split("-").join(""));
    formData.append("file_del_array", deletedFile);
    formData.append("ord_no", 0);
    formData.append("use_yn", data.brdInfo[0].use_yn);
    for (let i = 0; i < files.length; i++) {
      formData.append("fileField", files[i].data);
    }
    sartEditPost(formData);
  };

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      } else {
        handleInputChange("postTitle", data.brdInfo[0]?.ttl);
        handleInputChange("postCn", data.brdInfo[0]?.dtl);
        handleInputChange("tmp01", data.brdInfo[0]?.tmp01);
        handleInputChange("tmp02", data.brdInfo[0].tmp02.split(".").join("-"));
        setUploadedFiles(data?.fileInfo);
      }
    }
  }, [data, isLoading]);

  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <PageTitle title={"등록하기"} />
      <table>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <input
                value={reducerState.postTitle}
                name="tilte"
                type="text"
                placeholder="제목을 입력해 주세요"
                onChange={(e) => handleInputChange("postTitle", e.target.value)}
              />
            </td>
          </tr>
          <tr className={`${[styles["period"]].join(" ")}`}>
            <th>등록일시</th>
            <td>
              <input
                type="date"
                value={reducerState.tmp02}
                onChange={(e) => handleInputChange("tmp02", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>Youtube URL</th>
            <td>
              <input
                value={reducerState.tmp01}
                name="tmp01"
                type="text"
                placeholder="정렬순서를 입력해주세요"
                onChange={(e) => handleInputChange("tmp01", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td>
              <div style={{ height: "350px" }}>
                <ReactQuill
                  modules={modules}
                  style={{ height: "300px" }}
                  theme="snow"
                  value={reducerState.postCn}
                  onChange={(value) => handleOnChangeData("postCn", value)}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Button
        disable={btAble}
        text="수정 완료"
        buttonFunction={submitEditPost}
      />
      {modalState ? (
        <ConfirmModal
          modalText={modalText}
          confirmText="확인"
          confirmFunction={closeModal}
        />
      ) : null}
      {SecondModalState ? (
        <ConfirmModal
          modalText="수정 완료"
          confirmText="확인"
          confirmFunction={successPost}
        />
      ) : null}
    </div>
  );
};
export default SocialMediaEdit;
