import React, { useEffect, useState } from "react";
import { QueryKeys } from "../../../util/queryClient";
import { getMangement2 } from "../../api/api";
import styles from "../../components/scss/table/add.module.scss";
import { useQuery } from "@tanstack/react-query";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import Tag from "../../components/tag/Tag";

function AdminFooter() {
  const navigate = useNavigate();
  const [typeCodes, setTypeCodes] = useState({ tmp01: "KOR" });
  const { data, isLoading } = useQuery(
    [QueryKeys.MAIN, "OPE_PR", typeCodes.tmp01],
    () => getMangement2(typeCodes.tmp01),
    {
      staleTime: 0,
      cacheTime: 0,
    },
    {
      select: (data) => {
        return data;
      },
    }
  );
  const handleClickMoveToEditPage = () => {
    navigate(`/admin/management/pref/footerInfo/board/edit/${typeCodes.tmp01}`);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <Tag typeCodes={typeCodes} setTypeCodes={setTypeCodes} />
      {!isLoading && data.code === "success" ? (
        <>
          <div className="flex justify-between">
            <PageTitle title={"환경설정"} />
          </div>
          <table>
            <tbody>
              <tr>
                <th>상호명</th>
                <td>{data.footerInfoList[0].cmpn_name}</td>
              </tr>
              <tr>
                <th>사업자 등록번호</th>
                <td>{data.footerInfoList[0].cmpn_rgst_nmbr}</td>
              </tr>
              <tr>
                <th>대표자명</th>
                <td>{data.footerInfoList[0].rprs_name}</td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>{data.footerInfoList[0].ml}</td>
              </tr>
              <tr>
                <th>카피라이트</th>
                <td>{data.footerInfoList[0].cpyr}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>{data.footerInfoList[0].drs1}</td>
              </tr>
              <tr>
                <th>주소2</th>
                <td>{data.footerInfoList[0].drs2}</td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>{data.footerInfoList[0].phn_nmbr1}</td>
              </tr>
              <tr>
                <th>전화번호2</th>
                <td>{data.footerInfoList[0].phn_nmbr2}</td>
              </tr>
            </tbody>
          </table>
          <div
            className={[styles["btn-group"], "flex justify-end gap-3"].join(
              " "
            )}
          >
            <Button
              text="수정하기"
              buttonFunction={() => handleClickMoveToEditPage()}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default AdminFooter;
