import React, { useEffect } from "react";
import styles from "../scss/main.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
function CoreValues({ value, valueRef, setValue, languageSelect }) {
  //coreValue Get
  const { data: coreValueData } = useQuery(
    [QueryKeys.MAIN, "MAI_CV", languageSelect],
    () => getMainBoard("MAI", "MAI_CV", languageSelect)
  );
  useEffect(() => {
    if (coreValueData) {
      setValue(1);
    }
  }, []);
  if (!coreValueData) return <div></div>;
  return (
    <section className={styles["value"]} ref={valueRef}>
      <h2
        className={[
          styles["title"],
          `${value === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        Core Values
      </h2>
      <ul className={[styles["value-list"], "flex flex-wrap"].join(" ")}>
        {coreValueData?.boardList.map((item) => (
          <li key={item.brd_id}>
            <strong>{item.ttl}</strong>
            <div>{parse(item.dtl)}</div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default React.memo(CoreValues);
