import React from "react";
import styles from "./scss/contact.module.scss";
import contactImage from "../../assets/images/ico/icon_arrow_rigth.png";
const Contact = ({ text, contactRef, contact, bgImgL, bgImgR, isPadding }) => {
  const openMailApp = () => {
    // 윈도우 메일 앱을 열기 위한 URL을 설정합니다.
    const mailAppUrl = `mailto:Contact@cnai.ai`;
    window.open(mailAppUrl);
  };
  return (
    <section
      className={[!isPadding && styles["pd-0"], styles["contact-us"]].join(" ")}
      ref={contactRef}
      style={{ backgroundImage: `url(${bgImgL}), url(${bgImgR})` }}
    >
      <h2
        className={[styles["title"], `${contact ? styles["active"] : ""}`].join(
          " "
        )}
      >
        {text}
      </h2>
      <button onClick={openMailApp}>
        Contact Us <img src={contactImage} alt="" />
      </button>
    </section>
  );
};

export default Contact;
