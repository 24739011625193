import React, { useReducer, useState, useEffect } from "react";
import styles from "../../components/scss/table/add.module.scss";
import PageTitle from "../../components/title/PageTitle";
import Button from "../../components/button/Button";
import { QueryKeys } from "../../../util/queryClient";
import { getDetailUser, postUser } from "../../api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmModal";

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.field]: action.payload };
    default:
      return state;
  }
};

function UserEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [reducerState, dispatch] = useReducer(reducer, {
    name: "",
    ml: "",
    cel_phn: "",
    cmpn_code: "",
    dprt_code: "",
  });
  //input 함수
  const handleOnChangeData = (field, value) => {
    dispatch({ type: "CHANGE", field, payload: value });
  };

  const [modalState, setModalState] = useState(false);
  const [modalText, setModalText] = useState("");
  const [btAble, setBtAble] = useState(false);
  const { data, isLoading } = useQuery(
    [QueryKeys.DETAIL, id],
    () => getDetailUser({ user_id: id }),
    {
      select: (data) => {
        return data;
      },
    },
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const { mutate: startPost } = useMutation((postData) => postUser(postData), {
    onSuccess: () => {
      const location = window.location.pathname.split("/");
      const path = location.slice(0, 4);
      navigate(`${path.join("/")}`);
      window.location.reload();
    },
  });

  // 유효성 검사
  const validation = () => {
    if (!reducerState.name.trim()) {
      setModalText("이름을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (!reducerState.ml.trim()) {
      setModalText("이메일을 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
    if (reducerState.cel_phn.trim().length < 11) {
      setModalText("연락처를 입력해주세요");
      setModalState(true);
      setBtAble(false);
      return true;
    }
  };
  //모달창을 닫는 함수
  const closeModal = () => {
    setModalState(false);
  };
  //작성 완료시 호출되는 함수
  const submitPost = () => {
    setBtAble(true);
    if (validation()) {
      return;
    }
    const editUserInfo = {
      user_id: data.userInfo[0].user_id,
      name: reducerState.name,
      ml: reducerState.ml,
      cel_phn: reducerState.cel_phn,
      cmpn_code: reducerState.cmpn_code,
      dprt_code: reducerState.dprt_code,
    };
    startPost(editUserInfo);
  };
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data && data.code === "fail") {
        navigate("/admin/sign-in");
      } else {
        handleOnChangeData("name", data.userInfo[0].name);
        handleOnChangeData("ml", data.userInfo[0].ml);
        handleOnChangeData("cel_phn", data.userInfo[0].cel_phn ?? "");
        handleOnChangeData("cmpn_code", data.userInfo[0].cmpn_code ?? "");
        handleOnChangeData("dprt_code", data.userInfo[0].dprt_code ?? "");
      }
    }
  }, [data, isLoading]);
  return (
    <div className={[styles["addInfo-wrapper"], "p-6"].join(" ")}>
      <PageTitle title={"등록하기"} />
      {!isLoading && data.code === "success" ? (
        <>
          <table>
            <tbody>
              <tr>
                <th>이름</th>
                <td>
                  <input
                    value={reducerState.name}
                    name="name"
                    type="text"
                    placeholder="이름을 입력해 주세요"
                    onChange={(e) => handleOnChangeData("name", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>
                  <input
                    value={reducerState.ml}
                    name="ml"
                    type="text"
                    placeholder="이메일을 입력해 주세요"
                    onChange={(e) => handleOnChangeData("ml", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰 번호</th>
                <td>
                  <input
                    value={reducerState.cel_phn}
                    name="cel_phn"
                    type="text"
                    placeholder="휴대폰 번호를 입력해 주세요"
                    maxLength={11}
                    onChange={(e) =>
                      handleOnChangeData(
                        "cel_phn",
                        e.target.value.replace(/\D/g, "")
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>회사 코드</th>
                <td>
                  <input
                    value={reducerState.cmpn_code}
                    name="cmpn_code"
                    type="text"
                    placeholder="회사 코드를 입력해 주세요"
                    onChange={(e) =>
                      handleOnChangeData("cmpn_code", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>부서 코드</th>
                <td>
                  <input
                    value={reducerState.dprt_code}
                    name="dprt_cdoe"
                    type="text"
                    placeholder="부서 코드를 입력해 주세요"
                    onChange={(e) =>
                      handleOnChangeData("dprt_code", e.target.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            disable={btAble}
            text="수정 완료"
            buttonFunction={submitPost}
          />
          {modalState ? (
            <ConfirmModal
              modalText={modalText}
              confirmText="확인"
              confirmFunction={closeModal}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}
export default UserEdit;
