import React from "react";
import styles from "../scss/tivv.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../util/queryClient";
import { getMainBoard } from "../../api/api";
import { useEffect } from "react";

const Adventage = ({ advantageTop, advantageRef, setAdvantageTop }) => {
  // 메인 Get
  const { data: advData } = useQuery(
    [QueryKeys.MAIN, "TIV_EX", ""],
    () => getMainBoard("TIV", "TIV_EX", ""),
    {
      select: (data) => {
        return data.boardList;
      },
    }
  );
  useEffect(() => {
    if (advData) {
      setAdvantageTop(1);
    }
  }, [advData]);
  if (!advData) return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["advantage"]} ref={advantageRef}>
      <h2
        className={[
          styles["title"],
          `${advantageTop === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {advData[0].ttl}
      </h2>
      <ul className={styles["advantage-list"]}>
        {advData.slice(1).map((item) => (
          <li key={item.brd_id} className={styles["advantage-item"]}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <div className={styles["content-box"]}>
              {/* <p>{item.ttl}</p> */}
              <span className={styles["content-detail"]}>
                {parse(item.dtl)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Adventage;
