import React, { useEffect } from "react";
import styles from "../scss/recruit.module.scss";
import { useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
import { getMainBoard2 } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function InterView({ interviewRef, interviewTop, setInterviewTop }) {
  const { data: InterviewData } = useQuery(
    [QueryKeys.MAIN, "REC_IN"],
    () => getMainBoard2("REC", "REC_IN"),
    {
      select: (data) => {
        return data.boardList;
      },
    }
  );
  const UrlButtonHandler = (url) => {
    window.open(url);
  };

  useEffect(() => {
    if (InterviewData) {
      setInterviewTop(1);
    }
  }, [InterviewData]);

  if (!InterviewData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section
      className={styles["interview"]}
      ref={interviewRef}
      style={{ backgroundImage: `url()` }}
    >
      <h2
        className={[
          styles["title"],
          `${interviewTop === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {InterviewData[0].ttl}
      </h2>
      <ul className={styles["interview-list"]}>
        {InterviewData.slice(1).map((item) => (
          <li key={item.brd_id} className={styles["interview-item"]}>
            <div className={styles["interview-box"]}>
              <div className={styles["img-box"]}>
                <img
                  src={`${process.env.REACT_APP_IMGURL}/${item.fileInfo[0].file_dntf_code}/${item.fileInfo[0].pld_file_name}`}
                  alt=""
                  onClick={() => {
                    UrlButtonHandler(item.tmp01);
                  }}
                />
              </div>
              <div className={styles["interview-content"]}>
                {parse(item.dtl)}
              </div>
              <div className={styles["interviewee"]}>{item.ttl}</div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default InterView;
