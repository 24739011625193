import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { isLoginState } from "../admin/util/recoil";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../util/queryClient";
import { getInvestMentList, getMainBoardForPaging } from "../api/api";

import styles from "../components/scss/investment.module.scss";

import btnPrev from "../assets/images/button/pagination_prev.png";
import btnNext from "../assets/images/button/pagination_next.png";

import btnFirst from "../assets/images/button/pagination_first.png";
import btnLast from "../assets/images/button/pagination_last.png";
import Contact from "../components/layout/Contact";

import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const headerList = [
  { id: "date", text: "날짜" },
  { id: "content", text: "내용" },
];

const Investment = () => {
  const navigate = useNavigate();
  const investmentRef = useRef();
  const contactRef = useRef();
  const isAdmin = useRecoilValue(isLoginState);
  const [maximunPage, setMaximunPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageGroup = Math.ceil(currentPage / (10 / 2));
  const startIndex = (currentPageGroup - 1) * (10 / 2) + 1;
  const endIndex = Math.min(startIndex + 10 / 2 - 1, maximunPage);
  const { data: investmentList } = useQuery(
    [QueryKeys.MAIN, "MED_IV", currentPage],
    () => getInvestMentList("MED_IV", 10, (currentPage - 1) * 10, "")
  );
  //첫페이지 이동
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  //다음 페이지 이동
  const handleNextPage = () => {
    if (currentPage >= maximunPage) return;
    else {
      setCurrentPage(currentPage + 1);
    }
  };
  // 이전 페이지 이동
  const handlePrevPage = () => {
    if (currentPage <= 1) return;
    else {
      setCurrentPage(currentPage - 1);
    }
  };
  //마지막 페이지로 이동
  const handleLastPage = () => {
    setCurrentPage(maximunPage);
  };
  const MoveToLink = (url) => {
    window.open(url, "_blank", "noopener");
  };
  useEffect(() => {
    if (investmentList) {
      setMaximunPage(Math.ceil(investmentList.paging[0].all_page / 10));
    }
  }, [investmentList]);
  const [windowWidth, setWindowWidth] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [investmentTop, setMediaTop] = useState(0);
  const [contactTop, setContactTop] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  let lastScroll = document.documentElement.scrollTop || 0;

  const onScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    if (scrollTop > lastScroll) {
      // down
    } else {
      // up
    }
    lastScroll = scrollTop;

    setScrollY(window.scrollY);
    if (investmentRef.current) {
      setMediaTop(investmentRef.current.offsetTop);
      setContactTop(contactRef.current.offsetTop);
    }
  };

  const onResize = () => {
    setWindowWidth(window.innerWidth);
    if (windowWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollY, windowWidth]);
  if (!investmentList)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <>
      <div
        className={[
          styles["wrapper"],
          styles["investment-wrapper"],
          isAdmin ? styles["is-admin"] : "",
        ].join(" ")}
      >
        {/* investment */}
        <section className={styles["investment"]} ref={investmentRef}>
          <div className={styles["investment-group"]}>
            <div className={styles["investment-box"]}>
              <strong className={styles["title"]}>Investment</strong>
              <div className={styles["scroll-x"]}>
                <ul className={styles["investment-list"]}>
                  <li className={styles["table-header-box"]}>
                    {headerList.map((item) => (
                      <div key={item.id} className={styles[`${item.id}`]}>
                        {item.text}
                      </div>
                    ))}
                  </li>
                  {investmentList.boardList.map((item) => (
                    <li
                      key={item.brd_id}
                      className={styles["investment-content-list"]}
                      onClick={() => {
                        navigate(item.brd_id);
                      }}
                    >
                      <div className={styles["date"]}>{item.tmp02}</div>
                      <div className={styles["content"]}>{item.ttl}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* pagination */}
          <div className={styles["pagination"]}>
            {/* 처음 페이지로 이동 */}
            <div
              className={[
                styles["page-btns"],
                styles["prev-btn-box"],
                "mr-1",
              ].join(" ")}
            >
              <button
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                <img src={btnFirst} alt="" />
              </button>
            </div>
            <div
              className={[styles["page-btns"], styles["prev-btn-box"]].join(
                " "
              )}
            >
              <button
                className={styles["btn-prev"]}
                disabled={currentPage === 1}
                onClick={handlePrevPage}
              >
                <img src={btnPrev} alt="" />
              </button>
            </div>
            <ol className={styles["pagination-num"]}>
              {Array.from(
                { length: endIndex - startIndex + 1 },
                (_, i) => startIndex + i
              ).map((page) => (
                <button
                  className={[
                    styles["page-btns"],
                    styles["page-nums"],
                    "flex items-center justify-center",
                    // `${currentPage === page ? styles.active : ""}`,
                  ].join(" ")}
                  key={page}
                  onClick={() => {
                    setCurrentPage(page);
                  }}
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
            </ol>
            <div
              className={[styles["page-btns"], styles["next-btn-box"]].join(
                " "
              )}
            >
              <button
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
                onClick={handleNextPage}
              >
                <img src={btnNext} alt="" />
              </button>
            </div>
            <div
              className={[
                styles["page-btns"],
                styles["next-btn-box"],
                "ml-1",
              ].join(" ")}
            >
              <button
                className={styles["btn-next"]}
                disabled={currentPage === maximunPage}
                onClick={handleLastPage}
              >
                <img src={btnLast} alt="" />
              </button>
            </div>
          </div>
        </section>
        <div className={styles["bg-wrapper"]}>
          <Contact
            text="씨앤에이아이에 대해 궁금하신가요?"
            isAdmin={isAdmin}
            contact={contactTop}
            contactRef={contactRef}
            isPadding={true}
          />
        </div>
      </div>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open(
              "/admin/investment/investment",
              "blank",
              "height=720,width=1280"
            );
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
    </>
  );
};

export default Investment;
