import React, { useEffect } from "react";
import Table from "../../components/table/Table";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest } from "../../api/api";
import { useNavigate } from "react-router-dom";

const Culture = () => {
  const navigate = useNavigate();
  const typeCodes = { grp_code: "PEO", dtl_code: "PEO_CL" };
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, "PEO_CL"],
    () => getTest(typeCodes.grp_code, typeCodes.dtl_code),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);

  return (
    <>
      {!isLoading && data.code === "success" ? (
        <div className="p-6">
          <div className="flex w-full items-center justify-between">
            <Table layoutList={data.boardList} title={"Culture"} />
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Culture;
