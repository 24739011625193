import { Quill } from "react-quill";
import "../assets/scss/formatFonts.scss"; /*이 파일은
위에 폰트나 폰트크기를 설정한 css파일이다. */

// 폰트를 whitelist에 추가하고 Quill에 등록해준다.
const Size = Quill.import("formats/size");
const Font = Quill.import("attributors/class/font");
const Align = Quill.import("formats/align");
const Icons = Quill.import("ui/icons");
Size.whitelist = [
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "28px",
  "30px",
  "32px",
  "36px",
  "38px",
  "40px",
  "42px",
  "44px",
  "46px",
  "48px",
  "50px",
  "52px",
  "64px",
  "80px",
];
Font.whitelist = ["NotoSansKR", "Roboto", "NanumSquare", "Pretendard"];
Align.whitelist = ["left", "center", "right", "justify"];
Icons.align["left"] = Icons.align[""];
Quill.register(Size, true);
Quill.register(Font, true);
Quill.register(Align, true);
