import React, { useState } from "react";
import styles from "../scss/table/table.module.scss";
//Recoil

//Router
import { useNavigate } from "react-router-dom";
import PageTitle from "../title/PageTitle";
import Button from "../button/Button";

let headerList = [
  { id: 1, title: "이름" },
  { id: 2, title: "이메일" },
  { id: 3, title: "사용여부" },
  { id: 4, title: "연락처" },
  { id: 5, title: "등록자" },
  { id: 6, title: "등록일" },
];

const UserTable = ({ layoutList }) => {
  const navigate = useNavigate();
  // State
  const [layoutHeaderList, setLayoutHeaderList] = useState(headerList);

  const handleClickMoveToPage = (id) => {
    navigate(`${window.location.pathname}/board/${id}`);
  };

  const handleClickMoveToAddPage = () => {
    navigate(`${window.location.pathname}/enroll`);
  };
  return (
    <div className={styles["content-box"]}>
      <PageTitle title={"회원관리"} />
      <div className="flex flex-col align-middle">
        <div
          className={[styles["top-section"], "my-3 flex justify-between"].join(
            " "
          )}
        >
          <span className={[styles["list-title"], "mb-3"].join(" ")}></span>
        </div>
        <div className={styles["list-wrapper"]}>
          <div className={styles["list-header"]}>
            <ul className={[styles["list-box"], "flex"].join(" ")}>
              {layoutHeaderList.map((item, index) => (
                <li
                  key={index + `${item.title}`}
                  className={[
                    styles["title-list-item"],
                    "flex items-center",
                  ].join(" ")}
                >
                  <div className={styles["header-text"]}>{item.title}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles["list-container"]}>
            <ul className={styles["list-box"]}>
              {layoutList?.map((item, index) => (
                <li
                  className={[
                    styles["content-list-item"],
                    "flex items-center",
                  ].join(" ")}
                  key={item.user_id}
                  onClick={() => handleClickMoveToPage(item.user_id)}
                >
                  {/* 이름 */}
                  <div
                    className={[styles["number"], styles["list-item"]].join(
                      " "
                    )}
                  >
                    {item.name}
                  </div>
                  {/*  */}
                  <div
                    className={[styles["name"], styles["list-item"]].join(" ")}
                  >
                    {item.ml}
                  </div>
                  {/* 상태 */}
                  <div
                    className={[styles["status"], styles["list-item"]].join(
                      " "
                    )}
                  >
                    {item.use_yn === "Y" ? "사용" : "미사용"}
                  </div>
                  <div
                    className={[styles["email"], styles["list-item"]].join(" ")}
                  >
                    {item.cel_phn
                      ? item.cel_phn.replace(
                          /^(\d{2,3})(\d{3,4})(\d{4})$/,
                          `$1-$2-$3`
                        )
                      : ""}
                  </div>
                  {/* 등록자 */}
                  <div
                    className={[styles["date"], styles["list-item"]].join(" ")}
                  >
                    {item.rgst_id}
                  </div>
                  {/* 등록일시 */}
                  <div
                    className={[styles["email"], styles["list-item"]].join(" ")}
                  >
                    {item.rgst_date.substr(0, 10)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <Button text="등록하기" buttonFunction={handleClickMoveToAddPage} />
        </div>
      </div>
    </div>
  );
};
export default UserTable;
