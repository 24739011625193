import React from "react";
import styles from "../scss/recruit.module.scss";
import { useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
import { getMainBoard2 } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";
function RecruitIntro({ isAdmin }) {
  //메인 Get
  const { data: RecruData } = useQuery(
    [QueryKeys.MAIN, "REC_MAI"],
    () => getMainBoard2("REC", "REC_MAI"),
    {
      select: (data) => {
        return data.boardList[0];
      },
    }
  );
  if (!RecruData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["intro"]}>
      <div className={styles["inner"]}>
        <div
          className={[
            styles["intro-text"],
            styles["intro-main-text"],
            "gap-2 block md:flex",
          ].join(" ")}
        >
          <div className="overflow-hidden inline md:inline-block">
            <span>{RecruData.ttl}</span>
          </div>
        </div>
        <div
          className={[
            styles["intro-text"],
            styles["intro-ko-text"],
            "gap-2",
          ].join(" ")}
        >
          <div
            className="overflow-hidden inline md:inline-block"
            style={{
              backgroundColor: "#ffffffc9",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            <span>{parse(RecruData.dtl)} </span>
          </div>
        </div>
      </div>
      {isAdmin ? (
        <button
          onClick={() => {
            window.open(
              "/admin/recruit/main",
              "blank",
              "height=720,width=1280"
            );
          }}
          className={styles["btn-show-layer"]}
        >
          admin
        </button>
      ) : null}
    </section>
  );
}

export default RecruitIntro;
