import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import styles from "../components/scss/main.module.scss";

import MainIntro from "../components/main/MainIntro";
import OurSolutions from "../components/main/OurSolutions";
import MediaKit from "../components/main/MediaKit";

import contactBgImgL from "../assets/images/bg/main_contact_1.png";
import { useRecoilValue } from "recoil";
import { isLoginState, languageSelector } from "../admin/util/recoil";
import Service from "../components/pola/Service";
import TivvService from "../components/tivv/TivvService";
import Application from "../components/pola/Application";
import Definition from "../components/tivv/Definition";

const Contact = lazy(() => import("../components/layout/Contact"));
const EngMain = () => {
  const pathName = window.location.href;
  useEffect(() => {
    // if (pathname === "#solution") {
    //   window.scrollTo({ top: 1000, left: 0, behavior: "auto" });
    // }
  }, [pathName]);

  const solutionRef = useRef();
  const serviceRef = useRef();
  const applicationRef = useRef();
  const tivvServiceRef = useRef();
  const definitionRef = useRef();
  const mediaRef = useRef();
  const contactRef = useRef();

  const languageSelect = useRecoilValue(languageSelector);
  const isAdmin = useRecoilValue(isLoginState);

  const [solution, setSolution] = useState(0);
  const [service, setService] = useState(0);
  const [application, setApplication] = useState(0);
  const [serviceTop, setServiceTop] = useState(0);
  const [definitionTop, setDefinitionTop] = useState(0);
  const [media, setMedia] = useState(0);
  const [contact, setContact] = useState(0);

  useEffect(() => {
    const options = {
      root: null, // viewport를 기준으로 감지
      rootMargin: "0px", // 추가적인 마진 없음
      threshold: 0.15,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const isTargetInView = entry.isIntersecting;
        if (isTargetInView) {
          switch (target) {
            case solutionRef.current:
              setSolution(2);
              observer.unobserve(target);
              break;
            case serviceRef.current:
              setService(2);
              observer.unobserve(target);
              break;
            case applicationRef.current:
              setApplication(2);
              observer.unobserve(target);
              break;
            case tivvServiceRef.current:
              setServiceTop(2);
              observer.unobserve(target);
              break;
            case definitionRef.current:
              setDefinitionTop(2);
              observer.unobserve(target);
              break;
            case mediaRef.current:
              setMedia(2);
              observer.unobserve(target);
              break;
            case contactRef.current:
              setContact(2);
              observer.unobserve(target);
              break;
            default:
              break;
          }
        }
      });
    }, options);

    // 감지할 요소들을 옵저버에 등록
    if (solutionRef.current) {
      observer.observe(solutionRef.current);
    }
    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }
    if (applicationRef.current) {
      observer.observe(applicationRef.current);
    }
    if (tivvServiceRef.current) {
      observer.observe(tivvServiceRef.current);
    }
    if (definitionRef.current) {
      observer.observe(definitionRef.current);
    }
    if (mediaRef.current) {
      observer.observe(mediaRef.current);
    }
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      // 옵저버 해제
      observer.disconnect();
    };
  }, [
    solutionRef.current,
    serviceRef.current,
    applicationRef.current,
    tivvServiceRef.current,
    definitionRef.current,
    mediaRef.current,
  ]);
  return (
    <>
      <div
        className={[
          styles["wrapper"],
          styles["main-wrapper"],
          isAdmin ? styles["is-admin"] : "",
        ].join(" ")}
      >
        <MainIntro isAdmin={isAdmin} languageSelect={languageSelect} />
        <div id="main-solutions">
          <OurSolutions
            solutionRef={solutionRef}
            solution={solution}
            setSolution={setSolution}
            languageSelect={languageSelect}
          />
        </div>
        <div id="pola-definition">
          <Service
            service={service}
            serviceRef={serviceRef}
            setService={setService}
            languageSelect={languageSelect}
          />
        </div>
        <div id="pola-application">
          <Application
            application={application}
            applicationRef={applicationRef}
            setApplication={setApplication}
            languageSelect={languageSelect}
          />
        </div>
        <div id="tivv-service">
          <TivvService
            serviceTop={serviceTop}
            serviceRef={tivvServiceRef}
            setServiceTop={setServiceTop}
            languageSelect={languageSelect}
          />
        </div>
        {/* definition */}
        <div id="tivv-definition">
          <Definition
            definitionTop={definitionTop}
            definitionRef={definitionRef}
            setDefinitionTop={setDefinitionTop}
            languageSelect={languageSelect}
          />
        </div>
        <div className={styles["bg-area"]}>
          <div id="main-mediakit">
            <MediaKit
              media={media}
              mediaRef={mediaRef}
              setMedia={setMedia}
              languageSelect={languageSelect}
            />
          </div>
          {/* contact */}
          <Suspense fallback="">
            <Contact
              contact={contact}
              contactRef={contactRef}
              text={"Contact us for more information"}
              bgImgL={contactBgImgL}
              bgImgR={""}
              isPadding={true}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default EngMain;
