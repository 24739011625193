import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_URL}`;

export const getCommon = async (grp_code, dtl_code) => {
  const res = await axios.get(
    `${BASE_URL}/front/getBscBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}`
  );
  return res.data;
};

export const getMainBoard = async (grp_code, dtl_code, searchTmp01) => {
  const res = await axios.get(
    `${BASE_URL}/front/getBscBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}&searchTmp01=${searchTmp01}`
  );
  return res.data;
};
export const getMainBoard2 = async (grp_code, dtl_code) => {
  const res = await axios.get(
    `${BASE_URL}/front/getBscBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}`
  );
  return res.data;
};

export const getMainBoardForPaging = async (
  grp_code,
  dtl_code,
  limitLine,
  offset,
  searchTmp01
) => {
  const res = await axios.get(
    `${BASE_URL}/front/getBscBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}&limitLine=${limitLine}&offset=${offset}&searchTmp01=${searchTmp01}`
  );
  return res.data;
};

export const getTmp01List = async (grp_code, dtl_code) => {
  const res = await axios.get(
    `${BASE_URL}/front/getTmp01List?grp_code=${grp_code}&dtl_code=${dtl_code}`
  );
  return res.data;
};

export const getMainBoardPaging = async (
  grp_code,
  dtl_code,
  searchTmp01,
  limitLine,
  offset
) => {
  const res = await axios.get(
    `${BASE_URL}/front/getBscBoardList?grp_code=${grp_code}&dtl_code=${dtl_code}&searchTmp01=${searchTmp01}&limitLine=${limitLine}&offset=${offset}`
  );
  return res.data;
};

export const getPopupList = async (grp_code, dtl_code) => {
  const res = await axios.get(
    `${BASE_URL}/front/getPopList?grp_code=${grp_code}&dtl_code=${dtl_code}`
  );
  return res.data;
};

export const getFooter = async (lang) => {
  const res = await axios.get(`${BASE_URL}/front/getFooterInfo?lang=${lang}`);
  return res.data;
};
export const getLinkSocialListForPaging = async (
  dtl_code1,
  dtl_code2,
  limitLine,
  offset,
  searchTmp01
) => {
  const res = await axios.get(
    `${BASE_URL}/front/getLinkList?dtl_code=${dtl_code1}&dtl_code=${dtl_code2}&limitLine=${limitLine}&offset=${offset}&searchTmp01=${searchTmp01}&orderType=tmp03`
  );
  return res.data;
};
export const getInvestMentList = async (
  dtl_code1,
  limitLine,
  offset,
  searchTmp01
) => {
  const res = await axios.get(
    `${BASE_URL}/front/getLinkList?dtl_code=${dtl_code1}&limitLine=${limitLine}&offset=${offset}&searchTmp01=${searchTmp01}&orderType=tmp03`
  );
  return res.data;
};
export const getDetail = async (grp_code, dtl_code, brd_id) => {
  const res = await axios.get(
    `${BASE_URL}/front/getBscBoardInfo?grp_code=${grp_code}&dtl_code=${dtl_code}&brd_id=${brd_id}`
  );
  return res.data;
};
