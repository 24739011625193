import React, { useEffect } from "react";
import styles from "../scss/pola.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function Service({ service, serviceRef, setService, languageSelect }) {
  //coreValue Get
  const { data: serviceData } = useQuery(
    [QueryKeys.MAIN, "POL_IF", languageSelect],
    () => getMainBoard("POL", "POL_IF", languageSelect)
  );

  useEffect(() => {
    if (serviceData) {
      setService(1);
    }
  }, []);
  if (!serviceData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["service"]} ref={serviceRef}>
      <h2
        className={[
          styles["title"],
          // `${scrollY > serviceTop - 500 ? styles["active"] : ""}`,
          `${service === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {parse(serviceData.boardList[0].ttl)}
      </h2>
      <span className={styles["sub-title"]}>
        {parse(serviceData.boardList[0].dtl)}
      </span>
      <div className={styles["service-box"]}>
        <img
          src={`${process.env.REACT_APP_IMGURL}/${serviceData.boardList[0].fileInfo[0].file_dntf_code}/${serviceData.boardList[0].fileInfo[0].pld_file_name}`}
          className={styles["gif-item"]}
          alt=""
        />
        <ul>
          <li key={parse(serviceData.boardList[3].brd_id)}>
            {parse(serviceData.boardList[1].dtl)}
          </li>
          <li key={parse(serviceData.boardList[2].brd_id)}>
            {parse(serviceData.boardList[2].dtl)}
          </li>
          <li key={parse(serviceData.boardList[1].brd_id)}>
            {parse(serviceData.boardList[3].dtl)}
          </li>
          <li key={parse(serviceData.boardList[0].brd_id)}>
            {parse(serviceData.boardList[4].dtl)}
          </li>
        </ul>
        <img
          src={`${process.env.REACT_APP_IMGURL}/${serviceData.boardList[0].fileInfo[1].file_dntf_code}/${serviceData.boardList[0].fileInfo[1].pld_file_name}`}
          className={styles["gif-item"]}
          alt=""
        />
      </div>
    </section>
  );
}

export default React.memo(Service);
