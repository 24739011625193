import React, { useEffect } from "react";
import styles from "../scss/pola.module.scss";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { getMainBoard } from "../../api/api";
import { QueryKeys } from "../../util/queryClient";

function Application({
  application,
  applicationRef,
  setApplication,
  languageSelect,
}) {
  //coreValue Get
  const { data: applicationData } = useQuery(
    [QueryKeys.MAIN, "POL_EX", languageSelect],
    () => getMainBoard("POL", "POL_EX", languageSelect)
  );
  useEffect(() => {
    if (applicationData) {
      setApplication(1);
    }
  }, []);
  if (!applicationData)
    return <div style={{ width: "100vh", height: "100vh" }}></div>;
  return (
    <section className={styles["application"]} ref={applicationRef}>
      <h2
        className={[
          styles["title"],
          // `${scrollY > applicationTop - 500 ? styles["active"] : ""}`,
          `${application === 2 ? styles["active"] : ""}`,
        ].join(" ")}
      >
        {applicationData.boardList[0].ttl}
      </h2>
      <div className={styles["application-box"]}>
        <ul className="flex flex-wrap">
          <li key={parse(applicationData.boardList[1].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[1].fileInfo[0].file_dntf_code}/${applicationData.boardList[1].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[1].ttl)}</strong>
            {parse(applicationData.boardList[1].dtl)}
          </li>
          <li key={parse(applicationData.boardList[2].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[2].fileInfo[0].file_dntf_code}/${applicationData.boardList[2].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[2].ttl)}</strong>
            {parse(applicationData.boardList[2].dtl)}
          </li>
          <li key={parse(applicationData.boardList[3].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[3].fileInfo[0].file_dntf_code}/${applicationData.boardList[3].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[3].ttl)}</strong>
            {parse(applicationData.boardList[3].dtl)}
          </li>
          <li key={parse(applicationData.boardList[4].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[4].fileInfo[0].file_dntf_code}/${applicationData.boardList[4].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[4].ttl)}</strong>
            {parse(applicationData.boardList[4].dtl)}
          </li>
          <li key={parse(applicationData.boardList[5].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[5].fileInfo[0].file_dntf_code}/${applicationData.boardList[5].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[5].ttl)}</strong>
            {parse(applicationData.boardList[5].dtl)}
          </li>
          <li key={parse(applicationData.boardList[6].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[6].fileInfo[0].file_dntf_code}/${applicationData.boardList[6].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[6].ttl)}</strong>
            {parse(applicationData.boardList[6].dtl)}
          </li>
          <li key={parse(applicationData.boardList[7].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[7].fileInfo[0].file_dntf_code}/${applicationData.boardList[7].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[7].ttl)}</strong>
            {parse(applicationData.boardList[7].dtl)}
          </li>
          <li key={parse(applicationData.boardList[8].brd_id)}>
            <div className={styles["img-box"]}>
              <img
                src={`${process.env.REACT_APP_IMGURL}/${applicationData.boardList[8].fileInfo[0].file_dntf_code}/${applicationData.boardList[8].fileInfo[0].pld_file_name}`}
                alt=""
              />
            </div>
            <strong>{parse(applicationData.boardList[8].ttl)}</strong>
            {parse(applicationData.boardList[8].dtl)}
          </li>
        </ul>
        <span className={styles["desc-text"]}>
          {parse(applicationData.boardList[0].dtl)}
        </span>
      </div>
    </section>
  );
}

export default React.memo(Application);
