import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../util/queryClient";
import { getTest3 } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import Tag from "../../components/tag/Tag";

function CoreValues() {
  const location = useLocation();
  const navigate = useNavigate();

  const [maximunPage, setMaximunPage] = useState();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;
  const [typeCodes, setTypeCodes] = useState({
    grp_code: "MAI",
    dtl_code: "MAI_CV",
    tmp01: "KOR",
  });
  const { data, isLoading } = useQuery(
    [QueryKeys.ADMIN, typeCodes.dtl_code, typeCodes.tmp01, currentPage],
    () => getTest3(typeCodes.grp_code, typeCodes.dtl_code, typeCodes.tmp01),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  useEffect(() => {
    if (!isLoading && data !== undefined) {
      if (data.code !== "success") {
        navigate("/admin/sign-in");
      }
    }
  }, [data, isLoading]);

  return (
    <>
      {!isLoading && data.code === "success" ? (
        <div className="p-6">
          {/* <Tag typeCodes={typeCodes} setTypeCodes={setTypeCodes} /> */}
          <div className="flex w-full items-center justify-between">
            <Table layoutList={data.boardList} title={"Core Values"} />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CoreValues;
